import React, { useState, useEffect } from "react";
import "./api.css";
import { Auth, API } from "aws-amplify";
import awsconfig from "../../Constant/aws-exports";
import preloader from "../../images/preloader.gif";
// API.configure(awsconfig[process.env.REACT_APP_STAGE]);

function Api({toggleLoading}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [apiKey, setApiKey] = useState();

  const getFiuName = async () => {
    try {
      setLoading(true);
      const result = await API.get("updateFiu", "/name");
      // console.log("name", result.data);
      if (localStorage.getItem("fiu_stage") == "prod") {
        setApiKey(result.data.prod_api_key);
      } else {
        setApiKey(result.data.dev_api_key);
      }
      setTimeout(()=>{setLoading(false)},1000)
      // setLoading(false);
    } catch (err) {
      console.log("Error :", err);
    }
  };
  const fetchTemplates = async () => {
    const requestInfo = {
        headers: {
            "Content-type": "application/json"
        }
    }
  
    //
    try {
        
        const response = await API.get("consentTemplates", '/templates', requestInfo)
        const templates = response.data 
        // console.log(templates)
        
        localStorage.setItem('tempId',templates[0].uuid)
            
  
    } catch (err) {
        console.log('Error', err)
    }
  }
  useEffect(() => {
    getFiuName();
    fetchTemplates()
  }, []);

  const Actions = (apiKey) => {
    if (apiKey == null) {
      return <button className="apiButton">Generate</button>;
    } else {
      function copy() {
        navigator.clipboard.writeText(apiKey);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
      return (
        <td id="api-action">
          <button
            onClick={() => {
              copy();
            }}
            className="apiButton"
          >
            Copy
          </button>
          {success ? (
            <div className="copied">Link copied to clipboard!</div>
          ) : (
            ""
          )}
        </td>
      );
    }
  };
  // console.log(apiKey, "123");
  return (
    <div className="api">
      <div className="col-4">
        {loading || toggleLoading? (
          <img src={preloader} className="preloa" />
        ) : (
          <table className="api-table">
            <tr className="api-tr">
              <th className="apith1">
                {localStorage.getItem("fiu_stage") == "prod"
                  ? "Production API Key"
                  : "Staging API Key"}
              </th>
              <th>Action</th>
            </tr>
            <tbody id="api-tbody">
              <tr>
                <td>{apiKey}</td>

                {Actions(apiKey)}
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {/* <div className="col-8">
        <div id="info2">
          Veniam sint esse adipisicing elit consectetur reprehenderit
          exercitation enim esse aliqua non nisi tempor labore. Qui quis do
          exercitation quis cupidatat laboris enim eiusmod occaecat. Do tempor
          ipsum ad proident nisi aliquip esse. Veniam dolore sunt nisi nisi
          mollit amet elit consectetur excepteur nulla. Voluptate laborum ipsum
          nostrud veniam commodo exercitation excepteur ullamco aliqua Lorem sit
          ea anim velit. Magna sunt anim qui enim cupidatat reprehenderit amet.
          Cillum do enim commodo eu sit ex sint fugiat.
        </div>
      </div> */}
    </div>
  );
}

export default Api;
