import React from "react";
import "./Switch.css";

const Switch = ({ isOn, handleToggle, onColor, buttonColor }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={() => {
          if (process.env.REACT_APP_STAGE != "me") {
            if (localStorage.getItem("prod_enable") == 1) {
              handleToggle();
            }
          }
        }}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{
          background:
            localStorage.getItem("prod_enable") == 0 ||
            process.env.REACT_APP_STAGE == "me"
              ? "#f0f0f0"
              : isOn && onColor,
          cursor:
            localStorage.getItem("prod_enable") == 0 ||
            process.env.REACT_APP_STAGE == "me"
              ? "not-allowed"
              : "pointer",
        }}
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span
          className={
            localStorage.getItem("prod_enable") == 0 ||
            process.env.REACT_APP_STAGE == "me"
              ? "react-switch-button-disable"
              : "react-switch-button"
          }
          style={{
            backgroundColor:
              localStorage.getItem("prod_enable") == 0 ||
              process.env.REACT_APP_STAGE == "me"
                ? "#d1d1d1"
                : buttonColor,
          }}
        />
      </label>
    </>
  );
};

export default Switch;
