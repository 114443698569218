module.exports = {
  source_type: {
    0: "Account Aggregator",
    1: "PDF",
    2: "Netbanking",
  },

  fi_request_status: {
    "CONSENT PENDING": 0,
    "DATA PENDING": 1,
    "CONSENT FAILED": 2,
    "DATA REQUEST FAILED": 3,
    "FIP MISSING": 4,
    "DATA READY": 5,
    "CONSENT REVOKED": 6,
  },

  data_request_status: {
    0: "PENDING",
    1: "APPROVED",
    6: "REJECTED",
    7: "FAILED",
  },
};
