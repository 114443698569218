import React, { useEffect, useState } from "react";
import "./CreateConsent.css";
import close from "../../images/close.svg";
import awsconfig from "../../Constant/aws-exports";
import { Auth, API } from "aws-amplify";
import Dropdown from "./Dropdown";
import { Modal } from "react-bootstrap";
// API.configure(awsconfig[process.env.REACT_APP_STAGE]);

function AddTemplate(props) {
  // console.warn(props)

  const [loading, setLoading] = useState(false);
  const [consentTypes, setConsentType] = useState([]);
  const [fiData, setFiData] = useState([]);
  const [isView, setIsView] = useState(false);

  const [fiuFlag, setFiuFlag] = useState(false);
  const [flag, setFlag] = useState("");

  const fi_types = [
    {
      name: "DEPOSIT",
    },
    {
      name: "RECURRING_DEPOSIT",
    },
    // {
    //     name:"GSTR1_3B"
    // },
  ];
  const types = [
    {
      name: "TRANSACTIONS",
    },
    {
      name: "PROFILE",
    },
    {
      name: "SUMMARY",
    },
  ];
  // console.log(consentTypes,"consentTypes")
  // console.log(fiData,"FITypes")
  const [data, setData] = useState({
    consent_mode: "",
    fetch_types: "",
    consent_types: "",
    fi_types: "",
    data_life_unit: "",
    data_life_value: "",
    frequency_unit: "",
    frequency_value: "",
    data_filter_type: "",
    data_filter_operator: "",
    data_filter_value: "",
    consent_life_unit: "",
    consent_life_value: "",
    fi_data_from: "",
    fi_data_to: "",
    fi_data_unit: "",
    purpose_code: "",
    purpose_ref_uri: "",
    purpose_text: "",
    purpose_category: "",
    fetch_data_range_unit: "",
    fetch_data_range_value: "",
  });

  useEffect(() => {
    setFlag(JSON.parse(localStorage.getItem("ceplr_fiu")));
  }, []);
  //   console.log(data,"data")

  const createConsent = async () => {
    let requestInfo = {
      body: {
        consent_life_unit: data.consent_life_unit,
        consent_life_value: data.consent_life_value,
        fi_data_from: data.fi_data_from,
        fi_data_to: data.fi_data_to,
        fi_data_unit: data.fi_data_unit,
        consent_mode: data.consent_mode,
        fetch_types: data.fetch_types,
        consent_types: consentTypes,
        fi_types: fiData,
        data_life_unit: data.data_life_unit,
        data_life_value: data.data_life_value,
        frequency_unit: data.frequency_unit,
        frequency_value: data.frequency_value,
        data_filter_type: data.data_filter_type,
        data_filter_operator: data.data_filter_operator,
        data_filter_value: data.data_filter_value,
        purpose_code: "104",
        purpose_ref_uri: "https://api.rebit.org.in/aa/purpose/104.xml",
        purpose_text: "Aggregated statement",
        purpose_category: "Financial Reporting",
        fetch_data_range_unit: data.fetch_data_range_unit,
        fetch_data_range_value: data.fetch_data_range_value,
      },
    };
    // console.log("body---", requestInfo.body);

    try {
      setLoading(true);
      const result = await API.post(
        "consentTemplates",
        "/templates",
        requestInfo
      );
      const response = await API.get("consentTemplates", "/templates");
      setLoading(false);
      props.setConsentTemplates(response.data);
      localStorage.setItem("tempId", response.data[0].uuid);
      props.setTrigger(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error.response);
    }
  };

  function handle(e) {
    const newData = { ...data };
    //maximum fetch data range is 6 months
    if (
      props.fiuFlag &&
      ((e.target.name != "fetch_data_range_unit" &&
        data.fetch_data_range_unit == "YEAR") ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "YEAR") ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "MONTH" &&
          data.fetch_data_range_value > 6) ||
        (data.fetch_data_range_unit == "MONTH" &&
          e.target.name == "fetch_data_range_value" &&
          e.target.value > 6) ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "DAY" &&
          data.fetch_data_range_value > 180) ||
        (data.fetch_data_range_unit == "DAY" &&
          e.target.name == "fetch_data_range_value" &&
          e.target.value > 180) ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "HOUR" &&
          data.fetch_data_range_value > 4320) ||
        (data.fetch_data_range_unit == "HOUR" &&
          e.target.name == "fetch_data_range_value" &&
          e.target.value > 4320))
    ) {
      setFiuFlag(true);
    }
    // maximum frequency of data fetching is 30 days
    else if (
      (e.target.name == "frequency_unit" &&
        e.target.value == "YEAR" &&
        data.frequency_value > 360) ||
      (data.frequency_unit == "YEAR" &&
        e.target.name == "frequency_value" &&
        e.target.value > 360) ||
      (e.target.name == "frequency_unit" &&
        e.target.value == "MONTH" &&
        data.frequency_value > 30) ||
      (data.frequency_unit == "MONTH" &&
        e.target.name == "frequency_value" &&
        e.target.value > 30) ||
      (e.target.name == "frequency_unit" &&
        e.target.value == "DAY" &&
        data.frequency_value > 1) ||
      (data.frequency_unit == "DAY" &&
        e.target.name == "frequency_value" &&
        e.target.value > 1) ||
      (e.target.name == "frequency_unit" &&
        e.target.value == "HOUR" &&
        data.frequency_value > 1) ||
      (data.frequency_unit == "HOUR" &&
        e.target.name == "frequency_value" &&
        e.target.value > 1)
    ) {
      setFiuFlag(true);
    } else {
      setFiuFlag(false);
    }

    if (e.target.name == "consent_mode") {
      if (e.target.value == "VIEW") {
        newData[e.target.name] = e.target.value;
        setIsView(true);
        newData["data_life_value"] = "0";
        setData(newData);
      } else {
        newData[e.target.name] = e.target.value;
        setIsView(false);
        setData(newData);
      }
    } else {
      newData[e.target.name] = e.target.value;
      setData(newData);
    }
    console.log(fiuFlag);
  }

  function onKeyup(e) {
    // console.log(e.target.value)
    if (e.target.value < 0) {
      e.target.value = 1;
    }
  }

  return props.trigger ? (
    <Modal show>
      <div className="row-2">
        <div className="divC1">
          <div className="newTemplateHeader">
            Create new configuration
            <div className="templateClose">
              <img
                src={close}
                alt="/"
                onClick={() => props.setTrigger(false)}
              />
            </div>
          </div>
        </div>

        <div className="divC9">
          <div className="content">
            <div className="content-1">
              Consent Mode
              <div className="fiTypesInput">
                {/* <input type="text" name="consent_mode" id="fiTypes" onChange={e => handle(e)} value={data.consent_mode} /> */}
                <select
                  name="consent_mode"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="VIEW">VIEW</option>
                  <option value="STORE">STORE</option>
                </select>
              </div>
            </div>
            <div className="content-2">
              Consent Types
              <div className="fiTypesInput1">
                <Dropdown
                  changeType={(type) => setConsentType(type)}
                  type={types}
                />

                {/* <input type="text" name="consent_types" id="fiTypes" onChange={e => handle(e)} value={data.consent_types} /> */}
                {/* <select name="consent_types" id="fiTypes"  onChange={e => handle(e)} multiple size="1">
                                        <option value="none" selected disabled hidden>Select</option>
                                        <option value="TRANSACTIONS" >TRANSACTIONS</option>
                                        <option value="PROFILE">PROFILE</option>
                                        <option value="SUMMARY">SUMMARY</option>
                                    </select> */}
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content-1">
              Consent Life Unit
              <div className="fiTypesInput">
                {/* <input type="text" name="consent_life_unit" id="fiTypes" onChange={e => handle(e)} value={data.consent_life_unit} /> */}
                <select
                  name="consent_life_unit"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="YEAR">YEAR</option>
                  <option value="MONTH">MONTH</option>
                  <option value="DAY">DAY</option>
                  <option value="HOUR">HOUR</option>
                </select>
              </div>
            </div>

            <div className="content-2">
              Consent Life Value
              <div className="fiTypesInput">
                <input
                  type="number"
                  placeholder="Enter a number"
                  onKeyPress={(e) => onKeyup(e)}
                  min="0"
                  name="consent_life_value"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                  value={data.consent_life_value}
                />
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content-1">
              Fetch Data Range Unit
              <div className="fiTypesInput">
                {/* <input type="text" name="fetch_data_range_unit" id="fiTypes" onChange={e => handle(e)} value={data.fetchDataRangeUnit} />
                 */}
                <select
                  name="fetch_data_range_unit"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="YEAR">YEAR</option>
                  <option value="MONTH">MONTH</option>
                  <option value="DAY">DAY</option>
                  <option value="HOUR">HOUR</option>
                </select>
              </div>
            </div>
            <div className="content-2">
              Fetch Data Range Value
              <div className="fiTypesInput">
                <input
                  type="number"
                  placeholder="Enter a number"
                  onKeyPress={(e) => onKeyup(e)}
                  min="0"
                  name="fetch_data_range_value"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                  value={data.fetchDataRangeValue}
                />
              </div>
            </div>
          </div>

          {fiuFlag &&
          (data.fetch_data_range_unit == "YEAR" ||
            (data.fetch_data_range_unit == "MONTH" &&
              data.fetch_data_range_value > 6) ||
            (data.fetch_data_range_unit == "DAY" &&
              data.fetch_data_range_value > 180) ||
            (data.fetch_data_range_unit == "HOUR" &&
              data.fetch_data_range_value > 4320)) ? (
            <span className="fetchRangeErr">
              *Fetch data range value cannot be greater than 6 months
            </span>
          ) : null}

          <div className="content">
            <div className="content-1">
              Consent Data From
              <div className="fiTypesInput">
                <input
                  type="number"
                  placeholder="Enter a number"
                  onKeyPress={(e) => onKeyup(e)}
                  min="0"
                  name="fi_data_from"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                  value={data.fi_data_from}
                />
              </div>
            </div>
            <div className="content-2">
              Consent Data To
              <div className="fiTypesInput">
                <input
                  type="number"
                  placeholder="Enter a number"
                  onKeyPress={(e) => onKeyup(e)}
                  min="0"
                  name="fi_data_to"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                  value={data.fi_data_to}
                />
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content-1">
              Consent Data Unit
              <div className="fiTypesInput">
                {/* <input type="text" name="fi_data_unit" id="fiTypes" onChange={e => handle(e)} value={data.fi_data_unit} /> */}
                <select
                  name="fi_data_unit"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="YEAR">YEAR</option>
                  <option value="MONTH">MONTH</option>
                  <option value="WEEK">WEEK</option>
                  <option value="DAY">DAY</option>
                </select>
              </div>
            </div>
            <div className="content-2">
              Fetch Types
              <div className="fiTypesInput">
                {/* <input type="text" name="fetch_types" id="fiTypes" onChange={e => handle(e)} value={data.fetch_types} /> */}
                <select
                  name="fetch_types"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="ONETIME">ONETIME</option>
                  <option value="PERIODIC">PERIODIC</option>
                </select>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content-1">
              Data life unit
              <div className="fiTypesInput">
                {/* <input type="text" name="data_life_unit" id="fiTypes" onChange={e => handle(e)} value={data.data_life_unit} /> */}
                <select
                  name="data_life_unit"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="YEAR">YEAR</option>
                  <option value="MONTH">MONTH</option>
                  <option value="DAY">DAY</option>
                  {/* <option value="INF">INF</option> */}
                </select>
              </div>
            </div>
            <div className="content-2">
              Data life value
              <div className="fiTypesInput">
                {isView ? (
                  <input
                    disabled="true"
                    type="number"
                    min="0"
                    id="fiTypes"
                    name="data_life_value"
                    onChange={(e) => handle(e)}
                    value="0"
                  />
                ) : (
                  <input
                    type="number"
                    placeholder="Enter a number"
                    onKeyPress={(e) => onKeyup(e)}
                    min="0"
                    id="fiTypes"
                    name="data_life_value"
                    onChange={(e) => handle(e)}
                    value={data.data_life_value}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content-1">
              Frequency unit
              <div className="fiTypesInput">
                {/* <input type="text" id="fiTypes" name="frequency_unit" onChange={e => handle(e)} value={data.frequency_unit} /> */}
                <select
                  name="frequency_unit"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="YEAR">YEAR</option>
                  <option value="MONTH">MONTH</option>
                  <option value="DAY">DAY</option>
                  <option value="HOUR">HOUR</option>
                  {/* <option value="INF">INF</option> */}
                </select>
              </div>
            </div>
            <div className="content-2">
              Frequency value
              <div className="fiTypesInput">
                <input
                  type="number"
                  placeholder="Enter a number"
                  onKeyPress={(e) => onKeyup(e)}
                  min="0"
                  id="fiTypes"
                  name="frequency_value"
                  onChange={(e) => handle(e)}
                  value={data.frequency_value}
                />
              </div>
            </div>
          </div>
          {(data.frequency_unit == "YEAR" && data.frequency_value > 360) ||
          (data.frequency_unit == "MONTH" && data.frequency_value > 30) ||
          (data.frequency_unit == "DAY" && data.frequency_value > 1) ||
          (data.frequency_unit == "HOUR" && data.frequency_value > 1) ? (
            <span className="fetchRangeErr">
              *Frequency value cannot be greater than 30 per month
            </span>
          ) : null}
          <div className="content">
            <div className="content-1">
              FI types
              <div className="fiTypesInput1">
                <Dropdown
                  changeType={(type) => setFiData(type)}
                  type={fi_types}
                  singleSelection={true}
                />
                {/* 
                                    <input type="text" name="fi_types" id="fiTypes" onChange={e => handle(e)} value={data.fi_types} /> */}
                {/* <select name="fi_types" id="fiTypes"  onChange={e => handle(e)}>
                                        <option value="none" selected disabled hidden>Select</option>
                                        <option value="DEPOSIT">DEPOSIT</option>
                                        <option value="MUTUAL FUNDS">MUTUAL FUNDS</option>
                                        <option value="WEEK">WEEK</option>
                                        <option value="DAY">DAY</option>
                                    </select> */}
              </div>
            </div>
            <div className="content-2">
              Data filter operator
              <div className="fiTypesInput">
                {/* <input type="text" id="fiTypes" name="data_filter_operator" onChange={e => handle(e)} value={data.data_filter_operator} /> */}
                <select
                  name="data_filter_operator"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="=">=</option>
                  <option value="!=">!=</option>
                  <option value=">">&gt;</option>
                  <option value="<">&lt;</option>
                  <option value=">=">&gt;=</option>
                  <option value="<=">&lt;=</option>
                </select>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content-1">
              Data filter value
              <div className="fiTypesInput">
                <input
                  type="number"
                  placeholder="Enter a number"
                  onKeyPress={(e) => onKeyup(e)}
                  min="0"
                  id="fiTypes"
                  name="data_filter_value"
                  onChange={(e) => handle(e)}
                  value={data.data_filter_value}
                />
              </div>
            </div>

            <div className="content-2">
              Data filter type
              <div className="fiTypesInput">
                {/* <input type="text" id="fiTypes" name="data_filter_type" onChange={e => handle(e)} value={data.data_filter_type} /> */}
                <select
                  name="data_filter_type"
                  id="fiTypes"
                  onChange={(e) => handle(e)}
                >
                  <option value="none" selected disabled hidden>
                    Select
                  </option>
                  <option value="TRANSACTIONAMOUNT">TRANSACTIONAMOUNT</option>
                </select>
              </div>
            </div>
          </div>
          {/* <div className="content" >
                            <div className="content-1" >
                                Purpose Code
                                <div className="fiTypesInput" >
                                    <input type="text" id="fiTypes" name="purpose_code" onChange={e => handle(e)} value={data.purpose_code} />
                                </div>
                            </div>
                            <div className="content-2">
                                Purpose Ref Uri
                                <div className="fiTypesInput" >
                                    <input type="url" id="fiTypes" name="purpose_ref_uri" onChange={e => handle(e)} value={data.purpose_ref_uri} />
                                </div>
                            </div>
                        </div>
                        <div className="content" >
                            <div className="content-1" >
                                Purpose Text
                                <div className="fiTypesInput" >
                                    <input type="text" id="fiTypes" name="purpose_text" onChange={e => handle(e)} value={data.purpose_text} />
                                </div>
                            </div>
                            <div className="content-2">
                                Purpose Category
                                <div className="fiTypesInput" >
                                    <input type="text" id="fiTypes" name="purpose_category" onChange={e => handle(e)} value={data.purpose_category} />
                                </div>
                            </div>
                        </div> */}
        </div>
        <div className="divC2">
          <button className="save" onClick={createConsent}>
            {loading ? (
              <span
                style={{ marginRight: "5px" }}
                className="spinner-border spinner-border-sm mr-1"
              ></span>
            ) : (
              `Save Configuration`
            )}
          </button>
        </div>
      </div>
    </Modal>
  ) : (
    ""
  );
}

export default AddTemplate;
