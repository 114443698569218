import React, { useEffect } from "react";

//images
import Mobile from "../../images/new_mobile.png";
import blueTick from "../../images/blueTick.svg";

//cssStyle
import "./TryItNow.css";

const stepData = [
  {
    image: blueTick,
    title: "Sample loan app",
    description:
      "The customer opens your mobile app or website, fills in their details and proceeds to share their bank statements. Ideally, at this stage you would provide the option to Share via Account Aggregator along with the benefits of doing so.When the customer selects the Account Aggregator option, you will send us the customer details, the Configuration ID and a Redirect URL. In response you will receive the URL to launch our consent approval flow. Upon completing the  consent approval process, the customer will be sent to the Redirect URL provided by you.",
  },
  {
    image: blueTick,
    title: "Welcome Screen",
    description:
      "The Consent Request is created at the Account Aggregator based on the Consent Template selected by you. We also use this space to set the context for the customer and provide a breakdown of the steps involved. ",
  },
  {
    image: blueTick,
    title: "Login via OTP ",
    description: `The customer logs in to the Account Aggregator (“AA”) via OTP based authentication. The customer’s mobile number used here is the one provided by you in Step 1. This mobile number must be one linked to the customer’s bank account. It would be useful to add this note prominently in your app’s screen where the customer provides their mobile number. `,
  },
  {
    image: blueTick,
    title: "Select Bank",
    description:
      "A list of banks that support the Account Aggregator framework are provided. The customer must select the bank where they have an account linked to the mobile number provided earlier. In case no accounts are discovered, the customer will receive an error message.",
  },
  {
    image: blueTick,
    title: "Account linking",
    description:
      "The customer’s account(s) held in the bank selected in the previous step are displayed. Any unlinked account(s) from which the customer wishes to share data must be selected for linking with the AA. Previously linked accounts are marked separately. If there are new accounts to link, an OTP based authorization is required before proceeding to the next step.",
  },
  {
    image: blueTick,
    title: "Consent review",
    description:
      "Before the customer approves the sharing of their data, the details of the required consent are presented in a structured manner. We clearly present what is to be shared, for how long and how often.",
  },
  {
    image: blueTick,
    title: "Success screen",
    description:
      "Once your customer approves, we fetch the digitally signed consent artifact from the AA. The required data is then fetched, decrypted and stored in order to make it available to you.  We estimate that the entire end-to-end process in the previous steps can be completed in under a minute!",
  },
];

function TryItNow() {
  // useEffect(() => {
  //     console.log(JSON.parse(localStorage.getItem('result')),"fiuDetails")
  //   },[])

  let fiu = localStorage.getItem("apiKey");
  let template = localStorage.getItem("tempId");
  let stage = localStorage.getItem("fiu_stage");
  // console.log(fiu, "fiuDetails ashish");
  // console.log(template, "tempdetails ashish");

  const apiKey = fiu;
  //  cont templateKey =

  // console.log(apiKey, "apiKey");

  return (
    <section id="TryItNow">
      <style type="text/css">{`sideBar {display: none}`}</style>
      <div className="GrayBackGroundDiv">
        <div className="demonstrateDiv">
          <div className="stepsDiv">Consent Request User Journey</div>
          <div className="stepTextDiv">
            {stepData.map((item) => (
              <div className="allstepsDiv">
                <img src={item.image} className="blueTick" />
                <div className="stepTextContainer">
                  <div className="loanBigText">{item.title}</div>
                  <div className="loanSmallText">{item.description}</div>
                </div>
              </div>
            ))}

            {/* <div className='shadowBottom'></div> */}
          </div>
        </div>
        <div className="iframeDiv">
          <img src={Mobile} className="MobileClass" />
          <iframe
            id="forceMobile"
            class="force-mobile"
            src={`https://${
              stage == "prod" ? "" : "me."
            }ceplr.com/demo?id=${apiKey}&tempId=${template}`}
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default TryItNow;
