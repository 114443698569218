import React from "react";
import consent_template_active from "../../../images/consent_template_active.svg";
import consent_template_inactive from "../../../images/consent_template_inactive.svg";
import setting_active from "../../../images/setting_active.svg";
import setting_inactive from "../../../images/setting_inactive.svg";

export const sidebarData = [
  {
    title: "Configurations",
    path: "/configurations",
    active_image: consent_template_active,
    inactive_image: consent_template_inactive,
  },
  {
    title: "Settings",
    path: "/settings",
    active_image: setting_active,
    inactive_image: setting_inactive,
  },
  
  // {
  //     title:"Postman Collection",
  //     path:'/postman-collection',
  // },
  // {
  //     title:"API Reference",
  //     path:'https://documenter.getpostman.com/view/17473933/UVC3m8uJ',
  // },
  // {
  //     title:"Try It Now",
  //     path:'/consent-flow',
  // }
];
