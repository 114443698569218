import React from "react";
import { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import preloader from "../../images/preloader.gif";
import awsconfig from "../../Constant/aws-exports";

import "./UpdateFiu.css";
import editIcon from "../../images/editIcon.svg";
import defaultImage from "../../images/defaultImage.svg";

// API.configure(awsconfig[process.env.REACT_APP_STAGE]);

function UpdateFiu({toggleLoading}) {
  const [file, setFile] = useState();
  const [name, setName] = useState("");
  const [fiuName, setFiuName] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [tabloader, setTabloader] = useState(false);
  const [imageFormat, setImageFormat] = useState(false);
  const getFiuLogo = async () => {
    try {
      const result = await API.get("updateFiu", "/logo");
      // console.log("logo ::::", result.message)
      setImages(result.message);
    } catch (err) {
      console.log("Error :", err);
    }
  };

  const getFiuName = async () => {
    try {
      setTabloader(true);
      const result = await API.get("updateFiu", "/name");
      // console.log("Fiu:::", result.data)
      setFiuName(result.data.name);
      setTabloader(false);
    } catch (err) {
      console.log("Error :", err);
    }
  };
  const fetchTemplates = async () => {
    const requestInfo = {
        headers: {
            "Content-type": "application/json"
        }
    }
  
    //
    try {
        
        const response = await API.get("consentTemplates", '/templates', requestInfo)
        const templates = response.data 
        // console.log(templates)
        
        localStorage.setItem('tempId',templates[0].uuid)
            
  
    } catch (err) {
        console.log('Error', err)
    }
  }

  useEffect(() => {
    getFiuLogo();
    getFiuName();
    fetchTemplates()
    // console.log("images", images)
  }, []);

  const accepted_formats = ["image/png", "image/jpeg"];
  const fileSelected = (event) => {
    const file = event.target.files[0];
    // console.log(file)
    //CHECK FOR FILE TYPE
    if (!accepted_formats.includes(file.type)) {
      // console.log("Please Upload in accepted format as PNG or JPEG")
      setImageFormat(true);
      setFile(false);
    } else {
      var File = event.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        // console.log('RESULT', reader.result)
        setFile(reader.result);
        uploadImage(reader.result)
        setImages(reader.result);
      };
      reader.readAsDataURL(file);

      setImageFormat(false);
    }
  };

  let emptyState = "";
  const uploadImage = async (result) => {
    setLoading(true);
    // event.preventDefault();
    // const result = await postImage({image: file, description})
    // setImages([result.image, ...images])
    // console.log("uploaded", { image: file })
    let requestInfo = {
      body: {
        user_avatar: result,
      },
    };
    // console.log("body---", requestInfo.body);

    try {
      
      const result = await API.post("updateFiu", "/logo", requestInfo);
      const getLogo = await API.get("updateFiu", "/logo");
      localStorage.setItem("images", getLogo.message);
      setImages(getLogo.message);

      setFile(emptyState);
      setLoading(false);
      // console.log("result", result)
    } catch (err) {
      setLoading(false);
      console.log("Error :", err);
    }
  };

  const updateName = async () => {
    // console.log("name", name)
    let requestInfo = {
      body: {
        name: name,
      },
    };
    // console.log("body---", requestInfo.body);

    try {
      const result = await API.post("updateFiu", "/name", requestInfo);
      localStorage.setItem("fiuName", name);
      setTimeout(()=>{setLoading(false)
      
      },500)
      // setLoading(false);
      // console.log("result", result)
      setName(emptyState);
      setFiuName(name);
      setDisabled(true);
    } catch (err) {
      console.log("Error :", err);
      // setLoading(false)
    }
  };

  const changeName = async () => {
    setDisabled(false);
    setFiuName(emptyState);
  };

  function disableButton(input, fiuName) {
    if (fiuName) {
      return (
        <div className="save_button_container">
          <div className="save_button" onClick={changeName}>
            Change
          </div>
        </div>
      );
    } else {
      if (input) {
        return (
          <div className="save_button_container">
            <div className="save_button" onClick={updateName}>
              {loading  ? (
                <span
                  style={{ marginRight: "5px" }}
                  className="spinner-border spinner-border-sm mr-1"
                ></span>
              ) : (
                `Save`
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div className="save_button_container">
            <div className="save_disable_button"> Save </div>
          </div>
        );
      }
    }
  }

  return (
    <section id="updateFiu">
      <div id="container">
        {tabloader || toggleLoading? (
          <img src={preloader} />
        ) : (
          <div className="profile">
            <h1>
              Company logo<br></br>
              {/* <span>*Please upload logo in accepted format as PNG or JPEG</span>  */}
              {imageFormat ? (
                <span1>
                  * Please upload logo in accepted format as PNG or JPEG
                </span1>
              ) : (
                <span2>
                  Please upload logo in accepted format as PNG or JPEG
                </span2>
              )}
            </h1>
            <div className="fiu_logo">
              <div className="image-upload">
              {loading? 
              <div className="spinnerDiv">
                 <img src={preloader} style={{height:"40px",width:"40px"}}/>
                </div>:
                <img
                  src={images.length == 0 ? defaultImage : images}
                  className="image"
                  
                />}
                <div className="overlay">
                  <label for="file-input">
                    <span>
                      <img className="icon" src={editIcon} />
                    </span>
                  </label>
                  <input id="file-input" type="file" onChange={fileSelected} />
                </div>
              </div>

              
            </div>

            <div className="company_name">
              <h1>Company Name </h1>
              <div className="save_container">
                <input
                  placeholder="Enter your Company Name"
                  disabled={disabled}
                  defaultValue={fiuName}
                  onChange={(e) => setName(e.target.value)}
                ></input>
                {disableButton(name, fiuName)}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
export default UpdateFiu;
