import React, { useEffect, useState } from "react";
import "./UpdateConsent.css";
import close from "../../images/close.svg";
import UpdatePopup from "./UpdatePopup";
import { Modal } from "react-bootstrap";
import { API } from "aws-amplify";
import Dropdown from "./Dropdown";

function EditPopup(props) {
  const value = props.template;
  const [loading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [toggleDiv, setToggleDiv] = useState(false);
  const [toggleDiv1, setToggleDiv1] = useState(false);
  const [fiuFlag, setFiuFlag] = useState(false);
  const [flag, setFlag] = useState("");
  const [prompt, setPrompt] = useState(false);
  const fi_types = [
    {
      name: "DEPOSIT",
    },
    {
      name: "RECURRING_DEPOSIT",
    },
    // {
    //   name:"GSTR1_3B"
    // },
  ];
  const types = [
    {
      name: "TRANSACTIONS",
    },
    {
      name: "PROFILE",
    },
    {
      name: "SUMMARY",
    },
  ];
  const [data, setData] = useState({
    consent_life_unit: value.consentLifeUnit,
    consent_life_value: value.consentLifeValue,
    fi_data_from: value.fiDataFrom,
    fi_data_to: value.fiDataTo,
    fi_data_unit: value.fiDataUnit,
    consent_mode: value.consentMode,
    fetch_types: value.fetchTypes,
    consent_types: value.consentTypes,
    fi_types: value.fiTypes,
    data_life_unit: value.dataLifeUnit,
    data_life_value: value.dataLifeValue,
    frequency_unit: value.frequencyUnit,
    frequency_value: value.frequencyValue,
    data_filter_type: value.dataFilterType,
    data_filter_operator: value.dataFilterOperator,
    data_filter_value: value.dataFilterValue,
    purpose_code: "104",
    purpose_ref_uri: "https://api.rebit.org.in/aa/purpose/104.xml",
    purpose_text: "Aggregated statement",
    purpose_category: "Financial Reporting",
    fetch_data_range_unit: value.fetchDataRangeUnit,
    fetch_data_range_value: value.fetchDataRangeValue,
  });
  let json = JSON.stringify(data);
  let consent = JSON.parse(json).consent_types;
  const [consentTypes, setConsentType] = useState([]);
  const [fiData, setFiData] = useState([]);

  useEffect(() => {
    const body = {
      consent_life_unit: value.consentLifeUnit,
      consent_life_value: value.consentLifeValue,
      fi_data_from: value.fiDataFrom,
      fi_data_to: value.fiDataTo,
      fi_data_unit: value.fiDataUnit,
      consent_mode: value.consentMode,
      fetch_types: value.fetchTypes,
      consent_types: value.consentTypes,
      fi_types: value.fiTypes,
      data_life_unit: value.dataLifeUnit,
      data_life_value: value.dataLifeValue,
      frequency_unit: value.frequencyUnit,
      frequency_value: value.frequencyValue,
      data_filter_type: value.dataFilterType,
      data_filter_operator: value.dataFilterOperator,
      data_filter_value: value.dataFilterValue,
      purpose_code: "104",
      purpose_ref_uri: "https://api.rebit.org.in/aa/purpose/104.xml",
      purpose_text: "Aggregated statement",
      purpose_category: "Financial Reporting",
      fetch_data_range_unit: value.fetchDataRangeUnit,
      fetch_data_range_value: value.fetchDataRangeValue,
    };
    setData(body);
    setFlag(JSON.parse(localStorage.getItem("ceplr_fiu")));
    if (props.fiuFlag && value.fetchDataRangeUnit == "YEAR") {
      setFiuFlag(true);
    } else if (
      props.fiuFlag &&
      value.fetchDataRangeUnit == "MONTH" &&
      value.fetchDataRangeValue > 6
    ) {
      setFiuFlag(true);
    } else if (
      (value.frequencyUnit == "MONTH" && value.frequencyValue > 30) ||
      (value.frequencyUnit == "YEAR" && value.frequencyValue > 360)
    ) {
      setFiuFlag(true);
    }
  }, [value]);

  function handle(e) {
    const newData = { ...data };

    //maximum fetch data range is 6 months
    if (
      props.fiuFlag &&
      ((e.target.name != "fetch_data_range_unit" &&
        data.fetch_data_range_unit == "YEAR") ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "YEAR") ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "MONTH" &&
          data.fetch_data_range_value > 6) ||
        (data.fetch_data_range_unit == "MONTH" &&
          e.target.name == "fetch_data_range_value" &&
          e.target.value > 6) ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "DAY" &&
          data.fetch_data_range_value > 180) ||
        (data.fetch_data_range_unit == "DAY" &&
          e.target.name == "fetch_data_range_value" &&
          e.target.value > 180) ||
        (e.target.name == "fetch_data_range_unit" &&
          e.target.value == "HOUR" &&
          data.fetch_data_range_value > 4320) ||
        (data.fetch_data_range_unit == "HOUR" &&
          e.target.name == "fetch_data_range_value" &&
          e.target.value > 4320))
    ) {
      setFiuFlag(true);
    }
    // maximum frequency of data fetching is 30 days
    else if (
      (e.target.name == "frequency_unit" &&
        e.target.value == "YEAR" &&
        data.frequency_value > 360) ||
      (data.frequency_unit == "YEAR" &&
        e.target.name == "frequency_value" &&
        e.target.value > 360) ||
      (e.target.name == "frequency_unit" &&
        e.target.value == "MONTH" &&
        data.frequency_value > 30) ||
      (data.frequency_unit == "MONTH" &&
        e.target.name == "frequency_value" &&
        e.target.value > 30) ||
      (e.target.name == "frequency_unit" &&
        e.target.value == "DAY" &&
        data.frequency_value > 1) ||
      (data.frequency_unit == "DAY" &&
        e.target.name == "frequency_value" &&
        e.target.value > 1) ||
      (e.target.name == "frequency_unit" &&
        e.target.value == "HOUR" &&
        data.frequency_value > 1) ||
      (data.frequency_unit == "HOUR" &&
        e.target.name == "frequency_value" &&
        e.target.value > 1)
    ) {
      setFiuFlag(true);
    } else {
      setFiuFlag(false);
    }

    if (e.target.name == "consent_mode") {
      if (e.target.value == "VIEW") {
        newData[e.target.name] = e.target.value;
        setIsView(true);
        newData["data_life_value"] = "0";
        setData(newData);
      } else {
        newData[e.target.name] = e.target.value;
        setIsView(false);
        setData(newData);
      }
    } else {
      newData[e.target.name] = e.target.value;
      setData(newData);
    }
  }

  const conse = (consentTypes, data) => {
    if (consentTypes.length > 0) {
      return consentTypes;
    } else {
      return data.consent_types;
    }
  };
  let arr = conse(consentTypes, data);
  const fi = (fiData, data) => {
    if (fiData.length > 0) {
      return fiData;
    } else {
      return data.fi_types;
    }
  };

  let arr2 = fi(fiData, data);
  const updateConsent = async () => {
    setPrompt(true);
    let requestInfo = {
      body: {
        consent_life_unit: data.consent_life_unit,
        consent_life_value: data.consent_life_value,
        fi_data_from: data.fi_data_from,
        fi_data_to: data.fi_data_to,
        fi_data_unit: data.fi_data_unit,
        consent_mode: data.consent_mode,
        fetch_types: data.fetch_types,
        consent_types: `${arr}`.split(","),
        fi_types: `${arr2}`.split(","),
        data_life_unit: data.data_life_unit,
        data_life_value: data.data_life_value,
        frequency_unit: data.frequency_unit,
        frequency_value: data.frequency_value,
        data_filter_type: data.data_filter_type,
        data_filter_operator: data.data_filter_operator,
        data_filter_value: data.data_filter_value,
        purpose_code: "104",
        purpose_ref_uri: "https://api.rebit.org.in/aa/purpose/104.xml",
        purpose_text: "Aggregated statement",
        purpose_category: "Financial Reporting",
        fetch_data_range_unit: data.fetch_data_range_unit,
        fetch_data_range_value: data.fetch_data_range_value,
      },
    };
    const path = "/templates/" + props.template.uuid;
    try {
      setLoading(true);
      const result = await API.post("consentTemplates", path, requestInfo);
      const response = await API.get("consentTemplates", "/templates");
      props.setConsentTemplates(response.data);
      localStorage.setItem("tempId", response.data[0].uuid);
      setLoading(false);
      props.setTrigger(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error.response);
    }
  };
  function onKeyup(e) {
    if (e.target.value < 0) {
      e.target.value = 1;
    }
  }
  var allowed = ["MONTH", "YEAR"];
  return props.trigger ? (
    <Modal show>
      <div className="bigcontainer">
        {prompt ? (
          <UpdatePopup
            show={prompt}
            yes={updateConsent}
            trigger={prompt}
            setTrigger={setPrompt}
            onHide={() => setPrompt(false)}
          />
        ) : null}
        <div className="row-2">
          <div className="div2">
            <div className="newTemplateHeader">
              Edit Configuration
              <div className="templateClose">
                <img
                  src={close}
                  alt="/"
                  onClick={() => {
                    props.setTrigger(false);
                    setToggleDiv(false);
                    setToggleDiv1(false);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="div9">
            <div className="content">
              <div className="content-1">
                Consent Mode
                <div className="fiTypesInput">
                  <select
                    name="consent_mode"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.consent_mode == "VIEW" ? (
                      <option id="VIEW" value="VIEW" selected>
                        VIEW
                      </option>
                    ) : (
                      <option id="VIEW" value="VIEW">
                        VIEW
                      </option>
                    )}
                    {data.consent_mode == "STORE" ? (
                      <option id="STORE" value="STORE" selected>
                        STORE
                      </option>
                    ) : (
                      <option id="STORE" value="STORE">
                        STORE
                      </option>
                    )}
                  </select>
                </div>
              </div>
              <div className="content-2">
                Consent Types
                <div className="fiTypesInput1">
                  {toggleDiv ? (
                    <Dropdown
                      changeType={(type) => setConsentType(type)}
                      type={types}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        setToggleDiv(!toggleDiv);
                      }}
                      id="fitypesdiv"
                    >
                      <input
                        value={data.consent_types}
                        className="fitypes1"
                        type="text"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="content">
              <div className="content-1">
                Consent Life Unit
                <div className="fiTypesInput">
                  <select
                    name="consent_life_unit"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.consent_life_unit == "YEAR" ? (
                      <option selected value="YEAR">
                        YEAR
                      </option>
                    ) : (
                      <option value="YEAR">YEAR</option>
                    )}
                    {data.consent_life_unit == "MONTH" ? (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    ) : (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    )}
                    {data.consent_life_unit == "DAY" ? (
                      <option selected value="DAY">
                        DAY
                      </option>
                    ) : (
                      <option value="DAY">DAY</option>
                    )}
                    {data.consent_life_unit == "HOUR" ? (
                      <option selected value="HOUR">
                        HOUR
                      </option>
                    ) : (
                      <option value="HOUR">HOUR</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="content-2">
                Consent Life Value
                <div className="fiTypesInput">
                  <input
                    type="number"
                    placeholder="Enter a number"
                    onKeyPress={(e) => onKeyup(e)}
                    min="0"
                    name="consent_life_value"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                    value={data.consent_life_value}
                  />
                </div>
              </div>
            </div>

            <div className="content">
              <div className="content-1">
                Fetch Data Range Unit
                <div className="fiTypesInput">
                  <select
                    name="fetch_data_range_unit"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.fetch_data_range_unit == "YEAR" ? (
                      <option selected value="YEAR">
                        YEAR
                      </option>
                    ) : (
                      <option value="YEAR">YEAR</option>
                    )}
                    {data.fetch_data_range_unit == "MONTH" ? (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    ) : (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    )}
                    {data.fetch_data_range_unit == "DAY" ? (
                      <option selected value="DAY">
                        DAY
                      </option>
                    ) : (
                      <option value="DAY">DAY</option>
                    )}
                    {data.fetch_data_range_unit == "HOUR" ? (
                      <option selected value="HOUR">
                        HOUR
                      </option>
                    ) : (
                      <option value="HOUR">HOUR</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="content-2">
                Fetch Data Range Value
                <div className="fiTypesInput">
                  <input
                    type="number"
                    placeholder="Enter a number"
                    onKeyPress={(e) => onKeyup(e)}
                    min="0"
                    name="fetch_data_range_value"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                    value={data.fetch_data_range_value}
                  />
                </div>
              </div>
            </div>
            {fiuFlag &&
            (data.fetch_data_range_unit == "YEAR" ||
              (data.fetch_data_range_unit == "MONTH" &&
                data.fetch_data_range_value > 6) ||
              (data.fetch_data_range_unit == "DAY" &&
                data.fetch_data_range_value > 180) ||
              (data.fetch_data_range_unit == "HOUR" &&
                data.fetch_data_range_value > 4320)) ? (
              <span className="fetchRangeErr">
                *Fetch data range value cannot be greater than 6 months
              </span>
            ) : null}

            <div className="content">
              <div className="content-1">
                Fi Data From
                <div className="fiTypesInput">
                  <input
                    type="number"
                    placeholder="Enter a number"
                    onKeyPress={(e) => onKeyup(e)}
                    min="0"
                    name="fi_data_from"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                    value={data.fi_data_from}
                  />
                </div>
              </div>
              <div className="content-2">
                Fi Data To
                <div className="fiTypesInput">
                  <input
                    type="number"
                    placeholder="Enter a number"
                    onKeyPress={(e) => onKeyup(e)}
                    min="0"
                    name="fi_data_to"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                    value={data.fi_data_to}
                  />
                </div>
              </div>
            </div>

            <div className="content">
              <div className="content-1">
                Fi Data Unit
                <div className="fiTypesInput">
                  <select
                    name="fi_data_unit"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.fi_data_unit == "YEAR" ? (
                      <option selected value="YEAR">
                        YEAR
                      </option>
                    ) : (
                      <option value="YEAR">YEAR</option>
                    )}
                    {data.fi_data_unit == "MONTH" ? (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    ) : (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    )}
                    {data.fi_data_unit == "WEEK" ? (
                      <option selected value="WEEK">
                        WEEK
                      </option>
                    ) : (
                      <option value="WEEK">WEEK</option>
                    )}
                    {data.fi_data_unit == "DAY" ? (
                      <option selected value="DAY">
                        DAY
                      </option>
                    ) : (
                      <option value="DAY">DAY</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="content-2">
                Fetch Types
                <div className="fiTypesInput">
                  <select
                    name="fetch_types"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.fetch_types == "ONETIME" ? (
                      <option value="ONETIME" selected>
                        ONETIME
                      </option>
                    ) : (
                      <option value="ONETIME">ONETIME</option>
                    )}
                    {data.fetch_types == "PERIODIC" ? (
                      <option value="PERIODIC" selected>
                        PERIODIC
                      </option>
                    ) : (
                      <option value="PERIODIC">PERIODIC</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div className="content">
              <div className="content-1">
                FI types
                <div className="fiTypesInput1">
                  {toggleDiv1 ? (
                    <Dropdown
                      changeType={(type) => setFiData(type)}
                      type={fi_types}
                      singleSelection={true}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        setToggleDiv1(!toggleDiv1);
                      }}
                      id="fitypesdiv"
                    >
                      <input
                        value={data.fi_types}
                        className="fitypes2"
                        type="text"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="content-2">
                Data life unit
                <div className="fiTypesInput">
                  <select
                    name="data_life_unit"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.data_life_unit == "YEAR" ? (
                      <option selected value="YEAR">
                        YEAR
                      </option>
                    ) : (
                      <option value="YEAR">YEAR</option>
                    )}
                    {data.data_life_unit == "MONTH" ? (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    ) : (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    )}
                    {data.data_life_unit == "DAY" ? (
                      <option selected value="DAY">
                        DAY
                      </option>
                    ) : (
                      <option value="DAY">DAY</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="content-1">
                Data life value
                <div className="fiTypesInput">
                  {isView ? (
                    <input
                      disabled="true"
                      type="number"
                      min="0"
                      id="fiTypes"
                      name="data_life_value"
                      onChange={(e) => handle(e)}
                      value="0"
                    />
                  ) : (
                    <input
                      type="number"
                      placeholder="Enter a number"
                      onKeyPress={(e) => onKeyup(e)}
                      min="0"
                      id="fiTypes"
                      name="data_life_value"
                      onChange={(e) => handle(e)}
                      value={data.data_life_value}
                    />
                  )}
                </div>
              </div>
              <div className="content-2">
                Frequency unit
                <div className="fiTypesInput">
                  <select
                    name="frequency_unit"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.frequency_unit == "YEAR" ? (
                      <option selected value="YEAR">
                        YEAR
                      </option>
                    ) : (
                      <option value="YEAR">YEAR</option>
                    )}
                    {data.frequency_unit == "MONTH" ? (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    ) : (
                      <option selected value="MONTH">
                        MONTH
                      </option>
                    )}
                    {data.frequency_unit == "DAY" ? (
                      <option selected value="DAY">
                        DAY
                      </option>
                    ) : (
                      <option value="DAY">DAY</option>
                    )}
                    {data.frequency_unit == "HOUR" ? (
                      <option selected value="HOUR">
                        HOUR
                      </option>
                    ) : (
                      <option value="HOUR">HOUR</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            {(data.frequency_unit == "YEAR" && data.frequency_value > 360) ||
            (data.frequency_unit == "MONTH" && data.frequency_value > 30) ||
            (data.frequency_unit == "DAY" && data.frequency_value > 1) ||
            (data.frequency_unit == "HOUR" && data.frequency_value > 1) ? (
              <span className="fetchRangeErr">
                *Frequency value cannot be greater than 30 per month
              </span>
            ) : null}
            <div className="content">
              <div className="content-1">
                Frequency value
                <div className="fiTypesInput">
                  <input
                    type="number"
                    placeholder="Enter a number"
                    onKeyPress={(e) => onKeyup(e)}
                    min="0"
                    id="fiTypes"
                    name="frequency_value"
                    onChange={(e) => handle(e)}
                    value={data.frequency_value}
                  />
                </div>
              </div>
              <div className="content-2">
                Data filter operator
                <div className="fiTypesInput">
                  <select
                    name="data_filter_operator"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.data_filter_operator == "=" ? (
                      <option value="=" selected>
                        =
                      </option>
                    ) : (
                      <option value="=">=</option>
                    )}
                    {data.data_filter_operator == "!=" ? (
                      <option selected value="!=">
                        !=
                      </option>
                    ) : (
                      <option value="!=">!=</option>
                    )}
                    {data.data_filter_operator == ">" ? (
                      <option selected value=">">
                        &gt;
                      </option>
                    ) : (
                      <option value=">">&gt;</option>
                    )}
                    {data.data_filter_operator == "<" ? (
                      <option value="<" selected>
                        &lt;
                      </option>
                    ) : (
                      <option value="<">&lt;</option>
                    )}
                    {data.data_filter_operator == ">=" ? (
                      <option selected value=">=">
                        &gt;=
                      </option>
                    ) : (
                      <option value=">=">&gt;=</option>
                    )}
                    {data.data_filter_operator == "<=" ? (
                      <option selected value="<=">
                        &lt;=
                      </option>
                    ) : (
                      <option value="<=">&lt;=</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="content-1">
                Data filter value
                <div className="fiTypesInput">
                  <input
                    type="number"
                    placeholder="Enter a number"
                    onKeyPress={(e) => onKeyup(e)}
                    min="0"
                    id="fiTypes"
                    name="data_filter_value"
                    onChange={(e) => handle(e)}
                    value={data.data_filter_value}
                  />
                </div>
              </div>

              <div className="content-2">
                Data filter type
                <div className="fiTypesInput">
                  <select
                    name="data_filter_type"
                    id="fiTypes"
                    onChange={(e) => handle(e)}
                  >
                    {data.data_filter_operator == "<=" ? (
                      <option selected value="TRANSACTIONAMOUNT">
                        TRANSACTIONAMOUNT
                      </option>
                    ) : (
                      <option value="TRANSACTIONAMOUNT">
                        TRANSACTIONAMOUNT
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="div1">
            <button
              className={fiuFlag ? "saveDisabled" : "save"}
              disabled={fiuFlag ? true : false}
              onClick={() => setPrompt(true)}
            >
              {loading ? (
                <span
                  style={{ marginRight: "5px" }}
                  className="spinner-border spinner-border-sm mr-1"
                ></span>
              ) : (
                `Save Configuration`
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    ""
  );
}
export default EditPopup;
