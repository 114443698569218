import React, { useState,useEffect } from "react";
import "./Topbar.css";
import { useLocation, useNavigate} from "react-router-dom";
import logo from "../../../images/new_logo.png";
import Switch from "../../Switch/Switch";
import preloader from "../../../images/preloader.gif";
import { Auth, API } from "aws-amplify";
import { result } from "lodash";
const routes = [{ path: "/customers/:id" }]




function Topbar({setToggleLoading,toggleLoading}) {
  const location = useLocation();
  const navigate = useNavigate();

  // const params = useLocation().search
  let fiuDetails = JSON.parse(localStorage.getItem('result'))

  const id = location.pathname.split("/")[2]
  

  

  

  const [fiuResult,setFiuResult] = useState({})
  // const [toggleLoading,setToggleLoading] = useState(false)

  const [value, setValue] = useState(
    localStorage.getItem("fiu_stage") == undefined
      ? false
      : localStorage.getItem("fiu_stage") == "dev"
      ? false
      : true
  );

  const updateName = async () => {
    const result = await API.get("updateFiu", "/name");

    // localStorage.setItem('result',result)
    // localStorage.setItem("result", JSON.stringify(result));
    setFiuResult(result.data);
  
  };

  useEffect(() => {
    // console.log(location.pathname);

    // console.log(currentUser(), "current user")
    updateName();
    // if (location.pathname == "/") {
    //   navigate("/customers");
    // } else {
    //   navigate(location.pathname);
    // }
  }, []);


  //url on the basis of process
  const resetUrl = () =>{
    if(process.env.REACT_APP_STAGE=="prod"){
      return "api"
  }else if(process.env.REACT_APP_STAGE=="dev"){
      return "api-demo"
  }else{
      return "api-test"
  }
  }

  //stageEnableInput on the basis of env
   const stageEnableInput = () =>{
    if(fiuResult.stage_enabled == '1'){
      return '0'
    }else{
      return '1'
    }
   }
   const fetchTemplates = async () => {
    const requestInfo = {
        headers: {
            "Content-type": "application/json"
        }
    }
  
    //
    try {
        
        const response = await API.get("consentTemplates", '/templates', requestInfo)
        const templates = response.data 
        // console.log(templates)
        
        localStorage.setItem('tempId',templates[0].uuid)
            
  
    } catch (err) {
        console.log('Error', err)
    }
  }
  

  // switch stage
  const switchStage = async (toggle) => {

    localStorage.setItem('loading',true)

    let stageEnabled = localStorage.getItem('stageEnabled')

    setToggleLoading(true)

    // process.env.TOGGLE = process.env.REACT_APP_STAGE
    
      const requestOptions = {
        method: 'POST',
        headers: {
          // "authorization": ' xxxxxxxxxx' ,
          // "Content-Type":"application/json",
          // "x-api-key": "UooVIIsiQV5TxqvjzBldE2ymM67nTfbW5FjzBlIA"
        },
  
        body: {
          uuid:fiuResult.uuid,
          stageInput:stageEnabled==0?1:0
        }
      };
  
      try{
        
        // console.log("here in try")
  
        const res = await API.post("stageEnabled", '/stageEnabled', requestOptions)
            // console.log(res.data.stage_enabled,"response enabled")
            

            
        if(res.data.stage_enabled == 1){
          localStorage.setItem("fiu_stage", "prod");
          localStorage.setItem('env','prod')
          localStorage.setItem("apiKey", res.data.prod_api_key);
          localStorage.setItem('stageEnabled',res.data.stage_enabled)
          // fetchTemplates()

          refreshPage();
          



        }else if(res.data.stage_enabled == 0){
          localStorage.setItem("fiu_stage", "dev");
          localStorage.setItem('env','dev')
          localStorage.setItem("apiKey", res.data.dev_api_key);
          localStorage.setItem('stageEnabled',res.data.stage_enabled)
          refreshPage();
          
          

          
        }
        
        
          
  
      }catch(err){
        
        // console.log(err, "errrors")
        // setResetResponse("")
      }
      
     
    // else {
    //   localStorage.setItem("fiu_stage", "dev");
    //   refreshPage();
    // }
  };

  // console.log(localStorage.getItem('env'), "envvv")

  // refresh the page
  const refreshPage = () => {
    setTimeout(() => {
      if(location.pathname != `/customers/${id}/data-requests`){
    
        

        window.location.reload(false);
        // fetchTemplates()
       
        setTimeout(()=>{ setToggleLoading(false)},1000)

      }else{
       
        navigate('/customers')
        window.location.reload()
        // fetchTemplates()
        
        setTimeout(()=>{ setToggleLoading(false)},1000)
        
        // setToggleLoading(false)
        
        // localStorage.setItem('loading',false)
        
      }
     
    }, 2500);
  };

  return (
    <>
    <div
      className="topbar_container"
      style={{
        background: value
          ? "linear-gradient(270deg, rgba(250, 112, 154, 0.5) -25.88%, rgba(254, 225, 64, 0) 112.3%)"
          : "linear-gradient(89.99deg,rgba(248, 251, 255, 0.6) 0.51%, rgba(67, 130, 217, 0) 142.24%)",
        backgroundColor: value ? "#FA800B33" : "#3a86ff66",
      }}
    >
      {/* logo */}
      <a href="/customers" style={{ marginLeft: "20px" }}>
        <img src={logo} alt="" className="topbar_logo" />
      </a>

      {/* stage container */}
      <div
        className="topbar_stage_container"
        style={{
          display: location.pathname === "/consent-flow" ? "none" : "flex",
        }}
      >
        {/* dev stage */}
        <p
          className="topbar_stage_dev"
          style={{
            color: !value ? "#3a86ff" : "#3a3a3a",
          }}
        >
          SANDBOX
        </p>
        {/* switch */}
        <Switch
          isOn={value}
          buttonColor={value ? "#FA800B" : "#3A86FF"}
          onColor="#fff"
          handleToggle={() => {
            setValue(!value);
            switchStage(!value);
          }}
        />
        {/* prod stage */}
        <p
          className="topbar_stage_prod"
          style={{
            color: value ? "#fa7d0d" : "#3a3a3a",
          }}
        >
          PRODUCTION
        </p>
      </div>
      
    </div>
   
    </>
    
  );
}

export default Topbar;
