import React, { useEffect, useState } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Amplify, API, Auth } from "aws-amplify";
import { createBrowserHistory } from "history";
import preloader from "../src/images/preloader.gif";
import Dashboard from "./Components/Dashboard";
import Login2 from "./Components/Login/login";
import awsconfig from "./Constant/aws-exports";

Amplify.configure(awsconfig[process.env.REACT_APP_STAGE].Auth);
API.configure(awsconfig[process.env.REACT_APP_STAGE]);

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toggleLoading, setToggleLoading] = useState(false);
  const location = createBrowserHistory();

  // useeffect of app function
  useEffect(async () => {
    checkStage();
  }, []);

  // check stage
  const checkStage = async () => {
    localStorage.setItem("env", process.env.REACT_APP_STAGE);

    // check if user logged in or not
    const check = await checkUserLoggedIn();

    // if fiu stage has some value
    if (localStorage.getItem("fiu_stage") != undefined) {
      // if stage is dev
      if (localStorage.getItem("fiu_stage") == "dev") {
        API.configure(awsconfig["dev"]);
        if (check) {
          setLoggedIn(true);
          setLoading(false);
          getFIUDetails();
        } else {
          setLoggedIn(false);
          setLoading(false);
        }
        window.sessionStorage.setItem("loggedIn", loggedIn);
      }

      // if stage is prod
      else if (localStorage.getItem("fiu_stage") == "prod") {
        API.configure(awsconfig["prod"]);
        if (check) {
          setLoggedIn(true);
          setLoading(false);
          getFIUDetails();
        } else {
          setLoggedIn(false);
          setLoading(false);
        }
        window.sessionStorage.setItem("loggedIn", loggedIn);
      }
    }

    // else fiu stage has no value
    else {
      API.configure(awsconfig[process.env.REACT_APP_STAGE]);
      if (check) {
        setLoggedIn(true);
        setLoading(false);
        getFIUDetails();
      } else {
        setLoggedIn(false);
        setLoading(false);
      }
      window.sessionStorage.setItem("loggedIn", loggedIn);
    }
  };

  // check if user is logged in or not
  const checkUserLoggedIn = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch {
      return false;
    }
  };

  // on sign in
  const onSignIn = () => {
    getFIUDetails();
    fetchTemplates();
  };

  // fetch templates
  const fetchTemplates = async () => {
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };

    // call the api
    try {
      const response = await API.get(
        "consentTemplates",
        "/templates",
        requestInfo
      );
      const templates = response.data;
      localStorage.setItem("tempId", templates[0].uuid);
    } catch (err) {
      console.log("Error in fetching templates ::: ", err);
    }
  };

  // get fiu details
  const getFIUDetails = async () => {
    try {
      const result = await API.get("updateFiu", "/name");

      // if stage is me
      if (process.env.REACT_APP_STAGE == "me") {
        localStorage.setItem("apiKey", result.data.dev_api_key);
        localStorage.setItem("fiuName", result.data.name);
      }

      // if stage is not me
      else {
        localStorage.setItem("fiuName", result.data.name);

        // if stage enabled 1 then fiu is in prod
        if (result.data.stage_enabled == 1) {
          localStorage.setItem("fiu_stage", "prod");
          localStorage.setItem("apiKey", result.data.prod_api_key);
        }

        // if stage enabled not available then fiu is in me
        else {
          localStorage.setItem("fiu_stage", "dev");
          localStorage.setItem("apiKey", result.data.dev_api_key);
        }
      }
      localStorage.setItem("stageEnabled", result.data.stage_enabled);
      localStorage.setItem("fiuEmail", result.data.email);
      localStorage.setItem("prod_enable", result.data.prod_enable);
      delete result.data.uuid;
      localStorage.setItem("ceplr_fiu", JSON.stringify(result.data));
      getFiuLogo();
    } catch (err) {
      console.log("Error in fetching fiu details ::: ", err);
    }
  };

  // get fiu logo
  const getFiuLogo = async () => {
    try {
      const result = await API.get("updateFiu", "/logo");
      localStorage.setItem("images", result.message);
      setLoggedIn(true);
    } catch (err) {
      localStorage.setItem("images", "");
      setLoggedIn(true);
    }
  };

  if (!loading) {
    if (loggedIn) {
      return (
        <section id="app">
          <Router>
            <Routes>
              <Route
                path="*"
                element={
                  <Dashboard
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setLoading={setLoading}
                    setToggleLoading={setToggleLoading}
                    toggleLoading={toggleLoading}
                    onLogout={async () => {
                      setLoading(true);
                      await Auth.signOut();
                      localStorage.clear();
                      setLoggedIn(false);
                      setLoading(false);
                    }}
                  />
                }
              />
            </Routes>
          </Router>
        </section>
      );
    } else {
      return (
        <section id="app">
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  <Login2
                    onSignIn={onSignIn}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                  />
                }
              />
              <Route
                exact
                path="*"
                element={
                  <Navigate
                    to="/"
                    replace
                    state={{ from: location.location }}
                  />
                }
              />
            </Routes>
          </Router>
        </section>
      );
    }
  } else {
    return (
      <section id="app">
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={preloader} />
        </div>
      </section>
    );
  }
}

export default App;
