import React, { useState } from "react";
import { API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

function DateInput(props) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateInvalid, setStartDateInvalid] = useState("");
  const [endDateInvalid, setEndDateInvalid] = useState("");
  const [loader, setLoader] = useState(false);
  const [overallInvalid, setOverallInvalid] = useState("");

  // check the form data
  const checkData = () => {
    setLoader(true);
    let foundErr = false;

    // check start date
    if (startDate.length == 0) {
      setStartDateInvalid("Invalid start date");
      foundErr = true;
      setLoader(false);
    }

    // check end date
    if (endDate.length == 0) {
      setEndDateInvalid("Invalid end date");
      foundErr = true;
      setLoader(false);
    }

    // if start date greater than today
    if (new Date(startDate) > new Date(Date.now())) {
      setStartDateInvalid("Invalid start date, should not greater than today");
      foundErr = true;
      setLoader(false);
    }

    // if start date year less than the given date
    if (new Date(startDate) < new Date("01/01/2022")) {
      setStartDateInvalid("Invalid start date, should not less than year 2022");
      foundErr = true;
      setLoader(false);
    }

    // if end date year greater than current date
    if (new Date(endDate) > new Date(Date.now())) {
      setEndDateInvalid("Invalid end date, should not greater than today");
      foundErr = true;
      setLoader(false);
    }

    // if all good
    if (!foundErr) {
      if (new Date(endDate) - new Date(startDate) < 1) {
        setOverallInvalid(
          "Invalid Dates. The difference between start date and end date should be 1 or greater than 1"
        );
        setLoader(false);
      } else {
        fetchReport();
      }
    }
  };

  // fetch analytics report
  const fetchReport = async () => {
    // get api key
    const apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "";

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
        "x-api-key": apiKey,
      },
      body: {
        start_date: startDate,
        end_date: endDate,
      },
    };

    // call the api
    try {
      const response = await API.post(
        "analysis",
        `/${props.uuid}/report`,
        requestInfo
      );
      props.onSuccess(response.data, startDate, endDate);
    } catch (error) {
      console.log("error while getting analysis data ::; ", error.response);
      props.onError();
    }
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      {/* header */}
      <Modal.Header closeButton>
        <Modal.Title>Analysed Data</Modal.Title>
      </Modal.Header>

      {/* body */}
      <Modal.Body>
        <Form autoComplete="off">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              disabled={loader}
              type="date"
              max="9999-12-31"
              onChange={(e) => {
                setOverallInvalid("");
                setStartDateInvalid("");
                setStartDate(e.target.value);
              }}
              placeholder="Enter start date"
              isInvalid={startDateInvalid.length > 0 ? true : false}
            />
            <Form.Control.Feedback
              type={startDateInvalid.length > 0 ? "invalid" : "valid"}
            >
              {startDateInvalid}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              disabled={loader}
              type="date"
              max="9999-12-31"
              onChange={(e) => {
                setOverallInvalid("");
                setEndDateInvalid("");
                setEndDate(e.target.value);
              }}
              placeholder="Enter end date"
              isInvalid={endDateInvalid.length > 0 ? true : false}
            />
            <Form.Control.Feedback
              type={endDateInvalid.length > 0 ? "invalid" : "valid"}
            >
              {endDateInvalid}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>

      {/* invalid  */}
      {overallInvalid.length > 0 ? (
        <p
          style={{
            margin: 0,
            padding: 0,
            marginTop: -20,
            marginBottom: "10px",
            color: "#dc3545",
            width: "93%",
            textAlign: "left",
            alignSelf: "center",
            fontSize: "11.3px",
          }}
        >
          {overallInvalid}
        </p>
      ) : null}

      {/* footer */}
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ cursor: "pointer", width: "120px", height: "40px" }}
          variant="primary"
          onClick={() => checkData()}
        >
          {loader ? <Spinner animation="border" size="sm" /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DateInput;
