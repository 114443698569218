import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "./DropDown";
import { ToastContainer, toast } from "react-toastify";
import bankStatementImage from "../../images/bankStatementImage.svg";
import uploadDisabled from "../../images/uploadIcon.svg";

import successTick from "../../images/successTick.svg";

import plusIcon from "../../images/plusIcon.svg";

import pdfFrame from "../../images/pdfFrame.svg";
import successPolling from "../../images/Success.svg";
import pdfError from "../../images/pdfError.svg";

import deleteIcon from "../../images/deleteIcon.svg";

import * as pdfjsLib from "pdfjs-dist";
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

function PDFModal(props) {
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [nameInvalid, setNameInvalid] = useState("");
  const [mobileInvalid, setMobileInvalid] = useState("");
  const [emailInvalid, setEmailInvalid] = useState("");
  const [loader, setLoader] = useState(false);
  const [linkId, setLinkId] = useState("");
  const [start, setStart] = useState(false);

  const [selected, setSelected] = useState("Select your bank");

  const [token, setToken] = useState("");
  const [partnerFIP, setPartnerFIP] = useState("");
  const [netBankingLoading, setNetBankingLoading] = useState(false);
  const [changeBank, setChangeBank] = useState(true);

  const [fileName, setFileName] = useState("Upload statement");
  const [uploaded, setUploaded] = useState(false);

  const [pdf, setPdf] = useState({});

  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [processed, setProcessed] = useState(false);

  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const [inputDisabled, setInputDisabled] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const [pdfBorderSelected, setPdfBorderSelected] = useState(false);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [pdfBlueBorder, setPdfBlueBorder] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [index, setIndex] = useState("");

  const [bankLoading, setBankLoading] = useState(false);
  const [bankSuccess, setBankSuccess] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [bankData, setBankData] = useState("");
  const [pdfList, setPdfList] = useState([
    {
      file: "",
      isUploaded: false,
      isFileSelected: false,
      isPasswordProtected: false,
      isDisabled: false,
    },
  ]);
  const [startProcessing, setStartProcessing] = useState(false);
  const [success, setSuccess] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [message, setMessage] = useState(
    "Statement upload is in progress for given transaction"
  );
  const [processingError, setProccesingError] = useState(false);
  const [processingLoading, setProcessingLoading] = useState(false);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    if (process.env.REACT_APP_STAGE == "dev") {
      setBaseUrl("api-demo");
    } else if (process.env.REACT_APP_STAGE == "prod") {
      setBaseUrl("api");
    } else if (process.env.REACT_APP_STAGE == "me") {
      setBaseUrl("api-test");
    }
  }, []);

  // check the form data
  const checkData = () => {
    setLoader(true);
    let foundErr = false;
    // check name
    if (name.length == 0) {
      setNameInvalid("Invalid name");
      // foundErr = true;
      // setLoader(false);
    }
    // check mobile
    var mobile_format = /^[6-9][0-9]{9}$/;
    if (!mobile_format.test(mobile)) {
      setMobileInvalid("Invalid mobile");
      foundErr = true;
      setLoader(false);
    }
    // check email
    var mail_format =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail_format.test(String(email).toLowerCase())) {
      setEmailInvalid("Invalid email");
      // foundErr = true;
      // setLoader(false);
    }

    //  if all good
    if (!foundErr) {
      // initize data
      const data = {
        customer_name: name,
        customer_contact: mobile,
        customer_email: email,
        configuration_uuid: props.configuration_uuid,
        redirect_url: "https://ceplr.com/",
        callback_url: "https://ceplr.com/",
      };
      sendConsent(data);
    }
  };

  // send consent
  const sendConsent = async (data) => {
    try {
      // get api key
      const apiKey = localStorage.getItem("apiKey")
        ? localStorage.getItem("apiKey")
        : "";
      // create param
      const params = {
        headers: {
          "Content-type": "application/json",
          "x-api-key": apiKey,
        },
        body: data,
      };
      // call the api
      let result = await API.post("sendConsent", "/consents", params);
      setLinkId(result.data.url.split("/")[4].split("?")[0]);
      bankList(result.data.url.split("/")[4].split("?")[0]);
      setStart(true);

      setLoader(false);
      //   props.onSuccess();
    } catch (e) {
      console.log("Error while sending consent ::: ", e);
      setLoader(false);
      props.onError();
    }
  };

  // console.log(linkId, "linkId")

  const getToken = async (index) => {
    // console.log(bankData[index], "bank data index")
    setBankLoading(true);

    const requestOptions = {
      // method: "POST",

      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        // "Content-Type":"application/json",
        //  "x-api-key":"qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"
      },

      body: { institution_id: bankData[index].id },
    };
    // console.log(requestOptions, "requestOptions")
    try {
      let res = await API.post(
        "getBanks",
        `/pdf/${linkId}/initiate`,
        requestOptions
      );

      // console.log
      if (res.code == 200) {
        setBankLoading(false);
        // console.log(res, "response")
        setInputDisabled(true);
        setToken(res.data);

        //Retrieve the object

        //put the obejct back

        // localStorage.setItem('requestId',res.data.request_id)
        // localStorage.setItem('txnId',res.data.txn_id)
        // setSelected(false)
        setFileSelected(false);
        setUploaded(false);
        setDisable(false);
        setBankSuccess(true);
        setPdfBorderSelected(true);
        setFileUploaded(false);
        setIsEncrypted(false);
        setPdfList([
          {
            file: "",
            isUploaded: false,
            isFileSelected: false,
            isPasswordProtected: false,
            isDisabled: false,
          },
        ]);
      } else {
        setSelected("Select your bank");
        setBankLoading(false);
        setFileSelected(false);
        setUploaded(false);
        setDisable(false);
        setBankSuccess(true);
        setPdfBorderSelected(true);
        setFileUploaded(false);
        setIsEncrypted(false);
        setPdfList([{ file: "", isUploaded: false, isFileSelected: false }]);
        if (res.message) {
          if (res.message.msg) {
            toast.configure(res.message.msg);
            toast.error(res.message.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "initiateUploadError",
            });
          } else {
            toast.configure("Something went wrong, please try again later.");
            toast.error("Something went wrong, please try again later.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "initiateUploadError",
            });
          }
        } else {
          toast.configure("Something went wrong, please try again later.");
          toast.error("Something went wrong, please try again later.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "initiateUploadError",
          });
        }
      }
    } catch (error) {
      // console.log(error, "error");
      setFileSelected(false);
      setPdfList([{ file: "", isUploaded: false, isFileSelected: false }]);
      setUploaded(false);
      setDisable(false);
      setBankSuccess(true);
      setPdfBorderSelected(true);
      setFileUploaded(false);
      setIsEncrypted(false);
    }
  };

  const bankList = async (id) => {
    // Fetch data from external API

    try {
      const apiKey = localStorage.getItem("apiKey")
        ? localStorage.getItem("apiKey")
        : "";

      const requestOptions = {
        // method: "GET",
        headers: {
          "x-api-key": apiKey,
        },
      };

      // const res = await fetch(
      //   `https://${subdomain}.ceplr.com/pdf/${id}/institutions`,
      //   requestOptions
      // );
      let result = await API.get(
        "getBanks",
        `/pdf/${id}/institutions`,
        requestOptions
      );

      // var res=data.res

      // const data = await res.json()
      // console.log(result, "bankList")
      setBankData(result.data);
    } catch (error) {
      // console.log(error, "error");
    }

    // const id  = context.query.id;
  };

  const hiddenFileInput = React.useRef(null);

  // console.log(pdfList[0].file ? pdfList[0].file.name : "", "filess");

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadFileInput = React.useRef(null);

  const uploadClick = (event) => {
    uploadFileInput.current.click();
  };

  const handleChange = (e, pdfIndex) => {
    // console.log(e.target.files[0], "files uploaded")
    // eventLogging(logEvents.pdf_upload_screen.change_file);

    let files = {};
    if (e.target && e.target.files[0]) {
      files = e.target.files[0];
    } else {
      // console.log(pdfList[pdfIndex].file, "file previous state")
      files = pdfList[pdfIndex].file;
    }

    const list = [...pdfList];
    // list[pdfIndex] = {
    //   file: files,
    //   isUploaded: false,
    //   isFileSelected: true,
    //   isPasswordProtected:false,
    //   isDisabled:false
    // };
    // setPdfList(list);

    // let files = e.target.files;

    if (e.target && e.target.files[0]) {
      setPdf(e.target.files[0]);
    }
    // console.log(e.target.files,"files")

    // let files =pdfList

    const reader = new FileReader();

    // console.log(reader, "reader")

    // console.log(files, "files before reader")
    reader.readAsArrayBuffer(
      e.target.files[0] == null ? files : e.target.files[0]
    );

    var fileProtected = false;

    // console.log(reader.result, "reader result")
    reader.onload = function () {
      let arr = new Uint8Array(this.result);
      const pdfFile = pdfjsLib.getDocument({ data: arr });
      pdfFile.onPassword = (callback, reason) => {
        if (reason == 1) {
          fileProtected = true;
          list[pdfIndex] = {
            file: files,
            isUploaded: false,
            isFileSelected: true,
            isPasswordProtected: true,
            isDisabled: true,
          };
          setDisable(false);
          setChecked(true);
          setIsEncrypted(true);
          setPdfList(list);
          setUploaded(false);
        }
      };

      if (!fileProtected) {
        list[pdfIndex] = {
          file: files,
          isUploaded: false,
          isFileSelected: true,
          isPasswordProtected: false,
          isDisabled: true,
        };
        setDisable(false);
        setChecked(false);
        setIsEncrypted(false);
        setPdfBlueBorder(true);
        setPdfList(list);
        setUploaded(false);
      }

      // var myFile = new Blob([reader.result], { type: "application/pdf" });
      // myFile.text().then((x) => {
      //   // console.log("isEncrypted", x.includes("Encrypt")) // true, if Encrypted
      //   // console.log("isEncrypted", x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt"));

      //   if (x.includes("Encrypt")) {
      //     list[pdfIndex] = {
      //       file: files,
      //       isUploaded: false,
      //       isFileSelected: true,
      //       isPasswordProtected: true,
      //       isDisabled: true,
      //     };
      //     setDisable(false);
      //     setChecked(true);
      //     setIsEncrypted(true);
      //     setPdfList(list);
      //     setUploaded(false);
      //   } else {
      //     list[pdfIndex] = {
      //       file: files,
      //       isUploaded: false,
      //       isFileSelected: true,
      //       isPasswordProtected: true,
      //       isDisabled: true,
      //     };
      //     setDisable(false);
      //     setChecked(false);
      //     setIsEncrypted(false);
      //     setPdfBlueBorder(true);
      //     setPdfList(list);
      //     setUploaded(false);
      //   }
      //   // console.log(file.name);
      // });
    };

    setFileSelected(true);
    setUploaded(false);
    setPassword("");
    setFileUploaded(false);
  };

  // console.log(isEncrypted, "encrypted")

  // const isCLicked = (e) =>{
  //     setClicked(true)
  // }

  // console.log(token.token, "token")

  // console.log(process, "partner fip")

  // console.log(partnerFIP, "partnerFIP")
  // console.log(partnerFIP.fipId?partnerFIP.fipId:"", "yes or not")

  const uploadSuccess = () => {
    toast.configure();
    toast.success("Upload Successful", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "uploadSuccess",
    });
  };

  const onChange1 = (e) => {
    // console.log("reeeee", e);
    if (inputDisabled) {
      //do nothing
    } else {
      e.preventDefault();
      toast.configure();
      toast.error("Please select a bank", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "selectBank",
      });
    }
  };

  const onChange = (e, pdfIndex) => {
    // const {value} = e.target.files[0]
    // setDisable(true)

    let files = {};
    if (e.target && e.target.files[0]) {
      files = e.target.files[0];
    } else {
      // console.log(pdfList[pdfIndex].file, "file previous state");
      files = pdfList[pdfIndex].file;
    }

    const list = [...pdfList];
    // list[pdfIndex] = {
    //   file: files,
    //   isUploaded: false,
    //   isFileSelected: true,
    //   isPasswordProtected:false,
    //   isDisabled:false
    // };
    // setPdfList(list);

    // let files = e.target.files;

    if (e.target && e.target.files[0]) {
      setPdf(e.target.files[0]);
    }
    // console.log(e.target.files,"files")

    // let files =pdfList

    const reader = new FileReader();

    // console.log(reader, "reader")

    // console.log(files, "files before reader")
    reader.readAsArrayBuffer(
      e.target.files[0] == null ? files : e.target.files[0]
    );

    var fileProtected = false;

    reader.onload = function () {
      let arr = new Uint8Array(this.result);
      const pdfFile = pdfjsLib.getDocument({ data: arr });
      pdfFile.onPassword = (callback, reason) => {
        if (reason == 1) {
          fileProtected = true;
          list[pdfIndex] = {
            file: files,
            isUploaded: false,
            isFileSelected: true,
            isPasswordProtected: true,
            isDisabled: true,
          };
          setDisable(false);
          setChecked(true);
          setIsEncrypted(true);
          setPdfList(list);
          setUploaded(false);
        }
      };

      if (!fileProtected) {
        list[pdfIndex] = {
          file: files,
          isUploaded: false,
          isFileSelected: true,
          isPasswordProtected: false,
          isDisabled: true,
        };
        setDisable(false);
        setChecked(false);
        setIsEncrypted(false);
        setPdfBlueBorder(true);
        setPdfList(list);
        setUploaded(false);
      }

      // var myFile = new Blob([reader.result], { type: "application/pdf" });
      // myFile.text().then((x) => {
      //   // console.log("isEncrypted", x.includes("Encrypt")) // true, if Encrypted
      //   // console.log("isEncrypted", x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt"));

      //   if (x.includes("Encrypt")) {
      //     list[pdfIndex] = {
      //       file: files,
      //       isUploaded: false,
      //       isFileSelected: true,
      //       isPasswordProtected: true,
      //       isDisabled: true,
      //     };
      //     setDisable(false);
      //     setChecked(true);
      //     setIsEncrypted(true);
      //     setPdfList(list);
      //     setUploaded(false);
      //   } else {
      //     list[pdfIndex] = {
      //       file: files,
      //       isUploaded: false,
      //       isFileSelected: true,
      //       isPasswordProtected: true,
      //       isDisabled: true,
      //     };
      //     setDisable(false);
      //     setChecked(false);
      //     setIsEncrypted(false);
      //     setPdfBlueBorder(true);
      //     setPdfList(list);
      //     setUploaded(false);
      //   }
      //   // console.log(file.name);
      // });
    };

    setFileSelected(true);
    setUploaded(false);
    setPassword("");
    setFileUploaded(false);
  };

  // console.log(pdfList, "pdfList");

  // console.log(fileName, "fileName")

  const goToNext = async () => {
    setProcessed(true);

    let subdomain = "";
    if (process.env.REACT_APP_STAGE == "dev") {
      subdomain = "api-demo";
    } else if (process.env.REACT_APP_STAGE == "prod") {
      subdomain = "api";
    } else if (process.env.REACT_APP_STAGE == "me") {
      subdomain = "api-test";
    }

    const requestOptions = {
      // method: "POST",

      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        // "Content-Type":"application/json",
        //  "x-api-key":"qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"
      },

      body: {
        request_id: token.request_id,
        txn_id: token.txn_id,
      },
    };

    // console.log(requestOptions, "requestOptions")
    // console.log(JSON.stringify({request_id:token.request_id}),"json stringify")
    try {
      let res = await API.post(
        "getBanks",
        `/pdf/${linkId}/complete`,
        requestOptions
      );

      // console.log(res, "response")
      if (res.code == "200") {
        setStartProcessing(true);
        fetchStatus();
      } else {
        completeUploadError(res);
        setIsError(true);
        setProcessed(false);
      }

      // setToken(res.data)

      // console.log('The link was clicked.');
    } catch (error) {
      // console.log(error, "error");
      completeUploadError(error.response.data);
    }
  };

  const completeUploadError = (res) => {
    // console.log(message.msg)

    let errorMess = "";
    errorMess = res.message.msg;

    // if (res.message.code == "StatementDateRange") {
    //   errorMess = res.message.msg;
    //   //   errorMess = `Please upload your last ${fetchValue()} ${
    //   //     fetchValue() == "1"
    //   //       ? fetchUnit()!=""?fetchUnit().toLowerCase():""
    //   //       : fetchUnit()!=""?fetchUnit().toLocaleLowerCase() + "s":""
    //   //   }  bank statement – from ${exactDate()} till date.`;
    // } else {
    //   errorMess = res.message.msg;
    // }
    toast.configure(errorMess);
    toast.error(errorMess, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "completeUploadError",
    });
    setProcessed(false);
  };

  const errorToast = (errorMess) => {
    toast.configure(errorMess);
    toast.error(errorMess, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "uploadError",
    });
  };

  // console.log(process.env.FIP, "FIP process")

  const uploadFileError = (res, pdfIndex) => {
    let errorMess = "";
    if (res.code == "InvalidPdfType") {
      errorMess = `Unable to detect the bank statement format for the selected bank. Either the statement belongs to a different bank or it's an unsupported format. Please check the statement and try again.`;
    } else if (res.code == "FileEditorBlacklisted") {
      errorMess =
        "Uploaded statement does not seems to be an original bank statement or It is a tampered pdf. Please get the original bank statement and try again.";
    } else if (res.code == "InvalidFilePassword") {
      errorMess = "Wrong password provided to open a password protected file";
    } else if (res.code == "RequiredToken") {
      errorMess = "Something went wrong. Please try again.";
    } else if (res.code == "NoFilePassword") {
      errorMess =
        "No password provided to open a password protected file. Please enter the correct password.";
    } else {
      errorMess = res.msg;
    }
    errorToast(errorMess);
  };

  // console.log(uploadFileError(), "upload file ")

  const uploadFile = async (e, pdfIndex) => {
    if (isEncrypted) {
      if (password.length == 0) {
        errorToast(
          "Please provide the password for the password protected file"
        );
        return;
      }
    }

    setLoading(true);

    // console.log(pdfIndex, "which file");

    let domain = "";
    if (process.env.REACT_APP_STAGE == "dev") {
      if (localStorage.getItem("fiu_stage") == "dev") {
        domain = "svcdemo.digitap.work/bank-data";
      } else {
        domain = "svc.digitap.ai/bank-data";
      }
    } else if (process.env.REACT_APP_STAGE == "me") {
      domain = "svcdemo.digitap.work/bank-data";
    }

    // use FormData to build the payload as multipart/form-data
    const formData = new FormData();
    formData.append("file", pdfList[pdfIndex].file);
    formData.append("token", token ? token.token : "");
    formData.append("request_id", token ? token.request_id : "");
    formData.append(
      "file_password_b16",
      new Buffer.from(password).toString("hex")
    );

    // formData.append('file_password_b16', password);

    // console.log(formData.get("file_password_b16"),"encoded")
    let list = [...pdfList];

    const requestOptions = {
      method: "POST",

      //     headers: {
      //         // "authorization": ' xxxxxxxxxx' ,

      //         "Content-Type":"application/json",
      //               //  "x-api-key":"qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"

      // },

      body: formData,
    };

    // make api call with image payload
    try {
      await fetch(`https://${domain}/uploadstmt`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          // console.log(res,"response");
          if (res.status == "success") {
            list[pdfIndex] = {
              file: pdfList[pdfIndex].file,
              isUploaded: true,
              isFileSelected: true,
              isPasswordProtected: false,
              isDisabled: false,
            };
            setUploaded(true);
            setLoading(false);
            setFileUploaded(true);
            setPassword("");
            uploadSuccess();
            setPdfList(list);
            setDisable(false);
            // setUploaded(false)
            uploadFileInS3(
              pdfList[pdfIndex].file,
              password,
              token ? token.request_id : ""
            );
          } else {
            list[pdfList.length - 1] = {
              file: {},
              isUploaded: false,
              isFileSelected: false,
              isPasswordProtected: false,
              isDisabled: false,
            };
            setUploaded(false);
            setLoading(false);
            setIsError(true);
            uploadFileError(res, pdfIndex);
            if (res.code == "UploadLimitExceeded") {
              // console.log("yaha hone ka change")
              setPdfList(list);
            }
            // if(res.code == "InvalidPdfType"){
            //       return error = ""
            // }
          }
        });
    } catch (error) {
      setLoading(false);
      // console.log(error,"error ")
    }
  };

  // console.log(pdf,"pdfff")
  // console.log(data,"dataaaaaa")

  const passwordPdf = (event) => {
    // console.log(event.target.value)

    // let eventPassword = event.target.value

    // console.log(,"password")

    setPassword(event.target.value);
    setDisable(true);
    setPdfBlueBorder(true);
  };

  const handleChecked = (e) => {
    // console.log(document.getElementById('files'), "filess")
    if (isEncrypted) {
      if (password.length == 0) {
        errorToast("File required password, Please provide the password.");
      } else {
        errorToast("File required password.");
      }

      // setDisable(false);
      // setChecked(!checked);
    } else {
      errorToast("File does not required password.");
      // setDisable(true);
      // setChecked(!checked);
    }
  };

  // console.log(pdf, "pdf");

  const addButton = () => {
    setPdfList([
      ...pdfList,
      {
        file: {},
        isUploaded: false,
        isFileSelected: false,
        isPasswordProtected: false,
        isDisabled: false,
      },
    ]);
  };

  const handleRemove = (pdfIndex) => {
    if (pdfList.length == 1) {
      setPdfList([
        {
          file: {},
          isUploaded: false,
          isFileSelected: false,
          isPasswordProtected: false,
          isDisabled: false,
        },
      ]);
    } else {
      const list = [...pdfList];
      list.splice(pdfIndex, 1);
      setPdfList(list);
    }
  };

  let options = {
    // method: "POST",

    headers: {
      // "authorization": ' xxxxxxxxxx' ,
      // "Content-Type":"application/json",
      //    "x-api-key":personalDetails.id
      // "x-api-key":"qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU" ,
    },
    body: {
      request_id: token.request_id,

      txn_id: token.txn_id,
    },
  };

  let retries = 5;

  const fetchStatus = async () => {
    try {
      let res = await API.post("getBanks", `/pdf/${linkId}/status`, options);

      if (res.message == "success") {
        setSuccess(res.message);
        setMessage("Statement upload completed for given transaction");
        setTimeout(() => {
          props.onSuccess();
        }, 1000);
      } else if (res.code == 422) {
        setMessage(res.message);
        setProccesingError(true);
      } else if (res.data.txn_status.map((item) => item.status) == "Failure") {
        setMessage("Unable to process the file.");
        setProccesingError(true);
      } else if (retries > 0) {
        setTimeout(() => fetchStatus(retries - 1), 4000);

        setSuccess(res.data.txn_status.map((item) => item.status));
        setMessage("Statement upload is in progress for given transaction");
      } else if (retries == 0) {
        setMessage("Request Timed Out");
        setProccesingError(true);
      }
    } catch (error) {
      console.error(error.message, "error message");
    }

    // setProccesingError(true)}
  };
  // console.log(txnId(),requestId(), "this is what we need here")

  // call pdf upload api in s3
  const uploadFileInS3 = async (s3File, filePassword, requestId) => {
    const myHeaders = new Headers();
    const formdata = new FormData();

    let subdomain = "";
    if (process.env.REACT_APP_STAGE == "dev") {
      if (localStorage.getItem("fiu_stage") == "dev") {
        subdomain = "api-demo";
      } else {
        subdomain = "api";
      }
    } else if (process.env.REACT_APP_STAGE == "me") {
      subdomain = "api-test";
    }

    formdata.append("file", s3File);
    formdata.append("file_password", filePassword);
    formdata.append("fiu_uuid", "");
    formdata.append("customer_uuid", "");
    formdata.append("data_request_uuid", "");
    formdata.append("link_uuid", linkId);
    formdata.append("request_id", requestId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`https://${subdomain}.ceplr.com/pdf-upload`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      {/* header */}
      <Modal.Header closeButton>
        <Modal.Title>Upload PDF</Modal.Title>
      </Modal.Header>

      {/* body */}
      <Modal.Body>
        {start ? (
          startProcessing ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="dotsDiv">
                  {success == "success" ? (
                    <img src={successPolling} priority={true} />
                  ) : processingError ? (
                    <img src={pdfError} priority={true} />
                  ) : (
                    <Spinner
                      animation="border"
                      style={{
                        fontSize: "40px",
                        width: "150px",
                        height: "150px",
                        color: "#3a86ff",
                      }}
                    />
                  )}
                </div>
                <div className="processingText">
                  {success == "success"
                    ? "Successful"
                    : processingError
                    ? "Error!"
                    : "Processing...."}
                </div>

                <div className="processingSmallText">{message}</div>
              </div>
            </>
          ) : (
            <>
              <div
                className={isActive ? "selectBankDivActive" : "selectBankDiv"}
              >
                <div className="selectBankText">Select Bank</div>
                <Dropdown
                  bankLoading={bankLoading}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  index={index}
                  setIndex={setIndex}
                  bankSuccess={bankSuccess}
                  setBankSuccess={setBankSuccess}
                  setIsEncrypted={setIsEncrypted}
                  setFileUploaded={setFileUploaded}
                  selected={selected}
                  setPdfBorderSelected={setPdfBorderSelected}
                  setDisable={setDisable}
                  setUploaded={setUploaded}
                  setFileSelected={setFileSelected}
                  setSelected={setSelected}
                  inputDisabled={inputDisabled}
                  setInputDisabled={setInputDisabled}
                  setPdf={setPdf}
                  bankData={bankData}
                  setToken={setToken}
                />
              </div>

              {isActive && (
                <div className="isActiveDropdown">
                  <div className="dropdownContent">
                    {bankData ? (
                      bankData.map((item, index) => (
                        <div
                          className="dropdownItem"
                          key={item.id}
                          onClick={() => {
                            setSelected(item.name);
                            setIsDropdownActive(true);
                            setIsActive(false);
                            setIndex(index);

                            getToken(index);
                          }}
                        >
                          <div className="ellipsClass">
                            <img
                              src={item.entityIconUri}
                              className="bankIconClass"
                            />
                          </div>

                          <div className="listDiv">{item.name} </div>
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{
                            fontSize: 10,
                            color: "#424242",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isActive ? (
                ""
              ) : (
                <div className="incomeDiv">
                  {/* <h1 className={styles.incometext}>
                 Income Proof
               </h1> */}

                  <div className="bankStatementDiv">
                    <img src={bankStatementImage} priority={true} />

                    <div className="listDivIncome">
                      {`Bank  Statement: Last 
             ${props.fetchValue} ${
                        props.fetchValue == "1"
                          ? props.fetchUnit != ""
                            ? props.fetchUnit.toLowerCase()
                            : ""
                          : props.fetchUnit == ""
                          ? ""
                          : props.fetchUnit?.toLocaleLowerCase() + "s"
                      }
                `}{" "}
                    </div>
                    {changeBank == false ? (
                      <div
                        className="changeText"
                        onClick={() => setChangeBank(true)}
                      >
                        {" "}
                        Change Bank
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {pdfList.map((item, pdfIndex) => {
                    // console.log(singleService.name, "ashish")
                    // console.log(Object.entries(item) === pdfIndex , "if empty")

                    return (
                      <>
                        <div
                          key={pdfIndex}
                          className={
                            item.isFileSelected == true
                              ? "uploadDivChange"
                              : "uploadDiv"
                          }
                        >
                          {/* {console.log(item.isFileSelected, "item")} */}
                          {item.isFileSelected == false ? (
                            <div
                              className={
                                pdfBorderSelected
                                  ? "labelAndInputDivActive"
                                  : "labelAndInputDiv"
                              }
                              onClick={() => uploadClick()}
                            >
                              <input
                                type="file"
                                id={pdfIndex}
                                accept=".pdf"
                                // className={
                                //   uploaded == true && item.isUploaded == true
                                //     ? styles.inputClassActive
                                //     : styles.inputClass
                                // }
                                style={{ display: "none" }}
                                //  disabled={!inputDisabled}
                                onClick={onChange1}
                                ref={uploadFileInput}
                                onChange={(e) => {
                                  onChange(e, pdfIndex);
                                }}
                                //  value= {singleService.pdf}
                                //  onChange={(e)=>handlePdfListChange(e,pdfIndex)}
                              />
                              <label className="aFile">
                                <img
                                  src={
                                    uploaded == true && item.isUploaded == true
                                      ? pdfFrame
                                      : uploadDisabled
                                  }
                                  priority={true}
                                />
                                <div
                                  className={
                                    uploaded == true && item.isUploaded == true
                                      ? "uploadTextUploaded"
                                      : "uploadText"
                                  }
                                >
                                  {item.isFileSelected == true
                                    ? pdfList[pdfIndex].file.name
                                    : "Upload Bank Statement"}
                                </div>
                                {/* {uploaded?
<div style={{marginLeft:"17%"}} >
<Image src={successTick} priority={true}/>
</div>
:""
} */}
                              </label>
                            </div>
                          ) : (
                            <div>
                              <div
                                className={
                                  uploaded == false && item.isUploaded == false
                                    ? "labelAndInputDivChange"
                                    : "labelAndInputDivChangeUploaded"
                                }
                                onClick={() => uploadClick()}
                              >
                                <input
                                  type="file"
                                  id={pdfIndex}
                                  accept=".pdf"
                                  disabled={
                                    uploaded == true && item.isUploaded == true
                                      ? true
                                      : false
                                  }
                                  // className={
                                  //   pdfBlueBorder
                                  //     ? styles.inputClassActiveChange
                                  //     : styles.inputClassChange
                                  // }
                                  style={{ display: "none" }}
                                  //  disabled={!inputDisabled}
                                  ref={uploadFileInput}
                                  onChange={(e) => onChange(e, pdfIndex)}
                                />
                                <label
                                  className={
                                    uploaded == false &&
                                    item.isUploaded == false
                                      ? "aFileChange"
                                      : "aFileChangeUploaded"
                                  }
                                >
                                  <img src={pdfFrame} priority={true} />
                                  <div className="uploadTextUploaded">
                                    {item.isFileSelected == true
                                      ? pdfList[pdfIndex].file.name
                                      : "Upload Bank Statement"}
                                  </div>
                                  {item.isUploaded == true ? (
                                    <div style={{ marginLeft: "19%" }}>
                                      <img src={successTick} priority={true} />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>

                              {item.isUploaded == true ? (
                                <button
                                  className="deleteButton"
                                  onClick={() => {
                                    handleRemove(pdfIndex);
                                  }}
                                >
                                  <img src={deleteIcon} priority={true} />
                                </button>
                              ) : (
                                <button
                                  id="buttonId"
                                  className="changeButton"
                                  onClick={() => handleClick()}
                                >
                                  Change
                                </button>
                              )}
                              <input
                                type="file"
                                id={pdfIndex}
                                accept=".pdf"
                                ref={hiddenFileInput}
                                onChange={(e) => {
                                  handleChange(e, pdfIndex);
                                }}
                                style={{ display: "none" }}
                              />
                            </div>
                          )}
                        </div>

                        {item.isUploaded == true ? (
                          // console.log("yaha nahi aaraha")
                          ""
                        ) : (
                          <div className="noteDiv">
                            {fileSelected == true &&
                            item.isFileSelected == true &&
                            item.isPasswordProtected == true ? (
                              <div className="checkboxDiv">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  onChange={() => handleChecked()}
                                  checked={checked}
                                />
                                File requires password? If It does not, then
                                click on upload.
                              </div>
                            ) : (
                              ""
                            )}

                            {checked == true &&
                            item.isFileSelected == true &&
                            item.isPasswordProtected == true ? (
                              <input
                                className="passwordinput"
                                value={password}
                                onChange={(event) => {
                                  passwordPdf(event);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        )}

                        {item.isUploaded == false ? (
                          disable == false && item.isDisabled == false ? (
                            <button
                              // id="upload-button"
                              disabled={true}
                              className="uploadButtonDisabled"
                              //  onClick={()=>uploadFile()}
                            >
                              {loading ? (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              ) : (
                                "Upload"
                              )}
                            </button>
                          ) : (
                            <button
                              // id="upload-button"
                              disabled={loading ? true : false}
                              className="uploadButton"
                              onClick={(e) => {
                                uploadFile(e, pdfIndex);
                              }}
                            >
                              {loading ? (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              ) : (
                                "Upload"
                              )}
                            </button>
                          )
                        ) : (
                          ""
                        )}
                        {/* {console.log( uploaded == true && item.isUploaded == true  , uploaded, "uploaded hai ki nahi")} */}
                        {pdfList.length - 1 === pdfIndex &&
                        item.isUploaded == true ? (
                          <button
                            className="addMore"
                            onClick={() => {
                              addButton();
                            }}
                          >
                            <img src={plusIcon} priority={true} />
                            Add More
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </>
          )
        ) : (
          <Form autoComplete="off">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="form-group required">
                <Form.Label className="control-label">Mobile Number</Form.Label>
              </div>
              <Form.Control
                disabled={loader}
                maxLength={10}
                type="text"
                onChange={(e) => {
                  setMobileInvalid("");
                  setMobile(e.target.value);
                }}
                placeholder="Enter mobile number"
                isInvalid={mobileInvalid.length > 0 ? true : false}
              />
              <Form.Control.Feedback
                type={mobileInvalid.length > 0 ? "invalid" : "valid"}
              >
                {mobileInvalid}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                disabled={loader}
                type="text"
                onChange={(e) => {
                  // setNameInvalid("");
                  setName(e.target.value);
                }}
                placeholder="Enter name"
                // isInvalid={nameInvalid.length > 0 ? true : false}
              />
              <Form.Control.Feedback
                type={nameInvalid.length > 0 ? "invalid" : "valid"}
              >
                {nameInvalid}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                disabled={loader}
                type="email"
                onChange={(e) => {
                  // setEmailInvalid("");
                  setEmail(e.target.value);
                }}
                placeholder="Enter email id"
                // isInvalid={emailInvalid.length > 0 ? true : false}
              />
              <Form.Control.Feedback
                type={emailInvalid.length > 0 ? "invalid" : "valid"}
              >
                {emailInvalid}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>

      {/* footer */}
      {isActive || startProcessing ? (
        ""
      ) : (
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {start ? (
            <>
              <button
                className={
                  pdfList[0].isUploaded == true
                    ? "proceedButton"
                    : "proceedButtonDisabled"
                }
                disabled={pdfList[0].isUploaded == true ? false : true}
                onClick={() => {
                  goToNext();
                }}
              >
                {processed ? (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                ) : (
                  "Proceed"
                )}
              </button>
            </>
          ) : (
            <Button
              style={{ cursor: "pointer", width: "120px", height: "40px" }}
              variant="primary"
              onClick={() => checkData()}
            >
              {loader ? <Spinner animation="border" size="sm" /> : "Next"}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default PDFModal;
