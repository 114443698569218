import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import "./analysedData.css";
import MonthlyAnalysis from "./monthlyAnalysis";
import backIcon from "../../images/back_icon.png";
import { toCapitalizedWords } from "../../utils/helper";

function AnalysedData(props) {
  const [showMonthly, setShowMonthly] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);
  const [value, setValue] = useState("");

  return (
    <Modal
      className="mdl"
      size="xl"
      dialogClassName="mdl-dlg"
      show={props.show}
      onHide={() => {
        if (!showMonthly) {
          props.onHide();
        }
      }}
    >
      {/* header */}
      <Modal.Header closeButton={showMonthly ? false : true}>
        <Modal.Title>
          <div className="model_header_div">
            {/* back icon */}
            {showMonthly ? (
              <img
                src={backIcon}
                style={{ cursor: "pointer" }}
                onClick={() => setShowMonthly(false)}
              />
            ) : null}

            {/* title */}
            <p className="model_header_title">
              {showMonthly
                ? `Monthly Data For ${toCapitalizedWords(value)}`
                : "Analysed Data"}
            </p>
          </div>
        </Modal.Title>
      </Modal.Header>

      {/* body */}
      <Modal.Body className="mdl_body">
        {showMonthly ? (
          <MonthlyAnalysis
            monthly_data={monthlyData}
            monthYearArray={props.monthYearArray}
          />
        ) : props.data.length ? (
          <Accordion defaultActiveKey="0">
            {props.data.map((items, index) => {
              return (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    <div className="main_header">
                      <p className="main_title">
                        Account Number {items.masked_account_number}
                      </p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* heading 1 */}
                    <div className="heading_container">
                      <p className="bullet">{"\u2B24"}</p>
                      <p className="heading_text">Basic Data</p>
                    </div>

                    {/* data of root level */}
                    <div className="row_container">
                      {Object.keys(items).map((keyName, i) => {
                        if (keyName != "analytics" && items[keyName]) {
                          return (
                            <div key={i}>
                              <p className="value_title">{keyName}</p>
                              <p className="value_subtitle">{items[keyName]}</p>
                            </div>
                          );
                        }
                      })}
                    </div>

                    {/* heading 2 */}
                    <div
                      className="heading_container"
                      style={{ marginTop: "12px" }}
                    >
                      <p className="bullet">{"\u2B24"}</p>
                      <p className="heading_text">Analysis Data</p>
                    </div>

                    {/* if there is not object in the analysis data */}
                    <div className="row_container">
                      {Object.keys(items.analytics).map((keyName1, i1) => {
                        if (typeof items.analytics[keyName1] != "object") {
                          return (
                            <div key={i1}>
                              <p className="value_title">{keyName1}</p>
                              <p className="value_subtitle">
                                {items.analytics[keyName1]}
                              </p>
                            </div>
                          );
                        }
                      })}
                    </div>

                    {/* if there is  object in the analysis data */}
                    <div className="column_container">
                      {Object.keys(items.analytics).map((keyName2, i2) => {
                        if (typeof items.analytics[keyName2] == "object") {
                          return (
                            <div className="column_container_div" key={i2}>
                              <div className="column_container_div_row">
                                <p className="column_container_heading">
                                  {toCapitalizedWords(keyName2)}
                                </p>
                                {items.analytics[keyName2][
                                  "monthly_analysis"
                                ] ? (
                                  <p
                                    className="column_container_button"
                                    onClick={() => {
                                      setValue(keyName2);
                                      setMonthlyData(
                                        items.analytics[keyName2][
                                          "monthly_analysis"
                                        ]
                                      );
                                      setShowMonthly(true);
                                    }}
                                  >
                                    View monthly analysis
                                  </p>
                                ) : null}
                              </div>
                              <div
                                className="row_container"
                                style={{ padding: "0px" }}
                              >
                                {Object.keys(items.analytics[keyName2]).map(
                                  (keyName3, i3) => {
                                    // if key name is not monthly analysis and salary_dates
                                    if (
                                      keyName3 != "monthly_analysis" &&
                                      keyName3 != "salary_dates"
                                    ) {
                                      return (
                                        <div key={i3}>
                                          <p className="value_title">
                                            {keyName3}
                                          </p>
                                          <p className="value_subtitle">
                                            {
                                              items.analytics[keyName2][
                                                keyName3
                                              ]
                                            }
                                          </p>
                                        </div>
                                      );
                                    }

                                    //  if key name is salary dates
                                    if (keyName3 == "salary_dates") {
                                      return (
                                        <div key={i3}>
                                          <p className="value_title">
                                            {keyName3}
                                          </p>
                                          <div className="salary_date_div">
                                            {items.analytics[keyName2][
                                              keyName3
                                            ].map((e, i) => {
                                              return (
                                                <div className="salary_date_div_row">
                                                  <p className="bullet">
                                                    {"\u2B24"}
                                                  </p>
                                                  <p
                                                    className="value_subtitle"
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                  >
                                                    {e}
                                                  </p>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <div className="no_data_found">
            <div>
              Thank you for your patience. We are currently retrieving or
              analyzing the customer's data for you.
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AnalysedData;
