import React, { useEffect, useState } from "react";
import { Auth, API } from "aws-amplify";
import awsconfig from "../../Constant/aws-exports";

import preloader from "../../images/preloader.gif";
import DropDown from "./DataRequest/dropDown";
import DataRequest from "../customers/DataRequest/index";
import {
  Routes,
  Route,
  Link,
  generatePath,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useForm } from "react-hook-form";

import { usePagination, DOTS } from "./usePagination";

import "./customers.css";
import { pageNumber } from "yup/lib/locale";
import AnalysedData from "../../Components/AnalysedData/analysedData";
import DateInput from "../../Components/DateInput/dateInput";
import { getMonthYearBtwDates } from "../../utils/helper";

// API.configure(awsconfig[process.env.REACT_APP_STAGE]);

function Pagination({
  siblingCount = 1,
  apiCall,
  postsPerPage,
  totalPosts,
  paginate,
  setCurrentPosts,
  currentPost,
}) {
  const pageNumbers = [];

  const paginationRange = usePagination({
    totalPosts,
    postsPerPage,
    siblingCount,
    currentPost,
  });

  //   console.log("pag",paginationRange)
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  function goToPrevPage(current) {
    setCurrentPosts(current);
    apiCall(current);
  }
  function currentPage(pageNumber) {
    paginate(pageNumber);
    apiCall(pageNumber);
  }
  function goToNextPage(current) {
    setCurrentPosts(current);
    apiCall(current);
  }

  var pages = Math.ceil(totalPosts / postsPerPage);

  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <nav className="paginate">
      <button
        className={`prev ${currentPost === 1 ? "disabled" : "prevPost"}`}
        onClick={() => goToPrevPage(currentPost - 1)}
      >
        &lt;
      </button>
      <ul className="pagination">
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item">&#8230;</li>;
          }
          return (
            <li
              className={`${
                currentPost === pageNumber ? "activate" : "pageLink"
              }`}
              onClick={() => currentPage(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      </ul>
      <button
        className={`${currentPost === lastPage ? "disabled" : "nextPost"}`}
        onClick={() => goToNextPage(currentPost + 1)}
      >
        &gt;
      </button>
    </nav>
  );
}

function Customers({ setToggleLoading, toggleLoading }) {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState(0);
  const [posts, setPosts] = useState([]);

  // const [customers, setCustomers] = useState(0);
  const [uuid, setUuid] = useState("");
  // const[posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchedCust, setSearchCust] = useState("");
  const [showDateInput, setShowDateInput] = useState(false);
  const [showAnalysedModel, setShowAnalysedModel] = useState(false);
  const [cusId, setCusId] = useState("");
  const [analyticData, setAnalyticData] = useState([]);
  const [monthYearArray, setMonthYearArray] = useState([]);

  let navigate = useNavigate();

  const dataRequest = (customer) => {
    const uuid = customer.uuid;
    navigate(`/customers/${uuid}/data-requests`);
  };

  // console.log(toggleLoading, "true ")
  const fetchCustomer = async () => {
    setLoading(true);
    setToggleLoading(true);
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };
    // Api call to fetch the customers of FIU with pagination
    //     const credential = await Auth.currentCredentials();
    // console.log(credential);
    // // console.log(user);
    // console.log("accessKeyId", credential['accessKeyId'])
    // console.log("secretAccessKey", credential['secretAccessKey'])
    // console.log("sessionToken", credential['sessionToken'])

    try {
      // console.log(API,"APIS")

      const response = await API.get("fiuCustomers", `?page=1`, requestInfo);
      setToggleLoading(false);
      // console.log("res-->")

      const customer = response.data;
      setCustomers(customer);
      setPosts(response.paginatedData);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log("error to get customer", error.response);
    }
  };

  // console.log("customers--",customers)
  // console.log("posts--",posts)

  const currentUser = async () => {
    let result = await Auth.currentSession((data) => console.log(data));

    console.log(result);
  };

  useEffect(() => {
    // updateName()

    currentUser();
    // console.log(currentUser(), " user")
    fetchCustomer();
    fetchTemplates();
  }, []);
  // console.log(searchedCust.length)

  const updateName = async () => {
    const result = await API.get("updateFiu", "/name");

    // localStorage.setItem('result',result)
    if (result.data.stage_enabled == 1) {
      localStorage.setItem("fiu_stage", "prod");
      // refreshPage();
    } else if (result.data.stage_enabled == 0) {
      localStorage.setItem("fiu_stage", "dev");
      // refreshPage();
    }
    // localStorage.setItem("result", JSON.stringify(result));
    // setFiu(result.data);
  };

  const fetchTemplates = async () => {
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };

    //
    try {
      const response = await API.get(
        "consentTemplates",
        "/templates",
        requestInfo
      );
      const templates = response.data;
      // console.log(templates)

      localStorage.setItem("tempId", templates[0].uuid);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const fetchPaginatedCustomer = async (currentPage) => {
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const response = await API.get(
      "fiuCustomers",
      `?page=${currentPage}`,
      requestInfo
    );

    // console.log("response.paginatedData", response.paginatedData)
    setPosts(response.paginatedData);
  };

  const handleClick = async (data) => {
    // console.log("data==",data)
    await fetchPaginatedCustomer(data);
  };

  const formatedDate = (date) => {
    const presentDate = new Date(date);
    var getMonthName = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = presentDate.getMonth();
    var day = presentDate.getDate();

    // get time
    function getTime(presentDate) {
      // if hour is greater than 12
      if (presentDate.getHours() > 12) {
        let time = `${String(presentDate.getHours() - 12).padStart(2, "0")}:${
          presentDate.getMinutes() < 10 ? "0" : ""
        }${presentDate.getMinutes()} PM`;
        return time;
      }
      // if hour is less than 12
      else {
        return `${String(presentDate.getHours()).padStart(2, "0")}:${String(
          presentDate.getMinutes()
        ).padStart(2, "0")} AM`;
      }
    }

    // return formatted date and time
    return `${String(day).padStart(2, "0")} ${getMonthName[month]} ${getTime(
      presentDate
    )}`;
  };

  const isOdd = (index) => index % 2 === 1;

  const renderCustomer = (customer, index) => {
    let format = formatedDate(customer.created_at);
    // console.log(format,"format")
    return (
      <tr key={index} className={isOdd(index) ? "bg-light" : ""}>
        <td>{customer.name}</td>
        <td>{customer.uuid}</td>
        <td>{customer.email}</td>
        <td>{customer.contact}</td>
        <td>{format}</td>
        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button
              className="view-a"
              style={{ marginLeft: 20 }}
              onClick={() => {
                const uuid = posts[index].uuid;
                setUuid(uuid);
                dataRequest(customer);
              }}
            >
              Data Requests
            </button>

            {/* <button
              style={{ display: "none" }}
              className="view-a"
              onClick={() => {
                setCusId(customer.uuid);
                setShowDateInput(true);
              }}
            >
              View Analysed Data
            </button> */}
          </div>
        </td>
      </tr>
    );
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // console.log("pag==",paginate)
  // Get current posts
  // const indexOfLastPost = currentPage * postsPerPage;

  // const indexOfFirstPost=indexOfLastPost-postsPerPage;

  const currentPosts = posts;

  const fetchSearchedUser = async (value) => {
    if (!value) {
      const requestInfo = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await API.get(
        "fiuCustomers",
        `?page=1&search=${searchedCust}`,
        requestInfo
      );

      // console.log("response.paginatedData", response.paginatedData)
      const customer = response.data;
      setCustomers(customer);
      setPosts(response.paginatedData);
    } else {
      const requestInfo = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await API.get(
        "fiuCustomers",
        `?page=1&search=${value}`,
        requestInfo
      );

      // console.log("response.paginatedData", response.paginatedData)
      const customer = response.data;
      setCustomers(customer);
      setPosts(response.paginatedData);
    }
  };
  function search(e) {
    // console.log(e.code)
    fetchCustomer(e.target.value);
  }
  var rows1 = customers;

  function displayrows(rows) {
    if (rows == 0) {
      return <tbody>No Customer found</tbody>;
    } else if (rows == 1) {
      return <tbody>{renderCustomer(posts[0], 0)}</tbody>;
    } else {
      // console.log("inside")
      return (
        <tbody>
          {currentPosts.map((element, index) => {
            return renderCustomer(element, index);
          })}
        </tbody>
      );
    }
  }

  // console.log(!isNaN(searchedCust),".//")

  return (
    <section id="customer_page">
      <div className="container-fluid cp">
        <div className="row">
          {/* <DataRequest /> */}
          <div className="col-3">
            <div className="customerDiv">
              <div className="customer-header">Customers</div>
              <div className="customer_content">
                Your customers onboarded to Ceplr.
              </div>
            </div>
            <div className="searchDiv">
              <div className="searchBarDiv">
                <input
                  type="text"
                  className="searchBar"
                  placeholder="Name or Mobile number"
                  onChange={(e) => {
                    !e.target.value.length == 0
                      ? setSearchCust(e.target.value)
                      : fetchCustomer();
                  }}
                  onKeyPress={(e) =>
                    e.code == "Enter" ? fetchSearchedUser(e.target.value) : ""
                  }
                />
              </div>
              <div className="searchButtonDiv">
                <button
                  className="searchButton"
                  onClick={() => {
                    fetchSearchedUser();
                  }}
                >
                  Search
                </button>
              </div>
              {/* <div className='sortDiv'>
                        <div className='sortHeader'>
                            Sort by:
                        </div>
                        <div className='sortDropDown'>
                            <DropDown/>
                        </div>
                    </div> */}
            </div>
          </div>
          <div className="col-8">
            <div className="custTable">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Customer ID</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile No.</th>
                    <th>Created At</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {/* {toggleLoading==true?fetchCustomer:""} */}
                {loading || toggleLoading ? (
                  <tbody>
                    <img className="preloader" src={preloader} alt="" />
                  </tbody>
                ) : (
                  displayrows(rows1)
                )}
              </table>
            </div>
          </div>
          {/* apiCall={fetchCustomer()} */}

          <div className="col-1">
            <Pagination
              apiCall={handleClick}
              postsPerPage={postsPerPage}
              totalPosts={rows1}
              paginate={paginate}
              setCurrentPosts={setCurrentPage}
              currentPost={currentPage}
            />
          </div>
        </div>
      </div>

      {showAnalysedModel ? (
        <AnalysedData
          show={showAnalysedModel}
          onHide={() => setShowAnalysedModel(false)}
          data={analyticData}
          monthYearArray={monthYearArray}
        />
      ) : null}

      {showDateInput ? (
        <DateInput
          show={showDateInput}
          onHide={() => setShowDateInput(false)}
          uuid={cusId}
          onSuccess={(data, start, end) => {
            const monthYear = getMonthYearBtwDates(start, end);
            setMonthYearArray(monthYear);
            setAnalyticData(data);
            setShowDateInput(false);
            setShowAnalysedModel(true);
          }}
          onError={() => {
            setShowDateInput(false);
          }}
        />
      ) : null}
    </section>
  );
}

export default Customers;
