import Accordion from "react-bootstrap/Accordion";

function MonthlyAnalysis(props) {
  return (
    <Accordion defaultActiveKey="0">
      {props.monthly_data.map((items, index) => {
        return (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>{props.monthYearArray[index]}</Accordion.Header>
            <Accordion.Body>
              <div className="row_container">
                {Object.keys(items).map((keyName, i) => {
                  if (typeof items[keyName] != "object") {
                    return (
                      <div key={i}>
                        <p className="value_title">{keyName}</p>
                        <p className="value_subtitle">{items[keyName]}</p>
                      </div>
                    );
                  }
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
}

export default MonthlyAnalysis;
