import "./Login.css";
import { Auth, API } from "aws-amplify";

// @ts-ignore
import illustration from "../../images/Frame.svg";
// @ts-ignore
import warning from "../../images/warning.svg";
import blindEye from "../../images/blindEye.svg";
import eyeIcon from "../../images/eyeIcon.svg";
// @ts-ignore
import logo from "../../images/new_logo.png";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { getConstantValue } from "typescript";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestAccount from "./requestAcount";
import openEye from "../../images/openEye.png";
import closeEye from "../../images/closeEye.png";

export default function Login({ onSignIn }) {
  let navigate = useNavigate();
  let location = useLocation();
  // console.log(location, "state")

  localStorage.setItem(
    "from",
    location.state != null ? location.state.from.pathname : ""
  );
  const initialValues = { username: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);

  const [formError, setFormError] = useState({});
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [resetResponse, setResetResponse] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [confirmResponse, setConfirmResponse] = useState("");
  const [confirmErrorResponse, setConfirmErrorResponse] = useState("");

  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  // const [newPassword,setNewPassword] = useState("")
  const [patternError, setPatternError] = useState(false);
  const [patternResponse, setPatternResponse] = useState("");

  const [resendLoading, setResendLoading] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [counter, setCounter] = useState(30);
  const [intervalId, setIntervalId] = useState(null);
  // const [increment,setIncrement] = useState("0")
  const [decrement, setDecrement] = useState("5");
  const [attemptsLeft, setAttemptsLeft] = useState(false);
  const [requestAccount, setRequestAccount] = useState(false);
  const [eye, setEye] = useState("password");
  const [eyePassword, setEyePassword] = useState("password");
  const [eyeConfirm, setEyeConfirm] = useState("password");

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
  } = useForm({
    mode: "onTouched",
  });

  const [loading, setLoading] = useState(false);
  function handleChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log(formValues);
  }

  function emailHandleChange(e) {
    setVerifyEmail(e.target.value);
  }

  const forgotPasswordFunction = () => {
    setForgotPassword(true);
    setUserVerified(false);
    setVerifyEmail("");
    setConfirmError(false);
    setResetResponse("");
    setResetError(false);
    setResetSuccess(false);
    setConfirmSuccess(false);
    setConfirmResponse("");
    setOtpError(false);
    setOtpSuccess(false);
    setCounter(30);
  };

  // console.log(process.env, "process enviourment")

  const blindEyeLogin = () => {
    setEye(eye === "password" ? "text" : "password");
    // inputRef.current.focus()
  };

  const newPassword = watch("newPassword");
  const verificationCode = watch("verificationCode");

  // console.log(formValues.newPassword,"new password", confirmedPassword, "confirmed Password")

  function formSubmit(e) {
    // e.preventDefault();
    setFormError(validate(formValues));
    setSubmit(true);
  }

  const onSubmit = (data) => {
    // console.log(data)
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required!";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }
    // } else if (values.password.length < 4) {
    //     errors.password = "Password must be more than 4 characters";
    // } else if (values.password.length > 10) {
    //     errors.password = "Password cannot exceed more than 10 characters";
    // }

    return errors;
  };

  // console.log(formValues.username, "userName")
  // console.log(verifyEmail, "email")

  // console.log(errors, "formstate")

  const signIn = async () => {
    try {
      setLoading(true);
      await Auth.signIn(formValues.username, formValues.password);
      onSignIn();
    } catch (err) {
      // console.log("Error while logging in", err);
      setLoading(false);
      setError(true);
    }
  };

  // console.log(verifyEmail,formValues.newPassword, "details for temp signin")

  const tempSignIn = async () => {
    // console.log("in temp sign")
    try {
      // setLoading(true);
      await Auth.signIn(verifyEmail, formValues.newPassword);
      // onSignIn();
    } catch (err) {
      // console.log("Error while logging in", err);
      // setLoading(false);
      // setError(true);
    }
  };

  // console.log(decrement, "decrement")
  // console.log(attemptsLeft, "attempts true")
  // console.log(counter , "counter")

  const resendAgain = async () => {
    reset({ verificationCode: "", newPassword: "", confirmPassword: "" });
    setConfirmErrorResponse("");
    setCounter(30);

    if (decrement == 0) {
      setDecrement(decrement);
    } else {
      setDecrement(decrement - 1);
    }

    setResendLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        // "Content-Type":"application/json",
        // "x-api-key": personalDetails.id
      },

      body: {
        email: verifyEmail,
      },
    };

    try {
      const res = await API.post(
        "resetPassword",
        "/resetPassword",
        requestOptions
      );

      if (decrement <= 4) {
        setAttemptsLeft(true);
      } else {
        setAttemptsLeft(false);
      }

      if (res.code == 200) {
        // setResetSuccess(true)
        // setResetError(false)

        // setTimeout(() => {
        //   setUserVerified(true)
        //   setLoading(false)
        // }, 1000);
        setResendLoading(false);
        Timer();
        setOtpSuccess(true);
        if (decrement < 4 && decrement > 0) {
          toast.warning(
            `You are left with ${decrement} attempts to reset your password`,
            {
              position: toast.POSITION.TOP_RIGHT,
              toastId: "warningToast",
            }
          );
        }
        // timer()

        // setResetResponse(res.message)
      }
      // if(res.message == "LimitExceededException"){
      //  setResetError(true)
      //  setResetResponse("Limit has exceeded,Please try again after 30 minutes.")
      //  setLoading(false)
      // }else if(res.message == "UserNotFoundException"){
      //   setResetError(true)
      //   setResetResponse("The email address does not exist, Please sign up.")
      //   setLoading(false)
      // }
      else {
        // setResetError(true)
        // setResetResponse(res.message)
        // setLoading(false)
        setOtpError(true);
        setResendLoading(false);
        // Timer()
        // timer()
      }
      // console.log(res)
    } catch (err) {
      setLoading(false);
      setError(true);
      // console.log(err, "errrors")
      // setResetResponse("")
    }
    // setAttemptsLeft(false)
  };
  const blindEyePasswordLogin = () => {
    setEyePassword(eyePassword === "password" ? "text" : "password");
    // inputRef.current.focus()
  };

  const blindEyeConfirmLogin = () => {
    setEyeConfirm(eyeConfirm === "password" ? "text" : "password");
    // inputRef.current.focus()
  };
  // console.log(resetError, "reset error")

  const verifyUserName = async () => {
    // setIncrement(increment +1)
    if (decrement == 0) {
      setDecrement(decrement);
    } else {
      setDecrement(decrement - 1);
    }

    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        // "Content-Type":"application/json",
        // "x-api-key": personalDetails.id
      },

      body: {
        email: verifyEmail,
      },
    };

    try {
      const res = await API.post(
        "resetPassword",
        "/resetPassword",
        requestOptions
      );

      if (res.code == 200) {
        setResetSuccess(true);
        setResetError(false);
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          // toastId:"warningToast"
        });

        // if(decrement<4 && decrement>0 ){
        //   toast.warning(`You are left with ${decrement} attempts to reset your password`, {
        //     position: toast.POSITION.TOP_CENTER,
        //     // toastId:"warningToast"
        // });

        // }

        setTimeout(() => {
          setUserVerified(true);
          setLoading(false);
        }, 1000);

        setResetResponse(
          "Reset password email sent, Please check your email for the verification code"
        );
      }
      if (res.message == "LimitExceededException") {
        setResetError(true);
        setResetResponse(
          "Limit has exceeded,Please try again after 30 minutes."
        );
        setLoading(false);
      } else if (res.message == "UserNotFoundException") {
        setResetError(true);
        setResetResponse("The email address does not exist, Please sign up.");
        setLoading(false);
      } else {
        setResetError(true);
        setResetResponse(res.message);
        setLoading(false);
      }
      // console.log(res)
      // }
      // )
    } catch (err) {
      setLoading(false);
      setError(true);
      // console.log(err, "errrors")
      setResetResponse("");
    }
    // setAttemptsLeft(false)
  };

  let interval = useRef(null);
  const inputRef = useRef(null);

  function Timer() {
    if (counter > 0) {
      interval.current = setInterval(
        () => setCounter((counter) => (counter === 0 ? counter : counter - 1)),
        1000
      );
    }
    if (counter == 0) {
      // setCounter(30)
    }
    return () => clearInterval(interval.current);
  }

  // console.log(counter, "counter")

  const goBack = () => {
    setForgotPassword(false);
    reset();
    setAttemptsLeft(false);
    setDecrement("5");
    setResetError(false);
  };
  const globalSignOut = async () => {
    // console.log("global signout")
    try {
      await Auth.signOut({ global: true });
    } catch (err) {
      // console.log("Error while logging in", err);
    }
  };

  const confirmPassword = async (data) => {
    // const values = getValues()
    // let formData= data

    // console.log(newPassword, verificationCode, "values")

    setLoading(true);

    if (decrement == 0) {
      setDecrement(decrement);
    } else {
      setDecrement(decrement - 1);
    }

    const requestOptions = {
      method: "POST",
      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        // "Content-Type":"application/json",
        // "x-api-key": personalDetails.id
      },

      body: {
        email: verifyEmail,
        ConfirmationCode: verificationCode,
        Password: newPassword,
      },
    };
    // console.log(requestOptions.body, "bodyyy")

    try {
      const res = await API.post(
        "confirmPassword",
        "/confirmPassword",
        requestOptions
      );
      // setLoading(false)
      if (decrement <= 4) {
        setAttemptsLeft(true);
      } else {
        setAttemptsLeft(false);
      }
      if (res.code == 200) {
        setConfirmSuccess(true);
        setConfirmResponse(res.message);
        tempSignIn();

        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          // toastId:"warningToast"
        });

        setTimeout(() => {
          setForgotPassword(false);
          globalSignOut();
          setLoading(false);
          reset();
        }, 1000);
      } else if (res.code == 400) {
        setConfirmError(true);
        setLoading(false);
        if (res.message == "CodeMismatchException") {
          // setConfirmError(true)
          return setConfirmErrorResponse("The Verification code is incorrect");
          // setFormValues({ verificationCode:""})
        } else if (res.message == "MissingRequiredParameters") {
          // setConfirmError(true)
          return setConfirmErrorResponse("All input fields should be filled");
          // setFormValues({ verificationCode:""})
        } else if (res.message == "InvalidParameterException") {
          // setConfirmError(true)
          return setConfirmErrorResponse("All input fields should be filled");
          // setFormValues({ verificationCode:""})
        } else if (res.message == "LimitExceededException") {
          // setConfirmError(true)
          return setConfirmErrorResponse(
            "Limit has exceeded, please try again after 30 minutes."
          );
          // setFormValues({ verificationCode:""})
        } else if (res.message == "MultipleValidationErrors") {
          // setConfirmError(true)
          return setConfirmErrorResponse(
            "Please fill all the required parameters."
          );
          // setFormValues({ verificationCode:""})
        }
      }

      // console.log(res)
    } catch (err) {
      setLoading(false);
      setError(true);
      // console.log(err, "errrors")
    }
    // setAttemptsLeft(false)
  };

  // console.log(confirmResponse, "response of confirmation")

  return (
    <section id="Login">
      <ToastContainer />
      <div className="container-fluid">
        <div className="row align-items center justify-content center">
          <div className="col col-sm-12 col-xl-6">
            <div className="rectangle">
              <img src={illustration} alt="" className="illustartion" />
            </div>
          </div>

          <div className="col col-sm-12 col-xl-6">
            {requestAccount ? (
              <RequestAccount
                setRequestAccount={setRequestAccount}
                requestAccount={requestAccount}
                onGoBack={() => setRequestAccount(false)}
              />
            ) : forgotPassword ? (
              <>
                {userVerified ? (
                  <>
                    <form
                      id="contact-form"
                      className="main"
                      onSubmit={handleSubmit(confirmPassword)}
                    >
                      <img src={logo} alt="" className="logo" />

                      <input
                        name="hiddenInput"
                        value="hiddenValue"
                        type="hidden"
                      />

                      <div className="input3" style={{ marginTop: "4%" }}>
                        <div className="confirmPassword">New Password</div>
                        <div
                          className={`user_password ${
                            formError.password && "invalid"
                          }`}
                        >
                          <input
                            type={eyePassword}
                            className="loginPassword"
                            name="newPassword"
                            // value={newPassword}
                            // onChange={newPasswordChange}
                            // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$"
                            {...register("newPassword", {
                              required: "password is required",
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
                                message:
                                  "one uppercase letter, one number, and one special character must be included.",
                              },
                              minLength: {
                                value: 6,
                                message: "Minimum required length is 6",
                              },
                            })}
                          />
                          <span
                            className="password__show"
                            onClick={blindEyePasswordLogin}
                          >
                            {eyePassword === "text" ? (
                              <img
                                src={openEye}
                                style={{
                                  height: "23px",
                                  width: "23px",
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <img
                                src={closeEye}
                                style={{
                                  height: "23px",
                                  width: "23px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </span>
                        </div>
                        {errors.newPassword ? (
                          <p className="validation-error">
                            {errors.newPassword.message}
                          </p>
                        ) : (
                          <p className="validation-error"></p>
                        )}
                      </div>

                      <div className="input4">
                        <div className="confirmPassword">Confirm Password</div>
                        <div
                          className={`user_password ${
                            formError.password && "invalid"
                          }`}
                        >
                          <input
                            type={eyeConfirm}
                            className="loginPassword"
                            name="confirmedPassword"
                            // value={confirmedPassword}
                            // onChange={confirmHandleChange}
                            // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$"
                            defaultValue={""}
                            {...register("confirmPassword", {
                              required: "Confirm password is required",
                              validate: (value) =>
                                value === newPassword ||
                                "Password does not match",
                            })}
                          />
                          <span
                            className="password__show"
                            onClick={blindEyeConfirmLogin}
                          >
                            {eyeConfirm === "text" ? (
                              <img
                                src={openEye}
                                style={{
                                  height: "23px",
                                  width: "23px",
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <img
                                src={closeEye}
                                style={{
                                  height: "23px",
                                  width: "23px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </span>
                        </div>
                        {errors.confirmPassword ? (
                          <p className="validation-error">
                            {errors.confirmPassword.message}
                          </p>
                        ) : (
                          <p className="validation-error"></p>
                        )}
                        {/* {confirmSuccess?<p className="validation-success"> </p>:confirmError?<p className="validation-error"> {confirmErrorResponse}</p>:errors.confirmPassword ?<p className="validation-error">{errors.confirmPassword.message}</p>:<p className="validation-error"></p>} */}
                        {/* <p className="validation-error">{formValues.newPassword == formValues.confirmPassword?"":"Password did not match"}</p> */}
                      </div>
                      <div className="input5">
                        <div className="confirmUsername">Verification Code</div>{" "}
                        <input
                          className={`user_input ${
                            errors.verificationCode
                              ? "user_Invalidinput"
                              : "user_input"
                          }`}
                          type="text"
                          name="verificationCode"
                          // value={formValues.verificationCode}
                          // onChange={handleChange}
                          maxLength="6"
                          {...register("verificationCode", {
                            required: "Verification code is required",
                          })}
                        />
                        {confirmSuccess ? (
                          <p className="validation-success"> </p>
                        ) : confirmError ? (
                          <p className="validation-error">
                            {" "}
                            {confirmErrorResponse}
                          </p>
                        ) : errors.verificationCode ? (
                          <p className="validation-error">
                            {errors.verificationCode.message}
                          </p>
                        ) : (
                          <p className="validation-error"></p>
                        )}
                      </div>
                      <div className="sendAgain">
                        {otpSuccess && counter > 0 ? (
                          <p className="">
                            Code sent, please try again in{" "}
                            <span
                              style={{
                                color: "#3a86ff",
                                cursor: "pointer",
                                marginLeft: "3px",
                              }}
                            >
                              {counter}
                            </span>{" "}
                            seconds
                          </p>
                        ) : otpError ? (
                          <p className="resend-error">
                            Limit has exceeded Please try again after 30 minutes
                            or{" "}
                            <span
                              style={{
                                color: "#3a86ff",
                                cursor: "pointer",
                                marginLeft: "3px",
                                textDecoration: "underline",
                              }}
                              onClick={() => goBack()}
                            >
                              {" "}
                              Go back{" "}
                            </span>
                          </p>
                        ) : (
                          <div>
                            Didn't receive the code?{" "}
                            <span
                              style={{
                                color: "#3a86ff",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={resendAgain}
                            >
                              {resendLoading ? (
                                <span
                                  style={{ marginRight: "5px" }}
                                  className="spinner-border spinner-border-sm mr-1"
                                ></span>
                              ) : (
                                `Resend Code`
                              )}{" "}
                            </span>{" "}
                          </div>
                        )}
                      </div>

                      <button className="log_in_buttonConfirm">
                        {loading ? (
                          <span
                            style={{ marginRight: "5px" }}
                            className="spinner-border spinner-border-sm mr-1"
                          ></span>
                        ) : (
                          `Confirm Password`
                        )}
                      </button>
                    </form>
                  </>
                ) : (
                  <>
                    <form
                      id="contact-form"
                      className="main"
                      onSubmit={handleSubmit(verifyUserName)}
                    >
                      <img src={logo} alt="" className="logo" />
                      <div className="input1">
                        <div className="username">Email address</div>

                        <input
                          className={`user_input ${
                            formError.verifyEmail && "invalid"
                          }`}
                          type="text"
                          name="email"
                          value={verifyEmail || ""}
                          onChange={emailHandleChange}
                        />
                        {resetSuccess ? (
                          <p className="validation-success"> </p>
                        ) : resetError ? (
                          <p className="validation-error"> {resetResponse}</p>
                        ) : (
                          <p className="validation-error">
                            {formError.verifyEmail}
                          </p>
                        )}

                        {/* <p className="validation-error">{}</p> */}
                      </div>

                      <button
                        className="log_in_button"
                        disabled={verifyEmail.length == 0 ? true : false}
                      >
                        {loading ? (
                          <span
                            style={{ marginRight: "5px" }}
                            className="spinner-border spinner-border-sm mr-1"
                          ></span>
                        ) : (
                          `Verify Email`
                        )}
                      </button>
                      <div className="goBack" onClick={() => goBack()}>
                        {" "}
                        Go back
                      </div>
                    </form>
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <form
                  id="contact-form"
                  className="main1"
                  onSubmit={handleSubmit(formSubmit)}
                >
                  <img src={logo} alt="" className="logo" />

                  <>
                    <div className="input1">
                      <div className="username">Email address</div>
                      <input
                        className={`user_input ${
                          formError.username
                            ? "user_Invalidinput"
                            : "user_input"
                        }`}
                        type="text"
                        name="username"
                        value={formValues.username}
                        onChange={handleChange}
                      />
                      <p className="validation-error">{formError.username}</p>
                    </div>

                    <div className="input2">
                      <div className="password">Password</div>
                      <div
                        className={`user_password ${
                          formError.password && "invalid"
                        }`}
                      >
                        <input
                          type={eye}
                          className="loginPassword"
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                          ref={inputRef}
                        />
                        <span
                          className="password__show"
                          onClick={blindEyeLogin}
                        >
                          {eye === "text" ? (
                            <img
                              src={openEye}
                              style={{
                                height: "23px",
                                width: "23px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <img
                              src={closeEye}
                              style={{
                                height: "23px",
                                width: "23px",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </span>
                      </div>
                      <p className="validation-error">{formError.password}</p>
                    </div>

                    <div
                      className="forgot_password"
                      onClick={() => forgotPasswordFunction()}
                    >
                      Forgot Password?
                    </div>
                    <button className="log_in_button" onClick={signIn}>
                      {loading ? (
                        <span
                          style={{ marginRight: "5px" }}
                          className="spinner-border spinner-border-sm mr-1"
                        ></span>
                      ) : (
                        `Login`
                      )}
                    </button>

                    {requestAccount ? (
                      ""
                    ) : (
                      <div
                        className="accountRequest"
                        onClick={() => setRequestAccount(true)}
                      >
                        Request For Account Creation ?
                      </div>
                    )}

                    {formError.username || formError.password ? null : error ? (
                      <div className="login_err">
                        <span
                          className="d-flex flex-row alert alert-danger alert-dismissible fade show"
                          id="error"
                          style={{ padding: "10px" }}
                        >
                          <img style={{ marginRight: "15px" }} src={warning} />
                          <div
                            className="vr"
                            style={{
                              marginRight: "15px",
                              width: "2px",
                              height: "40px",
                            }}
                          ></div>
                          <div
                            className="mb-1"
                            style={{ marginBottom: "10px" }}
                          >
                            <strong>Login Failed ! </strong> <br />
                            Incorrect Username or Password.
                          </div>
                        </span>
                      </div>
                    ) : (
                      <div className="login_err"></div>
                    )}
                  </>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
