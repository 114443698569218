import { useState,useEffect } from 'react';

import   './pdfModule.css'

//images
import dropdownarrow from '../../images/dropdownArrow.svg'
import dropdowndisabled from '../../images/dropdowndisabled.svg'
import successTick from '../../images/successTick.svg'
// import bankLogo from '../../images/pdfBankLogo.svg'


function Dropdown({selected,index,setIndex,bankSuccess,bankLoading,setBankSuccess,setDisable,isActive,setIsActive,setIsEncrypted,setFileUploaded,setSelected,bankData,setPdfBorderSelected,setUploaded,setFileSelected,setToken,inputDisabled,setPdf,setInputDisabled}){

// const router= useRouter()


// console.log(bankData,"bankdata")
    // const [isActive,setIsActive] = useState(false)
    // const [index,setIndex] = useState("")

    const [loading,setLoading] = useState(false)
    // const [bankSuccess,setBankSuccess] = useState(false)
    

    
    // console.log(bankData,"bankdata ashish")

    let selectedbank =bankData?bankData[index]?bankData[index].name:"":""


    // console.log(selected, "selected ")
    // console.log(selectedbank, "selected bank")
    
    return (
        <div className="dropdownPdf">
            <div style={{height:"100%",width:"100%"}}>

            
            {isActive  ? <div className="dropdownButton" onClick={()=>setIsActive(!isActive)}>
                
                
                {bankLoading?<span className="spinner-border spinner-border-sm mr-1"></span>:selected}
           {bankLoading?null:<img src={selected==selectedbank?successTick:dropdownarrow}/>}  </div>: <div className={selected==selectedbank?(!bankSuccess?"dropdownButton":"dropdownButtonSuccess"):"dropdownButtondisabled"} onClick={()=>setIsActive(!isActive)}>{bankLoading?<span className="spinner-border spinner-border-sm mr-1"></span>:selected}
           {bankLoading?null:<img src={selected==selectedbank?successTick:dropdowndisabled}/>} </div>}
           
           </div>

            
          </div>  
       
    )
}


export default Dropdown