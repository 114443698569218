import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function CustomTooltip(props) {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 50, hide: 50 }}
      overlay={(overProps) => (
        <Tooltip id="button-tooltip" {...overProps}>
          {props.msg}
        </Tooltip>
      )}
    >
      {props.children}
    </OverlayTrigger>
  );
}

export default CustomTooltip;
