const awsconfig = {
  // Auth : {
  //     "aws_project_region": "ap-south-1",
  //     "aws_cognito_identity_pool_id": "ap-south-1:d2b85ec8-81ad-4d5d-94fb-d146ff50047d",
  //     "aws_cognito_region": "ap-south-1",
  //     "aws_user_pools_id": "ap-south-1_Vqm9WgVqp",
  //     "aws_user_pools_web_client_id": "6774u6nv8fhsd8idp9fdcfb4cl",
  //         },
  //         API : {
  //            endpoints : [
  //                {
  //                 "name" : "fiuCustomers",
  //                 "endpoint" : "https://api-test.ceplr.com/fiu/customers",
  //                 "region" : "ap-south-1"
  //                },
  //                {
  //                    "name" : "consentTemplates",
  //                    "endpoint" : "https://api-test.ceplr.com/consent-templates",
  //                    "region" : "ap-south-1"
  //                },
  //                {
  //                    "name" : "url",
  //                    "endpoint" : "https://api-test.ceplr.com/fiu/link",
  //                    "region" : "ap-south-1"
  //                },
  //                {
  //                 "name" : "Fi",
  //                 "endpoint" : "https://api-test.ceplr.com/Fi",
  //                 "region" : "ap-south-1"
  //                },
  //                {
  //                 "name" : "updateFiu",
  //                 "endpoint" : "https://api-test.ceplr.com/update-fiu",
  //                 "region" : "ap-south-1"
  //                }
  //            ]
  //         }
  me: {
    Auth: {
      aws_project_region: "ap-south-1",
      aws_cognito_identity_pool_id:
        "ap-south-1:d2b85ec8-81ad-4d5d-94fb-d146ff50047d",
      aws_cognito_region: "ap-south-1",
      aws_user_pools_id: "ap-south-1_Vqm9WgVqp",
      aws_user_pools_web_client_id: "6774u6nv8fhsd8idp9fdcfb4cl",
    },
    API: {
      endpoints: [
        {
          name: "fiuCustomers",
          endpoint: "https://api-test.ceplr.com/fiu/customers",
          region: "ap-south-1",
        },
        {
          name: "consentTemplates",
          endpoint: "https://api-test.ceplr.com/consent-templates",
          region: "ap-south-1",
        },
        {
          name: "url",
          endpoint: "https://api-test.ceplr.com/fiu/link",
          region: "ap-south-1",
        },
        {
          name: "Fi",
          endpoint: "https://api-test.ceplr.com/Fi",
          region: "ap-south-1",
        },
        {
          name: "updateFiu",
          endpoint: "https://api-test.ceplr.com/update-fiu",
          region: "ap-south-1",
        },
        {
          name: "resetPassword",
          endpoint: "https://api-test.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "confirmPassword",
          endpoint: "https://api-test.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "sendMail",
          endpoint: "https://api-test.ceplr.com",
          region: "ap-south-1",
        },
        {
          name: "sendConsent",
          endpoint: "https://api-test.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "stageEnabled",
          endpoint: "https://api-test.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "analysis",
          endpoint: "https://api-test.ceplr.com/customers",
          region: "ap-south-1",
        },
        {
          name: "getBanks",
          endpoint: "https://api-test.ceplr.com",
          region: "ap-south-1",
        },
        {
          name: "uploadStatement",
          endpoint: "https://svcdemo.digitap.work/bank-data",
          // region:"ap-south-1"
        },
        {
          name: "consentRequest",
          endpoint: "https://api-test.ceplr.com/consent-request",
          region: "ap-south-1",
        },
        {
          name: "baseUrl",
          endpoint: "https://api-test.ceplr.com",
          region: "ap-south-1",
        },
      ],
    },
  },
  dev: {
    Auth: {
      aws_project_region: "ap-south-1",
      aws_cognito_identity_pool_id:
        "ap-south-1:bd27956e-5474-4cf4-93aa-37cfe163330e",
      aws_cognito_region: "ap-south-1",
      aws_user_pools_id: "ap-south-1_yf1qDwuV6",
      aws_user_pools_web_client_id: "5br18nnq33k7cklrcenb24fvil",
    },
    API: {
      endpoints: [
        {
          name: "fiuCustomers",
          endpoint: "https://api-demo.ceplr.com/fiu/customers",
          region: "ap-south-1",
        },
        {
          name: "consentTemplates",
          endpoint: "https://api-demo.ceplr.com/consent-templates",
          region: "ap-south-1",
        },
        {
          name: "url",
          endpoint: "https://api-demo.ceplr.com/fiu/link",
          region: "ap-south-1",
        },
        {
          name: "Fi",
          endpoint: "https://api-demo.ceplr.com/Fi",
          region: "ap-south-1",
        },
        {
          name: "updateFiu",
          endpoint: "https://api-demo.ceplr.com/update-fiu",
          region: "ap-south-1",
        },
        {
          name: "resetPassword",
          endpoint: "https://api-demo.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "confirmPassword",
          endpoint: "https://api-demo.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "sendMail",
          endpoint: "https://api-demo.ceplr.com",
          region: "ap-south-1",
        },
        {
          name: "sendConsent",
          endpoint: "https://api-demo.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "stageEnabled",
          endpoint: "https://api-demo.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "analysis",
          endpoint: "https://api-demo.ceplr.com/customers",
          region: "ap-south-1",
        },
        {
          name: "getBanks",
          endpoint: "https://api-demo.ceplr.com",
          region: "ap-south-1",
        },
        {
          name: "uploadStatement",
          endpoint: "https://svcdemo.digitap.work/bank-data",
          // region:"ap-south-1"
        },
        {
          name: "consentRequest",
          endpoint: "https://api-demo.ceplr.com/consent-request",
          region: "ap-south-1",
        },
        {
          name: "baseUrl",
          endpoint: "https://api-demo.ceplr.com",
          region: "ap-south-1",
        },
      ],
    },
  },
  prod: {
    Auth: {
      aws_project_region: "ap-south-1",
      aws_cognito_identity_pool_id:
        "ap-south-1:221bf0a1-5d1d-4660-bfcb-0824b657d68d",
      aws_cognito_region: "ap-south-1",
      aws_user_pools_id: "ap-south-1_46sI9PLHL",
      aws_user_pools_web_client_id: "551lri10pe86nmnquh3rfh4ep7",
    },
    API: {
      endpoints: [
        {
          name: "fiuCustomers",
          endpoint: "https://api.ceplr.com/fiu/customers",
          region: "ap-south-1",
        },
        {
          name: "consentTemplates",
          endpoint: "https://api.ceplr.com/consent-templates",
          region: "ap-south-1",
        },
        {
          name: "url",
          endpoint: "https://api.ceplr.com/fiu/link",
          region: "ap-south-1",
        },
        {
          name: "Fi",
          endpoint: "https://api.ceplr.com/Fi",
          region: "ap-south-1",
        },
        {
          name: "updateFiu",
          endpoint: "https://api.ceplr.com/update-fiu",
          region: "ap-south-1",
        },
        {
          name: "resetPassword",
          endpoint: "https://api.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "confirmPassword",
          endpoint: "https://api.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "sendMail",
          endpoint: "https://api.ceplr.com",
          region: "ap-south-1",
        },
        {
          name: "sendConsent",
          endpoint: "https://api.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "stageEnabled",
          endpoint: "https://api.ceplr.com/fiu",
          region: "ap-south-1",
        },
        {
          name: "analysis",
          endpoint: "https://api.ceplr.com/customers",
          region: "ap-south-1",
        },
        {
          name: "getBanks",
          endpoint: "https://api.ceplr.com",
          region: "ap-south-1",
        },
        {
          name: "uploadStatement",
          endpoint: "https://svc.digitap.ai/bank-data",
          // region:"ap-south-1"
        },
        {
          name: "consentRequest",
          endpoint: "https://api.ceplr.com/consent-request",
          region: "ap-south-1",
        },
        {
          name: "baseUrl",
          endpoint: "https://api.ceplr.com",
          region: "ap-south-1",
        },
      ],
    },
  },
};
export default awsconfig;
