import "./Login.css"

import Amplify, { Auth } from 'aws-amplify';

// @ts-ignore
import illustration from "../../images/Frame.svg"
// @ts-ignore
import warning from "../../images/warning.svg"
// @ts-ignore
import logo from "../../images/logo.svg"
import React, { useState } from 'react';
import { useNavigate ,Navigate,Route,useLocation} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    username: yup.string().required("Username Is A Required Field"),
    password: yup.string().required("Password Is A Required Field")
}).required();



const Login = ({ onSignIn }) => {

    let navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    let location = useLocation();

    // console.log("in here logi 1")

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forgotPassword,setForgotPassword] = useState(false)


    const signIn = async () => {

        try {
            // @ts-ignore
            setLoading(true)
            const user = await Auth.signIn(username, password);

            if(location.state.from){
                navigate(location.state.from.pathname)
            }else{
                navigate("/customers");
            }

            
            
            onSignIn();
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log('Error logging in', err);
            setError(true);
        }
    }



    const submitForm = (data) => { }

    return (
        <section id="Login">
            <div className="container-fluid">
                <div className="row align-items center justify-content center">

                    <div className="col col-sm-12 col-xl-6" >
                        <div className="rectangle">
                            <img src={illustration} alt="" className="illustartion" />
                        </div>
                    </div>

                    <div className="col col-sm-12 col-xl-6">
                        <form id="contact-form" className="main" onSubmit={handleSubmit(submitForm)}>

                            <img src={logo} alt="" className="logo" />

                            {forgotPassword?"Hello":
                            <>
                            <div className="input1">
                            <div className="username">Username</div>
                            <input
                                type="text"
                                className={`user_input ${errors.username && "invalid"}`}
                                name="username"
                                value={username}
                                {...register("username", { required: true })}
                                onChange={e => setUserName(e.target.value)}
                            />
                            <p className="validation-error">{errors.username && errors.username?.message}</p>
                        </div>
                         <div className="input2">
                         <div className="password">Password</div>
                         <input
                             type="password"
                             className={`user_password ${errors.password && "invalid"}`}
                             value={password}
                             name="password"
                             {...register("password", { required: true })}
                             onChange={e => setPassword(e.target.value)}
                         />

                         <p className="validation-error">{errors.password && errors.password?.message}</p>
                     </div>
                     {/* <div className="forgot_password" onClick={()=>setForgotPassword(true)}>Forgot password?</div>
                      */}

                      {/* <button onClick={setForgotPassword(true)}>forgotPassword</button> */}
                            <button className="log_in_button" onClick={signIn}>
                                {loading ?
                                    // @ts-ignore
                                    <span style={{ marginRight: "5px" }} className="spinner-border spinner-border-sm mr-1"></span> : `Login`}
                            </button>
                            <div className="login_err">
                                {errors.username || errors.password ? null : error ?
                                    <span className="d-flex flex-row alert alert-danger alert-dismissible fade show" id="error" style={{ padding: "10px" }}>
                                        <img style={{ marginRight: "15px" }} src={warning} />
                                        <div className="vr" style={{ marginRight: "15px", width: "2px", height: "40px" }} ></div>
                                        <div className="mb-1" style={{ marginBottom: "10px" }} >
                                            <strong>Login Failed ! </strong> <br />
                                            Incorrect Username or Password.
                                        </div>
                                    </span>
                                    : null}
                            </div>
                     </>
                        }

                            
                           

                           
                        </form>
                    </div>
                </div>

            </div>

            {/* </div> */}
        </section>
    )
}

export default Login;


