import React, { useEffect, useState } from "react";
import "./DataRequest.css";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer } from "react-toastify";
import {
  errorToaster,
  successToaster,
  getMonthYearBtwDates,
} from "../../../utils/helper";
import { source_type, data_request_status } from "../../../utils/setting";
import AnalysedData from "../../../Components/AnalysedData/analysedData";
import CustomTooltip from "../../../Components/customTooltip/customTooltip";
import close from "../../../images/close.svg";
import arrow from "../../../images/arrow.svg";
import preloader from "../../../images/preloader.gif";
import newEyeIcon from "../../../images/neweye.png";
import newJsonIcon from "../../../images/newjson.png";
import newxlsIcon from "../../../images/newxls.png";
import newAnalyticsIcon from "../../../images/newanalytics.png";
import newTriggerIcon from "../../../images/newtrigger.png";
import downloadIcon from "../../../images/download.png";

// configuration details model
function ConfigurationDetailModel(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} className="modal">
      <Modal.Header className="modal-header">
        <div className="title">
          <Modal.Title id="modal-title">Configuration Details</Modal.Title>
        </div>
        <img src={close} alt="/" id="delete" onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body className="modal-p">
        {props.consent.consent_uuid ? (
          <p>
            <span className="attr">Configuration Id :</span>{" "}
            <span className="value">{props.consent.consent_uuid}</span>
          </p>
        ) : null}

        {props.consent.consent_start ? (
          <p>
            <span className="attr">Consent Start :</span>{" "}
            <span className="value">{props.consent.consent_start}</span>{" "}
          </p>
        ) : null}

        {props.consent.consent_expiry ? (
          <p>
            <span className="attr">Consent Expiry :</span>{" "}
            <span className="value">{props.consent.consent_expiry}</span>{" "}
          </p>
        ) : null}

        {props.consent.consent_mode ? (
          <p>
            <span className="attr">Consent Mode: </span>{" "}
            <span className="value">{props.consent.consent_mode}</span>{" "}
          </p>
        ) : null}

        {props.consent.fetch_type ? (
          <p>
            <span className="attr">Fetch type : </span>{" "}
            <span className="value">{props.consent.fetch_type}</span>{" "}
          </p>
        ) : null}

        {props.consent.fi_data_range_value ? (
          <p>
            <span className="attr">Fi Data Range Value :</span>{" "}
            <span className="value">{props.consent.fi_data_range_value}</span>{" "}
          </p>
        ) : null}

        {props.consent.fi_data_range_unit ? (
          <p>
            <span className="attr">Fi Data Range Unit : </span>{" "}
            <span className="value">{props.consent.fi_data_range_unit}</span>{" "}
          </p>
        ) : null}

        {props.consent.consent_type ? (
          <p>
            <span className="attr">Consent Type :</span>{" "}
            <span className="value">{props.consent.consent_type}</span>{" "}
          </p>
        ) : null}

        {props.consent.fi_types ? (
          <p>
            <span className="attr">Fi Types :</span>{" "}
            <span className="value">{props.consent.fi_types}</span>{" "}
          </p>
        ) : null}
        {props.consent.data_consumer ? (
          <p>
            <span className="attr">Data Consumer :</span>{" "}
            <span className="value">{props.consent.data_consumer}</span>{" "}
          </p>
        ) : null}

        <p>
          <span className="attr">Customer Id :</span>{" "}
          <span className="value">{props.consent.customer_id}</span>{" "}
        </p>

        {props.consent.purpose_code ? (
          <p>
            <span className="attr">Purpose Code :</span>{" "}
            <span className="value">{props.consent.purpose_code}</span>
          </p>
        ) : null}

        {props.consent.purpose_ref_uri ? (
          <p>
            <span className="attr">Purpose Ref Uri :</span>{" "}
            <span className="value"> {props.consent.purpose_ref_uri}</span>
          </p>
        ) : null}

        {props.consent.purpose_text ? (
          <p>
            <span className="attr">Purpose Text :</span>{" "}
            <span className="value">{props.consent.purpose_text}</span>{" "}
          </p>
        ) : null}

        {props.consent.purpose_category ? (
          <p>
            <span className="attr">Purpose Category :</span>{" "}
            <span className="value">{props.consent.purpose_category}</span>{" "}
          </p>
        ) : null}

        <p>
          <span className="attr">Fi Data Range From :</span>{" "}
          <span className="value">
            {props.consent.fi_data_range_from
              ? props.consent.fi_data_range_from
              : props.consent.fetch_data_range_from}
          </span>{" "}
        </p>
        <p>
          <span className="attr">Fi Data Range To :</span>{" "}
          <span className="value">
            {props.consent.fi_data_range_to
              ? props.consent.fi_data_range_to
              : props.consent.fetch_data_range_to}
          </span>{" "}
        </p>

        {props.consent.data_life_unit ? (
          <p>
            <span className="attr">Data Life Unit :</span>{" "}
            <span className="value">{props.consent.data_life_unit}</span>{" "}
          </p>
        ) : null}

        {props.consent.data_life_value ? (
          <p>
            <span className="attr">Data Life Value :</span>{" "}
            <span className="value">{props.consent.data_life_value}</span>{" "}
          </p>
        ) : null}

        {props.consent.frequency_unit ? (
          <p>
            <span className="attr">Frequency Unit :</span>{" "}
            <span className="value">{props.consent.frequency_unit}</span>{" "}
          </p>
        ) : null}

        {props.consent.frequency_value ? (
          <p>
            <span className="attr">Frequency Value :</span>{" "}
            <span className="value">{props.consent.frequency_value}</span>{" "}
          </p>
        ) : null}

        {props.consent.data_filter_type ? (
          <p>
            <span className="attr">Data Filter Type :</span>{" "}
            <span className="value">{props.consent.data_filter_type}</span>{" "}
          </p>
        ) : null}

        {props.consent.data_filter_operator ? (
          <p>
            <span className="attr">Data Filter Operator :</span>{" "}
            <span className="value">{props.consent.data_filter_operator}</span>{" "}
          </p>
        ) : null}

        {props.consent.data_filter_value ? (
          <p>
            <span className="attr">Data Filter Value :</span>{" "}
            <span className="value">{props.consent.data_filter_value}</span>{" "}
          </p>
        ) : null}

        {props.requestID ? (
          <p>
            <span className="attr">Request ID :</span>{" "}
            <span className="value">{props.requestID}</span>{" "}
          </p>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

// frequency model
function FrequencyModel(props) {
  const now = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
  const current_timestamp = new Date(now);
  current_timestamp.setHours(0, 0, 0, 0);
  const today_timestamp = current_timestamp
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  return (
    <Modal
      show={props.show}
      onHide={() => {
        if (!props.loader) {
          props.onHide();
        }
      }}
    >
      {/* header */}
      <Modal.Header closeButton>
        <Modal.Title>Frequency Details</Modal.Title>
      </Modal.Header>

      {/* body */}
      <Modal.Body>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <p className="frequencyText">Available triggers</p>
            <p className="frequencyText">
              <strong style={{ color: "#3a86ff" }}>
                {props.frequency.frequency_current_value}
              </strong>
            </p>
            <p className="frequencyText">out of</p>
            <p className="frequencyText">
              <strong style={{ color: "#3a86ff" }}>
                {props.frequency.frequency_value}
              </strong>
            </p>
            <p className="frequencyText">in this</p>
            <p>
              <p
                style={{
                  color: "#3a86ff",
                  margin: 0,
                  padding: 0,
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {props.frequency.frequency_unit}
              </p>
            </p>
          </div>

          <Button
            style={{
              cursor: "pointer",
              width: "120px",
              height: "40px",
              backgroundColor: "#3a86ff",
            }}
            onClick={() =>
              props.onFrequencyTrigger(props.frequency.consent_handle)
            }
            disabled={
              props.frequency.frequency_current_value == 0 ? true : false
            }
          >
            {props.loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Fetch Again"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// data request page main component
export default function Customers(props) {
  // define variables
  const params = useParams();
  const navigate = useNavigate();
  const uuid = params.id;
  const [loading, setLoading] = useState(false);
  const [dataReq, setDataReq] = useState([]);
  const [consent, setConsent] = useState([]);
  const [customer, setCustomer] = useState("");
  const [target, setTarget] = useState([]);
  const [show, setShow] = useState(false);
  const [jsonLoader, setJsonLoader] = useState(false);
  const [excelLoader, setExcelLoader] = useState(false);
  const [analysedLoader, setAnalysedLoader] = useState(false);
  const [showAnalysedModel, setShowAnalysedModel] = useState(false);
  const [analyticData, setAnalyticData] = useState([]);
  const [monthYearArray, setMonthYearArray] = useState([]);
  const [showFrequencyModel, setShowFrequencyModel] = useState(false);
  const [frequencyLoader, setFrequencyLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const apiKey = localStorage.getItem("apiKey")
    ? localStorage.getItem("apiKey")
    : "";

  // use effect of the component
  useEffect(() => {
    fetchDataReq();
  }, []);

  // method to fetch the data request of customer
  const fetchDataReq = async () => {
    setLoading(true);

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };

    // call the api
    try {
      const response = await API.get("fiuCustomers", `/${uuid}`, requestInfo);
      // if no data found
      if (response.data.length === 0) {
        navigate("/404");
      }

      const customer = response.data[0];
      setCustomer(customer);

      //  call the data request api
      const data = await API.get(
        "fiuCustomers",
        `/data_request/${uuid}`,
        requestInfo
      );
      setDataReq(data.data);
      setLoading(false);
    } catch (error) {
      console.log("error while getting data request ::: ", error.response);
      setLoading(true);
    }
  };

  // get configuration details
  const getConfigurationDetails = async (
    id,
    data_request_id,
    data_req,
    index
  ) => {
    setTarget(index);
    // if the source type is not AA
    if (!id) {
      const data = data_req;
      setConsent(data);
      setShow(true);
    }

    // else source type is Aa
    else {
      // request info
      const requestInfo = {
        body: {
          data_request_id: data_request_id,
        },
      };

      // call the api
      try {
        const data = await API.post(
          "fiuCustomers",
          `/data_request/consent/${id}`,
          requestInfo
        );

        setConsent(data.data);
        setShow(true);
      } catch (error) {
        console.log(
          "error while getting configuration details ::: ",
          error.response
        );
      }
    }
  };

  // get json data
  const getJsonData = async (dataReq, index) => {
    setTarget(index);
    setJsonLoader(true);
    const data_req = dataReq[index];

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
        "x-api-key": apiKey,
      },
      body: {
        data_request_id: data_req.data_request_id,
        format: "rawData",
      },
    };

    // call the api
    try {
      const data = await API.post("Fi", `/${customer.uuid}`, requestInfo);
      const blob = new Blob([JSON.stringify(data.data)], { type: "text/json" });
      const file = document.createElement("a");
      file.download = "raw_data.json";
      file.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      file.dispatchEvent(clickEvt);
      file.remove();
      setJsonLoader(false);
    } catch (error) {
      console.log("error while getting json data ::: ", error.response);
      setJsonLoader(true);
    }
  };

  // get excel data
  const getExcelData = async (dataReq, index) => {
    setTarget(index);
    setExcelLoader(true);
    const data_req = dataReq[index];

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
        "x-api-key": apiKey,
      },
      body: {
        data_request_id: data_req.data_request_id,
        format: "excel",
      },
    };

    // call the api
    try {
      const data = await API.post("Fi", `/${customer.uuid}`, requestInfo);
      const blob = new Blob([new Uint8Array(data.data.data)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = document.createElement("a");
      file.download = "raw_data.xls";
      file.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      file.dispatchEvent(clickEvt);
      file.remove();
      setExcelLoader(false);
    } catch (error) {
      console.log("error while getting excel data ::: ", error.response);
      setExcelLoader(true);
    }
  };

  // get analysed data
  const getAnalysedData = async (dataReq, index) => {
    setTarget(index);
    setAnalysedLoader(true);
    const data_req = dataReq[index];
    const startDate = String(data_req.fetch_data_range_from).split(" ")[0];
    const endDate = String(data_req.fetch_data_range_to).split(" ")[0];

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
        "x-api-key": apiKey,
      },
      body: {
        data_request_id: data_req.data_request_id,
      },
    };

    // call the api
    try {
      const response = await API.post(
        "analysis",
        `/${uuid}/report`,
        requestInfo
      );
      const monthYear = getMonthYearBtwDates(startDate, endDate);
      setMonthYearArray(monthYear);
      setAnalyticData(response.data);
      setAnalysedLoader(false);
      setShowAnalysedModel(true);
    } catch (error) {
      console.log("error while getting analysis data ::: ", error.response);
      setAnalysedLoader(false);
    }
  };

  // get frequency trigger
  const getFrequencyTrigger = async (consentHandle) => {
    setFrequencyLoader(true);

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
      body: {
        callback_url: "",
      },
    };

    // call the api
    try {
      const data = await API.post(
        "consentRequest",
        `/${consentHandle}/process`,
        requestInfo
      );
      if (data.code == 200) {
        successToaster(
          "Request has been submitted. Please wait for few minutes to update"
        );
        setShowFrequencyModel(false);
        setFrequencyLoader(false);
      } else {
        errorToaster("Somthing went wrong, Try again later");
        setShowFrequencyModel(false);
        setFrequencyLoader(false);
      }
    } catch (error) {
      console.log(
        "error while getting frequency trigger  ::: ",
        error.response
      );
      if (error.response.data.code == 403) {
        errorToaster(error.response.data.message);
      } else {
        errorToaster("Somthing went wrong, Try again later");
      }
      setShowFrequencyModel(false);
      setFrequencyLoader(false);
    }
  };

  // get formatted date
  const formattedDate = (date) => {
    var newDate = new Date(date);
    var res = `${String(newDate.getDate()).padStart(2, "0")}/${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}/${newDate.getFullYear()}`;
    return res;
  };

  // common action component
  const ActionComponent = (props) => {
    if (props.show) {
      return (
        <CustomTooltip msg={props.msg}>
          <button className="iconButton" onClick={props.onClick}>
            {props.loader && props.index ? (
              <Spinner
                animation="border"
                style={{
                  fontSize: 5,
                  color: "#3a86ff",
                  width: "15px",
                  height: "15px",
                }}
              />
            ) : (
              <img src={props.icon} style={{ width: 20, height: 20 }} />
            )}
          </button>
        </CustomTooltip>
      );
    } else {
      return "";
    }
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  // download pdf files
  const downloadPdfFiles = async (dataReq, index) => {
    setTarget(index);
    setDownloadLoader(true);
    const data_req = dataReq[index];

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
        "x-api-key": apiKey,
      },
      body: {
        data_request_id: data_req.data_request_id,
        format: "download",
      },
    };

    // call the api
    try {
      const res = await API.post("Fi", `/${customer.uuid}`, requestInfo);
      if (res.code != 200) {
        errorToaster(res.message);
      } else {
        const blob = base64toBlob(res.data, "application/zip");
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "pdf_files.zip";
        link.click();
      }
      setDownloadLoader(false);
    } catch (error) {
      console.log("error while getting pdf file ::: ", error.response);
      errorToaster("No File Found.");
      setDownloadLoader(false);
    }
  };

  // render data request table row
  const renderDataReqRow = (customer, index) => {
    const raw_data_id = customer.raw_data_id;
    const status = customer.status;
    const sourceType = source_type[customer.source_type];
    const isFrequency = customer.is_frequency;
    const dataRequestStatus = data_request_status[status]
      ? data_request_status[status]
      : "FAILED";

    return (
      <tr key={index} className={index % 2 == 1 ? "bg-light" : ""}>
        <td>{formattedDate(customer.fetch_date)}</td>
        <td>{sourceType}</td>
        <td>{customer.fi_type}</td>
        <td>{dataRequestStatus}</td>
        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* view configuration details */}
            <ActionComponent
              msg="View configuration details"
              icon={newEyeIcon}
              onClick={() => {
                getConfigurationDetails(
                  customer.consent_handle,
                  customer.data_request_id,
                  customer,
                  index
                );
                var rowData = customer.consent_handle ? consent : customer;
                rowData["index"] = index + 1;
                setConsent(rowData);
              }}
              loader={false}
              index={false}
              show={true}
            />

            {/* download json icon */}
            <ActionComponent
              msg="Download json"
              icon={newJsonIcon}
              onClick={() => getJsonData(dataReq, index)}
              loader={jsonLoader}
              index={target == index ? true : false}
              show={
                !!!raw_data_id && status == 2
                  ? false
                  : raw_data_id && status == 1 && sourceType == "PDF"
                  ? true
                  : false
              }
            />

            {/* download excel icon */}
            <ActionComponent
              msg="Download excel"
              icon={newxlsIcon}
              onClick={() => getExcelData(dataReq, index)}
              loader={excelLoader}
              index={target == index ? true : false}
              show={
                !!!raw_data_id && status == 2
                  ? false
                  : raw_data_id && status == 1 && sourceType == "PDF"
                  ? true
                  : false
              }
            />

            {/* analysed icon */}
            <ActionComponent
              msg="View analysed data"
              icon={newAnalyticsIcon}
              onClick={() => getAnalysedData(dataReq, index)}
              loader={analysedLoader}
              index={target == index ? true : false}
              show={status == 1 ? true : false}
            />

            {/* analysed icon */}
            <ActionComponent
              msg="View frequency details"
              icon={newTriggerIcon}
              onClick={() => {
                setTarget(index);
                setShowFrequencyModel(true);
              }}
              loader={false}
              index={false}
              show={isFrequency ? true : false}
            />

            {/* download pdf files */}
            <ActionComponent
              msg="Download PDF Files"
              icon={downloadIcon}
              onClick={() => downloadPdfFiles(dataReq, index)}
              loader={downloadLoader}
              index={target == index ? true : false}
              show={
                !!!raw_data_id && status == 2
                  ? false
                  : raw_data_id && status == 1 && sourceType == "PDF"
                  ? true
                  : false
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  // render data request table
  const renderDataReqTable = (rows) => {
    if (rows == 0) {
      return <tbody>No Data Requested</tbody>;
    } else if (rows == 1) {
      return <tbody>{renderDataReqRow(dataReq[0], 0)}</tbody>;
    } else {
      return (
        <tbody>
          {dataReq.map((element, index) => {
            return renderDataReqRow(element, index);
          })}
        </tbody>
      );
    }
  };

  return (
    <section id="datarequest_page">
      <ToastContainer pauseOnHover={false} />
      <div className="container-fluid cp">
        <div className="row">
          <div className="col-4">
            <div className="customer-header">
              <NavLink to="/customers" className="customers_link">
                Customers
              </NavLink>{" "}
              <img id="arrow" src={arrow} alt="" />{" "}
              <span>{customer === undefined ? "" : customer.name}</span>
            </div>
            <div className="datarequest-header">Data Requests</div>
            <div className="customer_content">
              Each request for financial data of this customer is displayed
              below along with the details of the configuration provided by the
              customer.
            </div>
          </div>

          <div className="col-8">
            {props.toggleLoading ? (
              <div className="custTable">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Fetch Date</th>
                      <th scope="col">Source Type</th>
                      <th scope="col">FI Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                </table>
                <div className="data_request_loader_conainer">
                  <img
                    style={{
                      width: "60px",
                      height: "70px",
                    }}
                    src={preloader}
                    alt=""
                  />
                </div>
              </div>
            ) : loading ? (
              <div className="custTable">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Fetch Date</th>
                      <th scope="col">Source Type</th>
                      <th scope="col">FI Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                </table>
                <div className="data_request_loader_conainer">
                  <img
                    style={{
                      width: "60px",
                      height: "70px",
                    }}
                    src={preloader}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              <div className="custTable">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Fetch Date</th>
                      <th scope="col">Source Type</th>
                      <th scope="col">FI Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {renderDataReqTable(dataReq.length)}
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* show configuration deatils model */}
      {show ? (
        <ConfigurationDetailModel
          show={show}
          onHide={() => setShow(false)}
          consent={consent}
          requestID={
            dataReq[target].source_type == "1"
              ? dataReq[target].request_id
              : null
          }
        />
      ) : null}

      {/* show analysed data model */}
      {showAnalysedModel ? (
        <AnalysedData
          show={showAnalysedModel}
          onHide={() => setShowAnalysedModel(false)}
          data={analyticData}
          monthYearArray={monthYearArray}
        />
      ) : null}

      {/* show frequency model  */}
      {showFrequencyModel ? (
        <FrequencyModel
          show={showFrequencyModel}
          onHide={() => setShowFrequencyModel(false)}
          loader={frequencyLoader}
          frequency={dataReq[target]}
          onFrequencyTrigger={(value) => getFrequencyTrigger(value)}
        />
      ) : null}
    </section>
  );
}
