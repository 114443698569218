import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar/index";
import Topbar from "./Topbar/index";
import Customers from "../../pages/customers/index";
import ConsentTemplate from "../../pages/ConsentTemplate/index";
import "./Dashboard.css";
import TryItNow from "../../pages/TryItNow/index";
import DataRequest from "../../pages/customers/DataRequest/index";
import Setting from "../../pages/Settings/index";
import Support from "../../pages/Support/support";
// import image from "../../images/redirectImage"
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import awsconfig from "../../Constant/aws-exports";
import Login from "../Login/index";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";

// API.configure(awsconfig[process.env.REACT_APP_STAGE]);

const Dashboard = (props) => {
  let navigate = useNavigate();
  const location = useLocation();

  const [fiu, setFiu] = useState();
  const [toggleLoading, setToggleLoading] = useState(props.toggleLoading);

  const currentUser = async () => {
    await Auth.currentSession((data) => console.log(data));
  };

  //  console.log(location, "dashboard pathname")

  let from = localStorage.getItem("from");

  // console.log(from, "from")

  useEffect(() => {
    // console.log(location.pathname);

    // console.log(currentUser(), "current user")
    // updateName();

    if (from != "" && location.pathname != "/") {
      // console.log("in here")

      navigate(`${from}`);
    } else {
      if (location.pathname == "/") {
        navigate("/customers");
      } else {
        navigate(location.pathname);
      }
    }
  }, []);

  // console.log(props,"fiudetails")

  const updateName = async () => {
    const result = await API.get("updateFiu", "/name");

    // localStorage.setItem('result',result)
    if (result.data.stage_enabled == 1) {
      localStorage.setItem("fiu_stage", "prod");
      // refreshPage();
    } else if (result.data.stage_enabled == 0) {
      localStorage.setItem("fiu_stage", "dev");
      // refreshPage();
    }
    localStorage.setItem("result", JSON.stringify(result));
    setFiu(result.data);
  };

  const signOut = async () => {
    try {
      props.onLogout();
      // props.setLoading(true);
      // await Auth.signOut();
      // props.setLoggedIn(false);
      // localStorage.clear();
      navigate("/");
    } catch (error) {
      // console.log("error signing out", error);
    }
  };

  return (
    <section id="dashboard">
      <div className="container-fluid">
        <div className="row-1">
          <Topbar
            setToggleLoading={setToggleLoading}
            toggleLoading={toggleLoading}
          />
        </div>
        <div className="row">
          <div
            className={
              window.location.pathname === "/consent-flow"
                ? null
                : "col col-md-6"
            }
          >
            {window.location.pathname === "/consent-flow" ? null : (
              <Sidebar
                signOut={signOut}
                toggleLoading={toggleLoading}
                setToggleLoading={setToggleLoading}
              />
            )}
          </div>

          <div
            className={
              window.location.pathname === "/consent-flow"
                ? null
                : "col col-md-6"
            }
          >
            <Routes>
              <Route
                exact
                path="/customers"
                element={
                  toggleLoading ? (
                    <Customers
                      setToggleLoading={setToggleLoading}
                      toggleLoading={toggleLoading}
                    />
                  ) : (
                    <Customers
                      setToggleLoading={setToggleLoading}
                      toggleLoading={toggleLoading}
                    />
                  )
                }
              ></Route>
              <Route
                path="/configurations"
                element={<ConsentTemplate toggleLoading={toggleLoading} />}
              ></Route>
              <Route path="/consent-flow" element={<TryItNow />}></Route>
              <Route
                path="/settings"
                element={<Setting toggleLoading={toggleLoading} />}
              ></Route>
              <Route path="/postman-collection" element={<Setting />}></Route>
              <Route path="/api-reference" element={<Setting />}></Route>
              <Route
                path="/support"
                element={
                  <Support
                    toggleLoading={toggleLoading}
                    setToggleLoading={setToggleLoading}
                  />
                }
              ></Route>

              <Route
                path="/customers/:id/data-requests"
                element={<DataRequest toggleLoading={toggleLoading} />}
              ></Route>

              <Route
                path="/404"
                element={
                  <ErrorPage
                    loggedIn={props.loggedIn}
                    setLoggedIn={props.setLoggedIn}
                  />
                }
              ></Route>
              <Route exact path="/*" element={<Navigate replace to="/404" />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
