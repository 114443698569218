import React, { useEffect, useState } from "react";
import "./dropdown.css";
import close from "../../images/close.svg";
import drop from "../../images/dropDown.svg";

function Dropdown({ type, changeType, values, singleSelection = false }) {
  // const [length,setlength]=useState(type.length)

  // let array
  // console.log(values,'3652')
  const [active, setActive] = useState(false);
  const [check, setCheck] = useState([]);

  // console.log(check)

  // useEffect(()=>{
  //     // let arr=values.split(",");
  //     setCheck(values)

  // },[active])

  const handleList = (e, acc) => {
    if (singleSelection) {
      setCheck([e.target.value]);
    } else {
      var updatedList = [...check];
      // console.log(e.target.value)
      if (e.target.checked) {
        updatedList = [...check, e.target.value];
      } else {
        if (check.includes(e.target.value)) {
          var idx = check.findIndex((x) => x === e.target.value);
          updatedList.splice(idx, 1);
        }
      }
      // console.log(updatedList)
      // console.log(check)
      // console.log('!!!!!!!!!!!!', JSON.stringify(updatedList));
      setCheck(updatedList);
    }
  };

  // console.log(check,".<--")

  // console.log(check.includes("PROFILE"),"sss")
  function send() {
    setActive(!active);
    changeType(check);
  }
  return (
    <div className="dropdown">
      <div className="header">
        <div className="doropdownHeader" onClick={() => send()}>
          {check.length == 0
            ? "Select"
            : `${check
                .map((type) => {
                  // console.log(type)
                  return type;
                })
                .join(", ")}`}
        </div>
        <div className="ArrowDropDown" onClick={() => send()}>
          {active ? (
            <div className="img2">
              <img src={close} />
            </div>
          ) : (
            <div className="img1">
              <img src={drop} />
            </div>
          )}
        </div>
      </div>
      {active ? (
        <div
          className={`${
            type.length == 2 ? "dropdown-content1" : "dropdown-content"
          }`}
        >
          <ul
            className={`${type.length == 2 ? "dropdown-ul1" : "dropdown-ul"}`}
          >
            {type.map((account, index) => {
              return (
                <label
                  for={account.name}
                  className="dropdown-label"
                  onChange={(e) => handleList(e, account)}
                >
                  <li className="dropdown-list">
                    <div className="type-name">{account.name}</div>
                    <div className="dropdown-checkbox">
                      <input
                        type="checkbox"
                        id={account.name}
                        value={account.name}
                        checked={
                          check.includes(`${account.name}`) ? "true" : ""
                        }
                      />
                    </div>
                  </li>
                </label>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Dropdown;
