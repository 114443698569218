import React,{useState} from 'react'
import "./dropDown.css"
import drop from "../../../images/dropDown.svg"
import tick from "../../../images/dropTick.svg"

function DropDown() {
    const[active,setActive]=useState(false)
    const [defaultSort,setDefaultSort]=useState("Latest")

    let values=[{name:"Latest"}, {name:"Oldest"}]
 return (
    <div id='dropDownDiv' onClick={()=>{setActive(!active)}}>
        <div className='dropDownHeader' >
            {defaultSort}
        </div>
        <div className='ArrowDropDown'>
                {active?<div ><img className="rotateimg180" src={drop}/></div>:<div><img className="rotateimg1801" src={drop}/></div>}
        </div>
        {active?<div className='dropD-content'>
            <ul>
                {values.map(sort=>{
                   return( 
                   <li className={defaultSort==`${sort.name}`?"selectedSort":"DropDlist"}  onClick={()=>{setDefaultSort(`${sort.name}`)}}>
                        <div className='sortVal'>{sort.name}</div>
                        
                            {defaultSort==`${sort.name}`? <div className='tick'><img src={tick}/></div> :""}
                        
                        
                    </li>)
                })}
            </ul>
        </div>:""}
    </div>
  )
}

export default DropDown