import "./Login.css";
import { Auth, API } from "aws-amplify";
// @ts-ignore
import illustration from "../../images/Frame.svg";
// @ts-ignore
import warning from "../../images/warning.svg";
// @ts-ignore
import logo from "../../images/new_logo.png";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { getConstantValue } from "typescript";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blindEye from "../../images/blindEye.svg";
import eyeIcon from "../../images/eyeIcon.svg";

export default function RequestAccount({
  setRequestAccount,
  requestAccount,
  onGoBack,
}) {
  const [error, setError] = useState(false);

  // const [requestAccount,setRequestAccount] = useState(false)

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const [loading, setLoading] = useState(false);

  const requestAccountConfirmation = async (data) => {
    setLoading(true);

    console.log(data, "data");

    const requestOptions = {
      // method: 'POST',
      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        "Content-Type": "application/json",
        // "x-api-key": personalDetails.id
      },

      body: {
        email: data.email,
        name: data.name,
        subject: "Account Request",
      },
    };
    // console.log(requestOptions.body, "bodyyy")

    try {
      const res = await API.post("sendMail", "/sendMail", requestOptions);

      console.log(res);

      setTimeout(() => {
        setRequestAccount(false);
        setLoading(false);
        reset();
      }, 2000);

      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "confirmSuccess",
      });
    } catch (err) {
      setLoading(false);
      setError(true);
      // console.log(err, "errrors")
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        id="contact-form"
        className="main1"
        onSubmit={handleSubmit(requestAccountConfirmation)}
      >
        <img src={logo} alt="" className="logo" />

        <>
          <div className="input1" style={{ marginTop: "4%" }}>
            <div className="confirmPassword">Email</div>
            <input
              type="email"
              className={`user_password ${
                errors.email ? "invalid" : "user_password"
              }`}
              name="email"
              // value={newPassword}
              // onChange={newPasswordChange}
              // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter the valid Email.",
                },
                // minLength:{
                //   value:6,
                //   message:"Minimum required length is 6"
                // }
              })}
            />
            {errors.email ? (
              <p className="validation-error">{errors.email.message}</p>
            ) : (
              <p className="validation-error"></p>
            )}
          </div>

          <div className="input4">
            <div className="requestName">Name</div>
            <input
              type="text"
              className={`req_Name ${
                errors.name ? "error_req_name" : "req_Name"
              }`}
              name="name"
              // value={confirmedPassword}
              // onChange={confirmHandleChange}
              // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$"
              defaultValue={""}
              {...register("name", {
                required: "Name is required",
                pattern: {
                  message: "Name is required",
                },
              })}
            />
            {errors.name ? (
              <p className="validation-error">{errors.name.message}</p>
            ) : (
              <p className="validation-error"></p>
            )}
          </div>

          {/* <div className="forgot_password" onClick={()=>forgotPasswordFunction()}>Reset password?</div> */}
          <button className="log_in_button">
            {loading ? (
              <span
                style={{ marginRight: "5px" }}
                className="spinner-border spinner-border-sm mr-1"
              ></span>
            ) : (
              `Submit`
            )}
          </button>

          <div className="goBack" onClick={onGoBack}>
            {" "}
            Go back
          </div>
        </>
      </form>
    </div>
  );
}
