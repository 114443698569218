import React, { useState } from "react";
import { API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

function SendModel(props) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [nameInvalid, setNameInvalid] = useState("");
  const [mobileInvalid, setMobileInvalid] = useState("");
  const [emailInvalid, setEmailInvalid] = useState("");
  const [loader, setLoader] = useState(false);

  // check the form data
  const checkData = () => {
    setLoader(true);
    let foundErr = false;
    // check name
    if (name.length == 0) {
      setNameInvalid("Invalid name");
      // foundErr = true;
      // setLoader(false);
    }
    // check mobile
    var mobile_format = /^[6-9][0-9]{9}$/;
    if (!mobile_format.test(mobile)) {
      setMobileInvalid("Invalid mobile");
      foundErr = true;
      setLoader(false);
    }
    // check email
    var mail_format =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail_format.test(String(email).toLowerCase())) {
      setEmailInvalid("Invalid email");
      // foundErr = true;
      // setLoader(false);
    }

    //  if all good
    if (!foundErr) {
      // initize data
      const data = {
        customer_name: name,
        customer_contact: mobile,
        customer_email: email,
        configuration_uuid: props.configuration_uuid,
        redirect_url: "https://ceplr.com/",
        callback_url: "https://ceplr.com/",
        mode: "sms",
      };
      sendConsent(data);
    }
  };

  // send consent
  const sendConsent = async (data) => {
    try {
      // get api key
      const apiKey = localStorage.getItem("apiKey")
        ? localStorage.getItem("apiKey")
        : "";
      // create param
      const params = {
        headers: {
          "Content-type": "application/json",
          "x-api-key": apiKey,
        },
        body: data,
      };
      // call the api
      await API.post("sendConsent", "/consents", params);
      setLoader(false);
      props.onSuccess();
    } catch (e) {
      console.log("Error while sending consent ::: ", e);
      setLoader(false);
      props.onError();
    }
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      {/* header */}
      <Modal.Header closeButton>
        <Modal.Title>Send Consent Link</Modal.Title>
      </Modal.Header>

      {/* body */}
      <Modal.Body>
        <Form autoComplete="off">
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <div className="form-group required">
            <Form.Label className="control-label">Mobile Number</Form.Label>
            </div>
            <Form.Control
              disabled={loader}
              maxLength={10}
              type="text"
              onChange={(e) => {
                setMobileInvalid("");
                setMobile(e.target.value);
              }}
              placeholder="Enter mobile number"
              isInvalid={mobileInvalid.length > 0 ? true : false}
            />
            <Form.Control.Feedback
              type={mobileInvalid.length > 0 ? "invalid" : "valid"}
            >
              {mobileInvalid}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              disabled={loader}
              type="text"
              onChange={(e) => {
                // setNameInvalid("");
                setName(e.target.value);
              }}
              placeholder="Enter name"
              // isInvalid={nameInvalid.length > 0 ? true : false}
            />
            <Form.Control.Feedback
              type={nameInvalid.length > 0 ? "invalid" : "valid"}
            >
              {nameInvalid}
            </Form.Control.Feedback>
          </Form.Group>

          
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              disabled={loader}
              type="email"
              onChange={(e) => {
                // setEmailInvalid("");
                setEmail(e.target.value);
              }}
              placeholder="Enter email id"
              // isInvalid={emailInvalid.length > 0 ? true : false}
            />
            <Form.Control.Feedback
              type={emailInvalid.length > 0 ? "invalid" : "valid"}
            >
              {emailInvalid}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>

      {/* footer */}
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ cursor: "pointer", width: "120px", height: "40px" }}
          variant="primary"
          onClick={() => checkData()}
        >
          {loader ? <Spinner animation="border" size="sm" /> : "Send"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SendModel;
