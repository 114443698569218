import React, { useEffect, useState } from "react";
import dimension from "./images/dimension.png";
import App from "./App";

function Responsive() {
  const [width, setWidth] = useState(window.innerWidth);

  // event listener for window resize
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // if width is greater than 1080px
  if (width > 1080) {
    return <App />;
  }

  // if width is not greater than 1080px
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={dimension}
          style={{ width: (width * 35) / 100 }}
          alt="dimension"
        />
        <p
          style={{
            fontSize: (width * 3) / 100,
          }}
        >
          Please open the dashboard on your desktop.
        </p>
      </div>
    </div>
  );
}

export default Responsive;
