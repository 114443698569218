import React from "react";
import { Modal } from "react-bootstrap";
import background from "../../images/background.svg";
import delete_1 from "../../images/exclamation-mark.png";
import "./UpDatePopup.css";

function UpdatePopup(props) {
  return props.trigger ? (
    <Modal show  
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    
      <Modal.Body>
      
          <div className="backGroundContainer">
            <div className="deleteBackground">
              <div className="deleteDiv">
              <img src={delete_1} alt="/" className="deleteBin"  style={{height:"30px",width:"30px"}}/>
              </div>
             
             
              <div className="deleteConfirmation">
                Are you sure you want to update this configuration?
              </div>
            </div>
          
          <div className="buttonDiv">
            <button
              className="yesButton"
              onClick={() => {
                props.yes(props.uuid);
                props.setTrigger(false);
              }}
            >
              Yes
            </button>
            <button
              className="noButton"
              onClick={() => props.setTrigger(false)}
            >
              No
            </button>
          </div>
          </div>
      
        </Modal.Body>
    </Modal>
  ) : (
    ""
  );
}

export default UpdatePopup;
