import React, { useEffect, useState } from "react";

//images
import Mobile from "../../images/new_mobile.png";
import blueTick from "../../images/blueTick.svg";
import contactImage from "../../images/contactImage.svg";
import phoneIcon from "../../images/phone.svg";
import emailIcon from "../../images/email.svg";
import { ToastContainer, toast } from "react-toastify";
import preloader from "../../images/preloader.gif";

import { Auth, API } from "aws-amplify";

//cssStyle
import "./support.css";
import { set } from "react-hook-form";

const subjectData = [
  "Report A Bug",
  "Request a feature",
  "Contact Support",
  "API Integration questions",
  "Other",
];

function Support({ toggleLoading, setToggleLoading }) {
  const [subject, setSubject] = useState("");
  const [anotherSubject, setAnotherSubject] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  console.log(subject, "subject");

  let fiuName = localStorage.getItem("fiuName");
  let fiuEmail = localStorage.getItem("fiuEmail");

  const textAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };
  const anotherSubjectChange = (event) => {
    setAnotherSubject(event.target.value);
  };

  const subjectChange = (event) => {
    setSubject(event.target.value);

    console.log(event.target.value);
  };
  const fetchTemplates = async () => {
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };

    //
    try {
      const response = await API.get(
        "consentTemplates",
        "/templates",
        requestInfo
      );
      const templates = response.data;
      // console.log(templates)

      localStorage.setItem("tempId", templates[0].uuid);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => fetchTemplates());

  const sendQuery = async () => {
    setLoading(true);
    const requestInfo = {
      method: "POST",
      headers: {
        // "Content-type": "application/json"
      },
      body: {
        name: fiuName,
        email: fiuEmail,
        subject: subject == "Other" ? anotherSubject : subject,
        description: textAreaValue,
      },
    };

    //
    try {
      // console.log("here in try")

      const res = await API.post("sendMail", "/sendMail", requestInfo);
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "Contact US",
      });

      setLoading(false);
      setSubject("");
      setAnotherSubject("");
      setTextAreaValue("");
    } catch (err) {
      // console.log(err, "errrors")
      // setResetResponse("")
    }
  };

  const handleSubmit = (event) => {
    // alert('Your favorite flavor is: ' + this.state.value);
    // event.preventDefault();
  };

  return (
    <section id="support">
      <ToastContainer />
      {toggleLoading ? (
        <div
          style={{
            display: "flex",
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={preloader} className="preloa" />
        </div>
      ) : (
        <div className="supportDiv">
          <div className="imageDiv">
            <img src={contactImage} className="contactImage" />
          </div>
          <div className="contactDiv">
            <div className="formContainer">
              <div className="headingDiv">Write to us</div>
              <form className="formDiv">
                <select
                  className="subjectInput"
                  value={subject}
                  onChange={subjectChange}
                >
                  <option key="blankChoice" hidden value>
                    {" "}
                    Select Subject{" "}
                  </option>
                  {subjectData.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>

                {subject == "Other" ? (
                  <input
                    className="anotherSubjectInput"
                    placeholder="Write Your Subject"
                    type="text"
                    value={anotherSubject}
                    onChange={anotherSubjectChange}
                  />
                ) : (
                  ""
                )}

                <textarea
                  className="textAreaClass"
                  value={textAreaValue}
                  onChange={textAreaChange}
                  placeholder="write a message*"
                />

                {textAreaValue == "" &&
                (subject == "" || anotherSubject == "") ? (
                  <div className="buttonDisabled">
                    {loading ? (
                      <span
                        style={{ marginRight: "5px" }}
                        className="spinner-border spinner-border-sm mr-1"
                      ></span>
                    ) : (
                      `Submit`
                    )}
                  </div>
                ) : (
                  <div className="button" onClick={sendQuery}>
                    {loading ? (
                      <span
                        style={{ marginRight: "5px" }}
                        className="spinner-border spinner-border-sm mr-1"
                      ></span>
                    ) : (
                      `Submit`
                    )}
                  </div>
                )}
              </form>
            </div>
            <div className="contactUsDiv">
              <div className="headingDiv">Contact us</div>
              <div className="emailMobilediv">
                <div className="mobileDiv">
                  <img className="imageIcon" src={phoneIcon} />
                  <text className="contactText"> 9632161891</text>
                </div>
                <div className="emailDiv">
                  <img className="imageIcon" src={emailIcon} />
                  <text className="contactText">
                    {" "}
                    ceplr_support@arthmate.com
                  </text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Support;
