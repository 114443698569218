import React,{useState} from 'react'
import "./settings.css"
import UpdateFiu from './UpdateFiu';
import Api from './Api';
function Settings({toggleLoading}) {

    const [tab,setTab]=useState("Profile");
    const [tabloader, setTabloader] = useState(false)

  return (
    <section id='settings'>
        <div className="container">
            <div className='row'>
                <div className="col-3">
                    <nav className='settings-nav'>
                        <ul className='settings-ul'>
                            <li onClick={()=>{setTab("Profile")}} className={tab=="Profile"?"active":""}>
                                Profile
                            </li>
                            <li onClick={()=>{setTab("Api")}} className={tab=="Api"?"active":""}>
                                API Keys
                            </li>
                        </ul>
                    </nav>
                </div>  
                <div className="col-9">
                     {tab=="Profile"?
                     <UpdateFiu toggleLoading={toggleLoading}/>
                    
                     :<Api toggleLoading={toggleLoading}/>}   
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default Settings;
