import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import logout from "../../../images/logout.svg";
import { sidebarData } from "./sideBarData";
import { useLocation, useNavigate } from "react-router-dom";
import redirectImage from "../../../images/redirectImage.svg";
import customer_active from "../../../images/customer_active.svg";
import customer_inactive from "../../../images/customer_inactive.svg";
import api_reference_active from "../../../images/api_reference_active.svg";
import api_reference_inactive from "../../../images/api_reference_inactive.svg";
import try_it_now_active from "../../../images/try_it_now_active.svg";
import try_it_now_inactive from "../../../images/try_it_now_inactive.svg";
import default_fiu_image from "../../../images/default_fiu_icon.svg";
import supportIcon from "../../../images/supportIcon.svg";
import supportIconInactive from "../../../images/supportIconInactive.svg";
import { getDocumentLink } from "../../../utils/helper";

function Sidebar({
  signOut,
  callApi,
  history,
  setToggleLoading,
  toggleLoading,
}) {
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const customer = location.pathname;
  const id = customer.split("/");
  const fiuName = localStorage.getItem("fiuName")
    ? localStorage.getItem("fiuName")
    : "";
  const fiuEmail = localStorage.getItem("fiuEmail")
    ? localStorage.getItem("fiuEmail")
    : "";
  const image = localStorage.getItem("images")
    ? localStorage.getItem("images")
    : "";

  const activeLink = (recent) => {
    if (window.location.pathname === recent) {
      return "is-active";
    }
  };

  useEffect(
    () => {
      // console.log("hello sidebar firse")
      // setToggleLoading(true)
      setLoader(false);
    },
    activeLink(),
    []
  );

  return (
    <>
      <div className="sidebar_main_container">
        {/* divider */}

        <div className="sidebar_container_fiu">
          <div className="sidebar_container_fiu_row">
            {/* icon */}
            <div className="sidebar_container_fiu_container">
              <img
                src={image == "" ? default_fiu_image : image}
                className="sidebar_container_fiu_icon"
              />
            </div>

            {/* name and email */}
            <div className="sidebar_container_fiu_name_email_container">
              <p
                className="sidebar_container_fiu_name"
                onMouseEnter={() => {
                  if (fiuName.length > 22) {
                    document.getElementsByClassName(
                      "tool_tip_name"
                    )[0].style.visibility = "visible";
                  }
                }}
                onMouseLeave={() => {
                  document.getElementsByClassName(
                    "tool_tip_name"
                  )[0].style.visibility = "hidden";
                }}
              >
                {fiuName.length > 22
                  ? String(fiuName).substring(0, 22) + "..."
                  : fiuName}
                <span class="tool_tip_name">{fiuName}</span>
              </p>
              <p
                className="sidebar_container_fiu_email"
                onMouseEnter={() => {
                  if (fiuEmail.length > 22) {
                    document.getElementsByClassName(
                      "tool_tip_email"
                    )[0].style.visibility = "visible";
                  }
                }}
                onMouseLeave={() => {
                  document.getElementsByClassName(
                    "tool_tip_email"
                  )[0].style.visibility = "hidden";
                }}
              >
                {fiuEmail.length > 22
                  ? String(fiuEmail).substring(0, 22) + "..."
                  : fiuEmail}
                <span class="tool_tip_email">{fiuEmail}</span>
              </p>
            </div>
          </div>
        </div>

        {/* divider  */}
        <div className="sidebar_divider" />

        {/* rest */}

        <div className="sidebar_container_rest">
          {/* sandbox section */}
          <p className="sidebar_container_rest_heading">SANDBOX</p>
          {/* customer */}
          <div
            onClick={() => navigate("/customers")}
            className={
              activeLink("/customers") ||
              activeLink(`/customers/${id[2]}/data-requests`)
                ? "sidebar_container_rest_row_active"
                : "sidebar_container_rest_row_inactive"
            }
          >
            {/* icon */}
            <img
              src={
                activeLink("/customers") ||
                activeLink(`/customers/${id[2]}/data-requests`)
                  ? customer_active
                  : customer_inactive
              }
              className="sidebar_container_rest_icon"
            />
            {/* title */}
            <div
              className={
                activeLink("/customers") ||
                activeLink(`/customers/${id[2]}/data-requests`)
                  ? "sidebar_container_rest_title_active"
                  : "sidebar_container_rest_title_inactive"
              }
            >
              Customers
            </div>
          </div>

          {/* developers section */}
          <p className="sidebar_container_rest_heading">DEVELOPERS</p>
          {sidebarData.map((item, index) => {
            return (
              <div
                onClick={() => navigate(item.path)}
                key={index}
                className={
                  activeLink(item.path)
                    ? "sidebar_container_rest_row_active"
                    : "sidebar_container_rest_row_inactive"
                }
              >
                {/* icon */}
                <img
                  src={
                    activeLink(item.path)
                      ? item.active_image
                      : item.inactive_image
                  }
                  className="sidebar_container_rest_icon"
                />
                {/* title */}
                <div
                  className={
                    activeLink(item.path)
                      ? "sidebar_container_rest_title_active"
                      : "sidebar_container_rest_title_inactive"
                  }
                >
                  {item.title}
                </div>
              </div>
            );
          })}

          {/* api reference */}

          <div
            onClick={() => window.open(getDocumentLink(), "_blank")}
            className={
              activeLink(getDocumentLink())
                ? "sidebar_container_rest_row_active"
                : "sidebar_container_rest_row_inactive"
            }
          >
            {/* icon */}
            <img
              src={
                activeLink(getDocumentLink())
                  ? api_reference_active
                  : api_reference_inactive
              }
              className="sidebar_container_rest_icon"
            />
            {/* title */}
            <div
              className={
                activeLink(getDocumentLink())
                  ? "sidebar_container_rest_title_active"
                  : "sidebar_container_rest_title_inactive"
              }
            >
              API Reference
            </div>
            {/* icon */}
            <img src={redirectImage} style={{ marginLeft: "10px" }} />
          </div>

          {/* try now */}
          <div
            onClick={() => window.open("/consent-flow", "_blank")}
            className={
              activeLink("/consent-flow")
                ? "sidebar_container_rest_row_active"
                : "sidebar_container_rest_row_inactive"
            }
          >
            {/* icon */}
            <img
              src={
                activeLink("/consent-flow")
                  ? try_it_now_active
                  : try_it_now_inactive
              }
              className="sidebar_container_rest_icon"
            />
            {/* title */}
            <div
              className={
                activeLink("/consent-flow")
                  ? "sidebar_container_rest_title_active"
                  : "sidebar_container_rest_title_inactive"
              }
            >
              Try It Now
            </div>
            {/* icon */}
            <img src={redirectImage} style={{ marginLeft: "10px" }} />
          </div>

          {/* customer */}
          <div
            onClick={() => navigate("/support")}
            className={
              activeLink("/support")
                ? "sidebar_container_rest_row_active"
                : "sidebar_container_rest_row_inactive"
            }
          >
            {/* icon */}
            <img
              src={activeLink("/support") ? supportIcon : supportIconInactive}
              className={
                activeLink("/support") ? "supportIcon" : "supportIconFaded"
              }
            />
            {/* title */}
            <div
              className={
                activeLink("/support")
                  ? "sidebar_container_rest_title_active"
                  : "sidebar_container_rest_title_inactive"
              }
            >
              Support
            </div>
          </div>
        </div>

        {/* log out */}

        <div className="sidebar_container_rest_logout_container">
          <div
            className="sidebar_container_rest_logout_button"
            onClick={signOut}
          >
            <img src={logout} className="sidebar_container_rest_logout_icon" />
            <p className="sidebar_container_rest_logout_text">Logout</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
