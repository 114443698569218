/**
 * Method to format the given string of datetime.
 * @param {String} str A datetime string that needs to be formatted
 * @param {String} type Type of output to be received : 'date' / 'datetime', by default the type of output is set to be 'datetime'
 * @returns Formatted datetime string
 */

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getFormatted = (str = "", type = "array") => {
  if (str == null) {
    return null;
  }

  var arr = str.split(" ");
  var [year, month, d] = arr[0].split("-");
};

// get month and year between two dates
export const getMonthYearBtwDates = (start_date, end_date) => {
  var start = new Date(start_date);
  var end = new Date(end_date);
  var result = getMonthYearArray(start, end);
  return result;
};

// to CapitalizedWords
export const toCapitalizedWords = (name) => {
  var words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.substring(1);
}

// get month year array
function getMonthYearArray(start, end) {
  var monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    var month = new Date(dt).getMonth();
    var year = new Date(dt).getFullYear();
    var data = `${monthName[month]} ${year}`;
    if (!arr.includes(data)) {
      arr.push(data);
    }
  }
  return arr;
}

export function successToaster(msg) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    closeButton: false,
    style: {
      backgroundColor: "rgb(240,249,236)",
      color: "#409E13",
      fontSize: "14px",
    },
  });
}

export function errorToaster(msg) {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    closeButton: false,
    style: {
      backgroundColor: "rgb(247,224,225)",
      color: "#D92B2B",
      fontSize: "14px",
    },
  });
}

export function getDocumentLink() {
  const link = "https://documenter.getpostman.com/view/25610463/2s935kMjsg";
  return link;
}
