import  React, { useEffect } from 'react'
import { Auth, API } from 'aws-amplify';
import './ErrorPage.css'
import { useNavigate } from 'react-router-dom';
import awsconfig from '../../Constant/aws-exports';

// API.configure(awsconfig[process.env.REACT_APP_STAGE]);


function ErrorPage(props) {

    let navigate = useNavigate();


    const handler = () => {
     navigate("/customers")
    }

    const loggedOut = () => {
        navigate("/")
       }
   
    
    return (
                  <section id="Error-Page">
                      <div className="container">
                            <div className='row'>
                             404 ERROR

                             <button onClick={props.loggedIn ? handler : loggedOut}className='btn'>Go Back</button>
                            </div>
                            
                 
                    </div>
                  </section>
                    
    

    )
}

export default ErrorPage;
