import React, { useEffect, useState } from "react";
import "./ConsentTemplate.css";
import { API } from "aws-amplify";
import { Modal, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import CustomTooltip from "../../Components/customTooltip/customTooltip";
import CreateConsent from "./CreateConsent";
import PDFModal from "../../Components/pdfModal/pdfModal";
import DeletePopup from "./DeletePopup";
import SendModel from "../../Components/SendModel/sendModel";
import UpdateConsent from "./UpdateConsent";
import preloader from "../../images/preloader.gif";
import add from "../../images/addButton.svg";
import cross from "../../images/cross.svg";
import check from "../../images/check.svg";
import close from "../../images/close.svg";
import newEyeIcon from "../../images/neweye.png";
import newEditIcon from "../../images/newedit.png";
import newDeleteIcon from "../../images/newdelete.png";
import newConsentIcon from "../../images/newconsent.png";
import newPdfIcon from "../../images/newpdf.png";

function ConsentTemplate({ toggleLoading }) {
  // define variables
  const [consentTemplates, setConsentTemplates] = useState([]);
  const [modalInfo, setModalInfo] = useState([]);
  const [template, setTemplate] = useState([]);
  const [uuid, setUuid] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState(false);
  const [fiuFlag, setFiuFlag] = useState(false);
  const [newTemplate, setNewTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [fetchUnit, setFetchUnit] = useState("");
  const [fetchValue, setFetchValue] = useState("");
  const [showPdfIcon, setShowPdfIcon] = useState(false);

  // use effect for consent template page
  useEffect(() => {
    const fiu = JSON.parse(localStorage.getItem("ceplr_fiu"));
    if (fiu.hasOwnProperty("pdf_flow") && fiu.pdf_flow) {
      setShowPdfIcon(true);
    }
    if (fiu.pdf_flow || fiu.netbanking_flow) {
      setFiuFlag(true);
    }
    fetchConsentTemplates();
  }, []);

  // fetch consent template of fiu
  const fetchConsentTemplates = async () => {
    setLoading(true);

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };

    // call the api
    try {
      const response = await API.get(
        "consentTemplates",
        "/templates",
        requestInfo
      );
      const templates = response.data;
      localStorage.setItem("tempId", templates[0].uuid);
      setConsentTemplates(templates);
      setLoading(false);
    } catch (err) {
      console.log("Error while fetching consent templates ::: ", err);
      setLoading(false);
    }
  };

  // method to delete consent templates
  const deleteConsent = async (uuid) => {
    let myInit = {
      body: {},
    };

    // request info
    const requestInfo = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const path = "/templates/" + uuid;

    // call the api
    try {
      await API.del("consentTemplates", path, myInit);
      const response = await API.get(
        "consentTemplates",
        "/templates",
        requestInfo
      );
      const templates = response.data;
      localStorage.setItem("tempId", templates[0].uuid);
      setConsentTemplates(templates);
    } catch (error) {
      console.log("Error while deleting consent templates :: ", error.response);
    }
  };

  // transaction function
  function transactionFunction(fetchTypes, fiDataFrom, fiDataTo, fiDataUnit) {
    // if fetch type is onetime
    if (fetchTypes == "ONETIME") {
      return <img src={cross} alt="" className="transNull" />;
    }

    // if fetch type is periodic
    else if (fetchTypes == "PERIODIC") {
      var fromDate = fiDataFrom;
      var toDate = fiDataTo;
      var unit = fiDataUnit.toLowerCase();
      return `Past ${fromDate} ${unit}(s) to future ${toDate} ${unit}(s)`;
    }
  }

  // fetch type function
  function fetchTypeFunction(fetchTypes, frequencyValue, frequencyUnit) {
    // if fetch type is periodic
    if (fetchTypes == "PERIODIC") {
      return `${frequencyValue} times a ${String(frequencyUnit).toLowerCase()}`;
    }

    // else return empty
    else {
      return <img src={cross} alt="" />;
    }
  }

  // common action component
  const ActionComponent = (props) => {
    if (props.show) {
      return (
        <CustomTooltip msg={props.msg}>
          <button className="iconButton" onClick={props.onClick}>
            {props.loader && props.index ? (
              <Spinner
                animation="border"
                style={{
                  fontSize: 5,
                  color: "#3a86ff",
                  width: "15px",
                  height: "15px",
                }}
              />
            ) : (
              <img src={props.icon} style={{ width: 20, height: 20 }} />
            )}
          </button>
        </CustomTooltip>
      );
    } else {
      return "";
    }
  };

  // render template  by row
  const renderTemplate = (template, index) => {
    let fetchTypes = fetchTypeFunction(
      template.fetchTypes,
      template.frequencyValue,
      template.frequencyUnit
    );
    let transaction = transactionFunction(
      template.fetchTypes,
      template.fiDataFrom,
      template.fiDataTo,
      template.fiDataUnit
    );
    return (
      <tr key={index} className={index % 2 == 1 ? "bg-light" : ""}>
        <td>{template.uuid}</td>
        <td>{template.fiTypes}</td>
        <td>
          <div className="trasDiv">{fetchTypes}</div>
        </td>
        <td>
          <img
            src={template.consentTypes.includes("PROFILE") ? check : cross}
            alt=""
          />
        </td>
        <td>
          <img
            src={template.consentTypes.includes("SUMMARY") ? check : cross}
            alt=""
          />
        </td>
        <td>{transaction}</td>
        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* view configuration details */}
            <ActionComponent
              msg="View configuration details"
              icon={newEyeIcon}
              onClick={() => {
                var rowData = consentTemplates[index];
                rowData["index"] = index + 1;
                setModalInfo(rowData);
                setShow(true);
              }}
              loader={false}
              index={false}
              show={true}
            />

            {/* edit configuration details */}
            <ActionComponent
              msg="Edit configuration"
              icon={newEditIcon}
              onClick={() => {
                const data = consentTemplates[index];
                setTemplate(data);
                setEditTemplate(true);
              }}
              loader={false}
              index={false}
              show={true}
            />

            {/* delete configuration */}
            <ActionComponent
              msg="Delete configuration"
              icon={newDeleteIcon}
              onClick={() => {
                const uuid = consentTemplates[index].uuid;
                setUuid(uuid);
                setDeleteTemplate(true);
              }}
              loader={false}
              index={false}
              show={consentTemplates.length == 1 ? false : true}
            />

            {/* send consent link */}
            <ActionComponent
              msg="Send consent link"
              icon={newConsentIcon}
              onClick={() => {
                const uuid = consentTemplates[index].uuid;
                setUuid(uuid);
                setShowSend(true);
              }}
              loader={false}
              index={false}
              show={true}
            />

            {/* upload pdf */}
            <ActionComponent
              msg="Upload pdf"
              icon={newPdfIcon}
              onClick={() => {
                const uuid = consentTemplates[index].uuid;
                const fetchValue = consentTemplates[index].fetchDataRangeValue;
                const fetchUnit = consentTemplates[index].fetchDataRangeUnit;
                setUuid(uuid);
                setFetchUnit(fetchUnit);
                setFetchValue(fetchValue);
                setShowPdf(true);
              }}
              loader={false}
              index={false}
              show={showPdfIcon}
            />
          </div>
        </td>
      </tr>
    );
  };

  // display template table
  function displayrows(rows) {
    if (rows == 0) {
      return <tbody>No Templates Created</tbody>;
    } else if (rows == 1) {
      return <tbody>{renderTemplate(consentTemplates[0], 0)}</tbody>;
    } else {
      return (
        <tbody>
          {consentTemplates.map((element, index) => {
            return renderTemplate(element, index);
          })}
        </tbody>
      );
    }
  }

  // view configuration details modal
  const ModalContent = () => {
    return (
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="modal-header">
          <div className="title">
            <p className="indexTitle">
              <span className="indexTitle1">{modalInfo.index}</span>
            </p>
            <Modal.Title id="modal-title">Configuration</Modal.Title>
          </div>
          <img src={close} alt="/" id="delete" onClick={() => setShow(false)} />
        </Modal.Header>
        <Modal.Body className="modal-p">
          <p>
            <span className="attr">Consent mode :</span>{" "}
            <span className="value">{modalInfo.consentMode}</span>
          </p>
          <p>
            <span className="attr">Fetch type :</span>{" "}
            <span className="value">{modalInfo.fetchTypes}</span>{" "}
          </p>
          <p>
            <span className="attr">Consent types :</span>{" "}
            <span className="value">{modalInfo.consentTypes}</span>{" "}
          </p>
          <p>
            <span className="attr">Consent life unit : </span>{" "}
            <span className="value">{modalInfo.consentLifeUnit}</span>{" "}
          </p>
          <p>
            <span className="attr">Consent life value : </span>{" "}
            <span className="value">{modalInfo.consentLifeValue}</span>{" "}
          </p>
          <p>
            <span className="attr">FI types :</span>{" "}
            <span className="value">{modalInfo.fiTypes}</span>{" "}
          </p>
          <p>
            <span className="attr">Fetch Data Range Value :</span>{" "}
            <span className="value">{modalInfo.fetchDataRangeValue}</span>{" "}
          </p>
          <p>
            <span className="attr">Fetch Data Range Unit:</span>{" "}
            <span className="value">{modalInfo.fetchDataRangeUnit}</span>{" "}
          </p>
          <p>
            <span className="attr">Consent data from :</span>{" "}
            <span className="value">{modalInfo.fiDataFrom}</span>{" "}
          </p>
          <p>
            <span className="attr">Consent data to : </span>{" "}
            <span className="value">{modalInfo.fiDataTo}</span>{" "}
          </p>
          <p>
            <span className="attr">Consent data unit :</span>{" "}
            <span className="value"> {modalInfo.fiDataUnit}</span>{" "}
          </p>
          <p>
            <span className="attr">Data life unit :</span>{" "}
            <span className="value"> {modalInfo.dataLifeUnit}</span>{" "}
          </p>
          <p>
            <span className="attr">Data life value :</span>{" "}
            <span className="value"> {modalInfo.dataLifeValue} </span>{" "}
          </p>
          <p>
            <span className="attr">Frequency unit :</span>{" "}
            <span className="value"> {modalInfo.frequencyUnit}</span>{" "}
          </p>
          <p>
            <span className="attr">Frequency value :</span>{" "}
            <span className="value"> {modalInfo.frequencyValue}</span>{" "}
          </p>
          <p>
            <span className="attr">Data filter type : </span>{" "}
            <span className="value">{modalInfo.dataFilterType}</span>{" "}
          </p>
          <p>
            <span className="attr">Data filter operator :</span>{" "}
            <span className="value">{modalInfo.dataFilterOperator}</span>{" "}
          </p>
          <p>
            <span className="attr">Data filter value :</span>{" "}
            <span className="value">{modalInfo.dataFilterValue}</span>{" "}
          </p>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <section id="consent_template">
      <ToastContainer pauseOnFocusLoss={false} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="template_header">Configurations</div>
            <div className="template_content">
              Create multiple consent request configurations and save them as
              configurations below. While raising a consent request, you will
              only need to provide the configuration ID.
            </div>
            <div className="new_template">
              <button
                className="add_button"
                onClick={() => setNewTemplate(true)}
              >
                <img className="add" src={add} alt="/" />
                Add new configuration
              </button>
            </div>
          </div>
          <div className="col-8" style={{ marginTop: "3.5%" }}>
            <div className="ctTable ">
              <table
                className="table table-borderless"
                id="tblDemo"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th scope="col">Configuration ID</th>
                    <th scope="col">FI Type</th>
                    <th scope="col">Fetch Type</th>
                    <th scope="col">Profile</th>
                    <th scope="col">Summary</th>
                    <th scope="col">Transaction</th>
                    <th scope="col" style={{ width: 300 }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                {loading || toggleLoading ? (
                  <tbody>
                    <img id="preloader" src={preloader} alt="" />
                  </tbody>
                ) : (
                  displayrows(consentTemplates.length)
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <CreateConsent
        trigger={newTemplate}
        setConsentTemplates={setConsentTemplates}
        setTrigger={setNewTemplate}
        fiuFlag={fiuFlag}
      />
      <UpdateConsent
        template={template}
        trigger={editTemplate}
        setConsentTemplates={setConsentTemplates}
        setTrigger={setEditTemplate}
        fiuFlag={fiuFlag}
      />
      <DeletePopup
        show={deleteTemplate}
        uuid={uuid}
        yes={deleteConsent}
        trigger={deleteTemplate}
        setTrigger={setDeleteTemplate}
        onHide={() => setDeleteTemplate(false)}
      />
      {show ? <ModalContent /> : null}
      {showSend ? (
        <SendModel
          show={showSend}
          onHide={() => setShowSend(false)}
          configuration_uuid={uuid}
          onSuccess={() => {
            setShowSend(false);
            toast.success("Successfully send the link", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}
          onError={() => {
            setShowSend(false);
            toast.error("Something went wrong, Please try again later.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}
        />
      ) : null}
      {showPdf ? (
        <PDFModal
          show={showPdf}
          onHide={() => setShowPdf(false)}
          configuration_uuid={uuid}
          fetchUnit={fetchUnit}
          fetchValue={fetchValue}
          onSuccess={() => {
            setShowPdf(false);
            toast.success("PDF uploaded successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}
          onError={() => {
            setShowPdf(false);
            toast.error("Something went wrong, Please try again later.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}
        />
      ) : null}
    </section>
  );
}

export default ConsentTemplate;
